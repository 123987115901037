import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import SearchCard from "./searchCard";
import Footer from "./Footer";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";

import {
  useGetItemsByChildCatagoryMutation,
  useGetItemsBySubCatagoryMutation,
} from "../features/auth/authApiSlice";
import PopularCategories from "./PopularCategories";
import "../styles/searchresults.css";
import "../styles/welcome.css";

function SearchResults() {
  const location = useLocation();
  const searchResults = location.state?.searchResults;
  const query = location.state?.query;
  const subcatagories = location.state?.subcatagories;

  const [getItemsBySubCatagory, { isSubLoading }] =
    useGetItemsBySubCatagoryMutation();
  const [getItemsByChildCatagory, { isChildLoading }] =
    useGetItemsByChildCatagoryMutation();

  //console.log("searchResults:", searchResults);
  //console.log("query:", query);
  //console.log("subcatagories:", subcatagories);

  const handleSortChange = (newSortBy) => {
    setSortBy(newSortBy);
  };

  const [sortBy, setSortBy] = useState("default"); // default sorting option
  const [originalItems, setOriginalItems] = useState(searchResults);
  const [sortedItems, setSortedItems] = useState(originalItems);
  const navigate = useNavigate();

  const handlegetSubCatagoryItems = async (subCatagory, catagoryType) => {
    if (catagoryType === "subCatagory") {
      const SearchResults = await getItemsBySubCatagory({
        subCatagory: subCatagory,
      });
      //console.log("SearchResults");
      //console.log(SearchResults);

      navigate("/searchResultsPage", {
        state: {
          searchResults: SearchResults.data.items,
          query: subCatagory,
          subcatagories: SearchResults.data.subCatagories,
        },
      });
    } else {
      const SearchResults = await getItemsByChildCatagory({
        childCatagory: subCatagory,
      });
      //console.log("SearchResults");
      //console.log(SearchResults);

      navigate("/searchResultsPage", {
        state: { searchResults: SearchResults.data, query: subCatagory },
      });
    }
    // Add logic here to handle the search action based on the searchQuery.
  };

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, [query]);

  useEffect(() => {
    // Implement sorting based on the selected option (sortBy)
    const sortItems = () => {
      if (!searchResults || searchResults.length === 0) {
        return searchResults; // Return originalItems as is when it's undefined or empty
      }

      switch (sortBy) {
        case "alphabetical":
          return searchResults
            .slice()
            .sort((a, b) => a.itemName.localeCompare(b.itemName));

        case "price":
          return searchResults.slice().sort((a, b) => a.price[0] - b.price[0]);

        default:
          return searchResults;
      }
    };

    // Update the state with the sorted results
    setSortedItems(sortItems());
    //console.log("sorted");
    //console.log(sortedItems);
  }, [searchResults, sortBy, query]);

  //console.log("yourData");
  return (
    <>
      <div className="">
        <Navbar />
        <div className="w-full  h-full pt-6  md:pl-10 pb-24 flex-col gap-4 ">
          <div
            className="md:ml-0 ml-5 text-black text-[28px] font-medium font-['Poppins'] leading-7 tracking-tight flex mt-4 mb-4 pr-24 border-b-4"
            style={{ borderBottom: "1px solid #ccc", width: "95%" }}
          >
            <div>{query} </div>
            <div className="ml-auto mb-2">
              <select
                id="sortBy"
                value={sortBy}
                onChange={(e) => handleSortChange(e.target.value)}
                className="bg-white  w-['130px'] ml-4 md:w-[180px] h-[38px] px-4 py-[5px] rounded-sm border border-stone-300 justify-center items-center gap-[55px] inline-flex text-neutral-600 text-base font-normal font-['Poppins'] leading-7 tracking-tight "
              >
                <option
                  value="default"
                  className="text-neutral-600 text-base font-normal font-['Poppins'] leading-7 tracking-tight"
                >
                  Default
                </option>
                <option
                  value="alphabetical"
                  className="text-neutral-600 text-base font-normal font-['Poppins'] leading-7 tracking-tight"
                >
                  Alphabetical
                </option>
                <option
                  value="price"
                  className="text-neutral-600 text-base font-normal font-['Poppins'] leading-7 tracking-tight"
                >
                  Price
                </option>
              </select>
            </div>
          </div>
          <div className="md:flex">
            {subcatagories && (
              <div className="w-[180px]  pleftright flex-col  items-start gap-3.5 inline-flex">
                <div className="pb-5 justify-center items-start gap-2.5 inline-flex">
                  <div className="text-black text-base font-normal font-['Poppins'] leading-[10px] tracking-tight">
                    Sub Categories
                  </div>
                </div>
                {subcatagories.map((subcategory, index) => (
                  <div
                    key={index}
                    className="w-[180px] justify-between items-start inline-flex"
                  >
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handlegetSubCatagoryItems(
                          subcategory.name,
                          subcategory.type
                        )
                      }
                      className="text-neutral-600 hover:text-neutral-700 text-[15px] font-normal font-['Poppins'] leading-7 tracking-tight"
                    >
                      {subcategory.name}
                    </div>
                  </div>
                ))}
              </div>
            )}

            {sortedItems?.length !== 0 ? (
              <div className="flex flex-wrap gap-2 md:ml-3 justifyLocal">
                {sortedItems?.map((item, index) => (
                  <SearchCard key={index} item={item} />
                ))}
              </div>
            ) : (
              <div>There are no Items for {query}</div>
            )}
          </div>
          <PopularCategories />
        </div>
        <ToastContainer />
      </div>
      <Footer></Footer>
    </>
  );
}

export default SearchResults;
