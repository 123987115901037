import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styles/tailwind.css";
import "tailwindcss/tailwind.css";
import App from "./App";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";

// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/js/bootstrap.js';

import { store } from "./app/store";
import { Provider } from "react-redux";

import { BrowserRouter, Routes, Route } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </BrowserRouter>
    </I18nextProvider>
  </Provider>
);
