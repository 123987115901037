import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";

const Conditions = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Navbar />
      <div className="font-sans text-gray-800 bg-gray-100 p-4 sm:p-8 md:p-12 lg:p-16">
        <div className="container mx-auto my-8 p-4">
          <h3 className="text-2xl font-bold mb-4">
            {t("terms.TermsAndConditions")}
          </h3>
          <p className="mb-4">{t("terms.WelcomeMessage")}</p>

          <section className="mb-8">
            <h3 className="text-lg font-bold mb-2">
              {t("terms.ScopeAndProviderHeading")}
            </h3>
            <p className="mb-2">{t("terms.ScopeAndProviderContent1")}</p>
            <p className="mb-2">{t("terms.ScopeAndProviderContent2")}</p>
            <p className="mb-2">{t("terms.ScopeAndProviderContent3")}</p>
            <p className="mb-2">{t("terms.ScopeAndProviderContent4")}</p>
          </section>

          <section className="mb-8">
            <h3 className="text-lg font-bold mb-2">
              {t("terms.ConclusionHeading")}
            </h3>
            <p className="mb-2">{t("terms.ConclusionContent1")}</p>
            <p className="mb-2">{t("terms.ConclusionContent2")}</p>
            <p className="mb-2">{t("terms.ConclusionContent3")}</p>
            <p className="mb-2">{t("terms.ConclusionContent4")}</p>
            <p className="mb-2">{t("terms.ConclusionContent5")}</p>
            <p className="mb-2">{t("terms.ConclusionContent6")}</p>
            <p className="mb-2">{t("terms.ConclusionContent7")}</p>
            <p className="mb-2">{t("terms.ConclusionContent8")}</p>
            <p className="mb-2">{t("terms.ConclusionContent9")}</p>
          </section>

          <section className="mb-8">
            <h3 className="text-lg font-bold mb-2">
              {t("terms.PricesAndShippingHeading")}
            </h3>
            <p className="mb-2">{t("terms.PricesAndShippingContent1")}</p>
            <p className="mb-2">{t("terms.PricesAndShippingContent2")}</p>
            <p className="mb-2">{t("terms.PricesAndShippingContent3")}</p>
          </section>

          <section className="mb-8">
            <h3 className="text-lg font-bold mb-2">
              {t("terms.DeliveryAndCancellationHeading")}
            </h3>
            <p className="mb-2">{t("terms.DeliveryAndCancellationContent1")}</p>
            <p className="mb-2">{t("terms.DeliveryAndCancellationContent2")}</p>
            <p className="mb-2">{t("terms.DeliveryAndCancellationContent3")}</p>
            <p className="mb-2">{t("terms.DeliveryAndCancellationContent4")}</p>
            <p className="mb-2">{t("terms.DeliveryAndCancellationContent5")}</p>
          </section>

          <section className="mb-8">
            <h3 className="text-lg font-bold mb-2">
              {t("terms.PaymentHeading")}
            </h3>
            <p className="mb-2">{t("terms.PaymentContent1")}</p>
            <ul className="list-disc pl-6 mb-4">
              {t("terms.PaymentMethods", { returnObjects: true }).map(
                (method, index) => (
                  <li key={index}>{method}</li>
                )
              )}
            </ul>
            <p className="mb-2">{t("terms.PaymentContent2")}</p>
          </section>

          <section className="mb-8">
            <h3 className="text-lg font-bold mb-2">
              {t("terms.SetOffHeading")}
            </h3>
            <p className="mb-2">{t("terms.SetOffContent1")}</p>
            <p className="mb-2">{t("terms.SetOffContent2")}</p>
          </section>

          <section className="mb-8">
            <h3 className="text-lg font-bold mb-2">
              {t("terms.RetentionOfTitleHeading")}
            </h3>
            <p className="mb-2">{t("terms.RetentionOfTitleContent1")}</p>
          </section>

          <section className="mb-8">
            <h3 className="text-lg font-bold mb-2">
              {t("terms.TransportDamageHeading")}
            </h3>
            <p className="mb-2">{t("terms.TransportDamageContent1")}</p>
            <p className="mb-2">{t("terms.TransportDamageContent2")}</p>
          </section>

          <section className="mb-8">
            <h3 className="text-lg font-bold mb-2">
              {t("terms.RightToDefectsHeading")}
            </h3>
            <p className="mb-2">{t("terms.RightToDefectsContent1")}</p>
            <p className="mb-2">{t("terms.RightToDefectsContent2")}</p>
            <p className="mb-2">{t("terms.RightToDefectsContent3")}</p>
            <p className="mb-2">{t("terms.RightToDefectsContent4")}</p>
          </section>

          <section className="mb-8">
            <h3 className="text-lg font-bold mb-2">
              {t("terms.LimitationOfLiabilityHeading")}
            </h3>
            <p className="mb-2">{t("terms.LimitationOfLiabilityContent1")}</p>
            <p className="mb-2">{t("terms.LimitationOfLiabilityContent2")}</p>
            <p className="mb-2">{t("terms.LimitationOfLiabilityContent3")}</p>
            <p className="mb-2">{t("terms.LimitationOfLiabilityContent4")}</p>
            <p className="mb-2">{t("terms.LimitationOfLiabilityContent5")}</p>
          </section>

          <section className="mb-8">
            <h3 className="text-lg font-bold mb-2">
              {t("terms.CancellationPolicyHeading")}
            </h3>
            <p className="mb-2">{t("terms.CancellationPolicyContent1")}</p>
            <p className="mb-2">{t("terms.CancellationPolicyContent2")}</p>
            <p className="mb-2">{t("terms.CancellationPolicyContent3")}</p>
            <p className="mb-2">{t("terms.CancellationPolicyContent4")}</p>
            <p className="mb-2">{t("terms.CancellationPolicyContent5")}</p>

            <div className="mb-4">
              <p>{t("legal.saleToEurope")}</p>
              <p>{t("legal.city")}</p>
              <p>{t("legal.address")}</p>
              <p>{t("legal.telephone")}</p>
              <p>{t("legal.email")}</p>
            </div>

            <p className="mb-2">{t("terms.CancellationPolicyContent6")}</p>
            <p className="mb-2">{t("terms.CancellationPolicyContent7")}</p>
            <p className="mb-2">{t("terms.CancellationPolicyContent8")}</p>
            <p className="mb-2">{t("terms.CancellationPolicyContent9")}</p>
            <p className="mb-2">{t("terms.CancellationPolicyContent10")}</p>
            <p className="mb-2">{t("terms.CancellationPolicyContent11")}</p>

            <div className="mb-4">
              <p>{t("legal.saleToEurope")}</p>
              <p>{t("legal.city")}</p>
              <p>{t("legal.address")}</p>
              <p>{t("legal.telephone")}</p>
              <p>{t("legal.email")}</p>
            </div>

            <p className="mb-2">{t("terms.CancellationPolicyContent12")}</p>
            <p className="mb-2">{t("terms.CancellationPolicyContent13")}</p>
            <p className="mb-2">{t("terms.CancellationPolicyContent14")}</p>
            <p className="mb-2">{t("terms.ExceptionsContent")}</p>
            <ul className="list-disc pl-6 mb-4">
              {t("terms.ExceptionsList", { returnObjects: true }).map(
                (exception, index) => (
                  <li key={index}>{exception}</li>
                )
              )}
            </ul>
          </section>

          <section className="mb-8">
            <h3 className="text-lg font-bold mb-2">
              {t("terms.ExclusionHeading")}
            </h3>
            <p className="mb-2">{t("terms.ExclusionContent1")}</p>
            <ul className="list-disc pl-6 mb-4">
              {t("terms.ExclusionList1", { returnObjects: true }).map(
                (item, index) => (
                  <li key={index}>{item}</li>
                )
              )}
            </ul>
            <p className="mb-2">{t("terms.ExclusionContent2")}</p>
            <ul className="list-disc pl-6 mb-4">
              {t("terms.ExclusionList2", { returnObjects: true }).map(
                (item, index) => (
                  <li key={index}>{item}</li>
                )
              )}
            </ul>
          </section>

          <section className="mb-8">
            <h3 className="text-lg font-bold mb-2">
              {t("terms.DataProtectionHeading")}
            </h3>
            <p className="mb-2">{t("terms.DataProtectionContent1")}</p>
            <p className="mb-2">{t("terms.DataProtectionContent2")}</p>
            <p className="mb-2">{t("terms.DataProtectionContent3")}</p>
            <p className="mb-2">{t("terms.DataProtectionContent4")}</p>
            <p className="mb-2">{t("terms.DataProtectionContent5")}</p>
          </section>

          <section className="mb-8">
            <h3 className="text-lg font-bold mb-2">
              {t("terms.CookiesHeading")}
            </h3>
            <p className="mb-2">{t("terms.CookiesContent1")}</p>
            <p className="mb-2">{t("terms.CookiesContent2")}</p>
            <p className="mb-2">{t("terms.CookiesContent3")}</p>
            <p className="mb-2">{t("terms.CookiesContent4")}</p>
            <p className="mb-2">{t("terms.CookiesContent5")}</p>
            <p className="mb-2">{t("terms.CookiesContent6")}</p>
          </section>

          <section className="mb-8">
            <h3 className="text-lg font-bold mb-2">
              {t("terms.PlaceAndLawHeading")}
            </h3>
            <p className="mb-2">{t("terms.PlaceAndLawContent1")}</p>
            <p className="mb-2">{t("terms.PlaceAndLawContent2")}</p>
          </section>

          <section className="mb-8">
            <h3 className="text-lg font-bold mb-2">
              {t("terms.FinalProvisionsHeading")}
            </h3>
            <p className="mb-2">{t("terms.FinalProvisionsContent1")}</p>
            <p className="mb-2">{t("terms.FinalProvisionsContent2")}</p>
            <p className="mb-2">{t("terms.FinalProvisionsContent3")}</p>
            <p className="mb-2">{t("terms.FinalProvisionsContent4")}</p>
            <p className="mb-2">{t("terms.FinalProvisionsContent5")}</p>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Conditions;
