import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import { useDispatch, useSelector } from 'react-redux';
import {
  setCredentials,
  setUser,
  setSelectedProfileBar,
  setBuyer,
  addPendingOrder,
  addPreparingOrder,
  addDeliveredOrders,
  addCanceledOrders,
  addOntheWayOrder,
  addPendingSeller,
  addBlockedSeller,
  addBlockedBuyer,
  addVerifiedSeller,
  addVerifiedBuyer,
  setTotalReviewsAndItems,
  setSellerAdminData,
  selectCurrenRole,
} from './authSlice';
import Navbar from '../../components/Navbar';

import {
  useGetAllItemNumbersMutation,
  useGetAllReviewNumbersMutation,
  useGetBuyerByAccountStatusMutation,
  useGetMonthlySalesListMutation,
  useGetOrdersByStatusBuyerMutation,
  useGetOrdersByStatusSellerMutation,
  useGetSellerByBusinessStatusMutation,
  useGetTotalDailySalesMutation,
  useGetTotalMonthlySalesMutation,
  useGetTotalWeeklySalesMutation,
  useGetTotalYearlySalesMutation,
  useLoginMutation,
  useResetPasswordMutation,
} from './authApiSlice';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/Footer';

const Login = () => {
  const currentRole = useSelector(selectCurrenRole);
  const userRef = useRef();
  const errRef = useRef();
  const [forgotting, setForgotting] = useState(false);
  const [email, setemail] = useState('');
  const [isLoggingIn, setisLoggingIn] = useState(false);

  const [password, setPwd] = useState('');
  const [forgotPswMsg, setForGotPswMsg] = useState(null);

  const [errMsg, setErrMsg] = useState('');
  const navigate = useNavigate();

  const [login, { isLoading }] = useLoginMutation();
  const [resetPassword, { isResetLoading }] = useResetPasswordMutation();

  const dispatch = useDispatch();

  // useEffect(() => {
  //   userRef.current.focus();
  // }, []);

  useEffect(() => {
    setErrMsg('');
  }, [email, password]);
  useEffect(() => {
    if (currentRole) {
      navigate('/');
    }
  }, []);

  const [getOrdersByStatusAndSeller, { isSellerLoading }] =
    useGetOrdersByStatusSellerMutation();

  const [getAllItemNumbers, { isAllItemsLoading }] =
    useGetAllItemNumbersMutation();

  const [getTotalDailySales, { isDailyLoading }] =
    useGetTotalDailySalesMutation();
  const [getMonthlySalesList, { isMlistLoading }] =
    useGetMonthlySalesListMutation();
  const [getTotalWeeklySales, { isweekLoading }] =
    useGetTotalWeeklySalesMutation();
  const [getTotalMonthlySales, { isDyLoading }] =
    useGetTotalMonthlySalesMutation();
  const [getTotalYearlySales, { isDaiyLoading }] =
    useGetTotalYearlySalesMutation();

  const [getAllReviewNumbers, { isAllReviewLaoding }] =
    useGetAllReviewNumbersMutation();

  const [getOrdersByStatusAndBuyer, { isBuyerLoading }] =
    useGetOrdersByStatusBuyerMutation();

  const [getBuyerByAccountStatus, { isBuyerAccountLoading }] =
    useGetBuyerByAccountStatusMutation();

  const [getSellerByBusinessStatus, { isSellerAccountLoadingLoading }] =
    useGetSellerByBusinessStatusMutation();

  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setisLoggingIn(true);
    setForGotPswMsg(null);
    try {
      const userData = await login({ email, password }).unwrap();
      //console.log(`userdata: ${userData[1].accountStatus}`);
      if (userData[1].accountStatus === 'pending') {
        // Display a message when the account status is "pending"
        setErrMsg('Please verify your email first.');
        return; // Prevent further execution
      }

      //console.log(userData[0].access_token);
      let access_token = userData[0].access_token;
      let refresh_token = userData[0].refresh_token;
      let dailysales;
      let weeklysales;
      let monthlysales;
      let yearlysales;
      let monthlySaleList;
      let totalSellerItemlength;
      const Id = userData[1]._id;

      const userEmail = userData[1].email;
      dispatch(
        setCredentials({
          user: email,
          accessToken: access_token,
          refreshtoken: refresh_token,
        }),
      );

      Cookies.set(
        'accessToken',
        access_token,
        { sameSite: 'Strict' },
        { secure: true },
      );
      Cookies.set(
        'refreshToken',
        refresh_token,
        { sameSite: 'Strict' },
        { secure: true },
      );

      const accessToken = Cookies.get('accessToken');
      const refreshToken = Cookies.get('refreshToken');
      //console.log("accessToken refreshToken");
      //console.log(accessToken, refreshToken);

      const role = userData[1].role;
      const cart = userData[1].cart;
      const country = userData[1].country ? userData[1].country : null;
      const region = userData[1].state ? userData[1].state : null;
      const city = userData[1].city ? userData[1].city : null;
      const accountNumber = userData[1].accountNumber
        ? userData[1].accountNumber
        : null;
      const additionalAccountNumber = userData[1].additionalAccountNumber
        ? userData[1].additionalAccountNumber
        : null;
      const additionalStreetAddress = userData[1].additionalStreetAddress
        ? userData[1].additionalStreetAddress
        : null;
      const zipCode = userData[1].zipCode ? userData[1].zipCode : null;
      const streetAddress = userData[1].streetAddress
        ? userData[1].streetAddress
        : null;
      const phoneNumber = userData[1].phoneNumber
        ? userData[1].phoneNumber
        : null;

      const postalCode = userData[1].postalCode ? userData[1].postalCode : null;
      const homeNumber = userData[1].homeNumber ? userData[1].homeNumber : null;
      const userImgUrl = userData[1].imgUrl ? userData[1].imgUrl : null;
      //console.log(`homeNumber: ${homeNumber}`);

      let name;
      if (role == process.env.REACT_APP_BUYER_ROLE) {
        name = userData[1].fullName;
        const preparingOrders = await getOrdersByStatusAndBuyer({
          orderStatus: 'preparing',
          buyerId: Id,
        });
        const onthewayOrders = await getOrdersByStatusAndBuyer({
          orderStatus: 'ontheway',
          buyerId: Id,
        });
        const pendingOrders = await getOrdersByStatusAndBuyer({
          orderStatus: 'pending',
          buyerId: Id,
        }).unwrap();
        const deliveredOrders = await getOrdersByStatusAndBuyer({
          orderStatus: 'delivered',
          buyerId: Id,
        });
        const cancelledOrders = await getOrdersByStatusAndBuyer({
          orderStatus: 'cancelled',
          buyerId: Id,
        });
        //console.log('pendingOrders');
        //console.log(pendingOrders);
        //console.log('preparingOrders');
        //console.log(preparingOrders);
        //console.log('onthewayOrders');
        //console.log(onthewayOrders);
        //console.log('deliveredOrders');
        //console.log(deliveredOrders);
        //console.log('cancelledOrders');
        //console.log(cancelledOrders);
        {
          preparingOrders.data?.length != 0 &&
            preparingOrders.data?.map((order) => {
              dispatch(
                addPreparingOrder({
                  orderId: order.order._id,
                }),
              );
            });
        }
        {
          onthewayOrders.data?.length != 0 &&
            onthewayOrders.data?.map((order) => {
              dispatch(
                addOntheWayOrder({
                  orderId: order.order._id,
                }),
              );
            });
        }

        {
          pendingOrders?.length != 0 &&
            pendingOrders?.map((order) => {
              dispatch(
                addPendingOrder({
                  orderId: order.order._id,
                }),
              );
            });
        }
        {
          deliveredOrders.data?.length != 0 &&
            deliveredOrders.data.map((order) => {
              dispatch(
                addDeliveredOrders({
                  orderId: order.order._id,
                }),
              );
            });
        }
        {
          cancelledOrders.data.length &&
            cancelledOrders.data.map((order) => {
              dispatch(
                addCanceledOrders({
                  orderId: order.order._id,
                }),
              );
            });
        }

        //console.log('is is working');
      } else if (role == process.env.REACT_APP_SELLER_ROLE) {
        totalSellerItemlength = await getAllItemNumbers({
          sellerId: userData._id,
        });

        //console.log('totalSellerItemlength');
        //console.log(totalSellerItemlength);

        dailysales = await getTotalDailySales({
          sellerId: userData._id,
        });
        //console.log('dailysales');
        //console.log(dailysales);
        weeklysales = await getTotalWeeklySales({
          sellerId: userData._id,
        });
        monthlysales = await getTotalMonthlySales({
          sellerId: userData._id,
        });
        yearlysales = await getTotalYearlySales({
          sellerId: userData._id,
        });
        monthlySaleList = await getMonthlySalesList({
          sellerId: userData._id,
        });
        const preparingOrders = await getOrdersByStatusAndSeller({
          orderStatus: 'preparing',
          sellerId: Id,
        });
        const onthewayOrders = await getOrdersByStatusAndSeller({
          orderStatus: 'ontheway',
          sellerId: Id,
        });
        const pendingOrders = await getOrdersByStatusAndSeller({
          orderStatus: 'pending',
          sellerId: Id,
        }).unwrap();
        const deliveredOrders = await getOrdersByStatusAndSeller({
          orderStatus: 'delivered',
          sellerId: Id,
        });
        const cancelledOrders = await getOrdersByStatusAndSeller({
          orderStatus: 'cancelled',
          sellerId: Id,
        });
        //console.log('pendingOrders');
        //console.log(pendingOrders);
        //console.log('preparingOrders');
        //console.log(preparingOrders);
        //console.log('onthewayOrders');
        //console.log(onthewayOrders);
        //console.log('deliveredOrders');
        //console.log(deliveredOrders);
        //console.log('cancelledOrders');
        //console.log(cancelledOrders);
        {
          preparingOrders.data.length != 0 &&
            preparingOrders.data.map((order) => {
              dispatch(
                addPreparingOrder({
                  orderId: order._id,
                }),
              );
            });
        }
        {
          onthewayOrders.data.length != 0 &&
            onthewayOrders.data.map((order) => {
              dispatch(
                addOntheWayOrder({
                  orderId: order._id,
                }),
              );
            });
        }

        {
          pendingOrders.length != 0 &&
            pendingOrders.map((order) => {
              dispatch(
                addPendingOrder({
                  orderId: order._id,
                }),
              );
            });
        }
        {
          deliveredOrders.data.length != 0 &&
            deliveredOrders.data.map((order) => {
              dispatch(
                addDeliveredOrders({
                  orderId: order._id,
                }),
              );
            });
        }
        {
          cancelledOrders.data.length &&
            cancelledOrders.data.map((order) => {
              dispatch(
                addCanceledOrders({
                  orderId: order._id,
                }),
              );
            });
        }

        //console.log('is is working');

        name = userData[1].name;
        const businessStatus = userData[1].businessStatus;
        if (businessStatus == 'pending') {
          setErrMsg('Please wait for verification from the admin');
          return;
        }
      } else if (role === process.env.REACT_APP_ADMIN_ROLE) {
        const totalItemlength = await getAllItemNumbers();
        const totalReviewLength = await getAllReviewNumbers();

        dailysales = await getTotalDailySales();
        //console.log('dailysales');
        //console.log(dailysales);
        weeklysales = await getTotalWeeklySales();
        monthlysales = await getTotalMonthlySales();
        yearlysales = await getTotalYearlySales();
        monthlySaleList = await getMonthlySalesList();

        //console.log('monthlySaleList');
        //console.log(monthlySaleList);

        dispatch(
          setTotalReviewsAndItems({
            reviewLength: totalReviewLength.data,
            itemLength: totalItemlength.data,
          }),
        );
        const PendingSellers = await getSellerByBusinessStatus({
          businessStatus: 'pending',
        });
        const VerifiedSellers = await getSellerByBusinessStatus({
          businessStatus: 'verified',
        });
        const BlockedSellers = await getSellerByBusinessStatus({
          businessStatus: 'blocked',
        });
        const BlockedBuyers = await getBuyerByAccountStatus({
          accountStatus: 'blocked',
        });
        const VerifiedBuyers = await getBuyerByAccountStatus({
          accountStatus: 'verified',
        });

        {
          PendingSellers.data.length != 0 &&
            PendingSellers.data.map((seller) => {
              dispatch(
                addPendingSeller({
                  sellerId: seller._id,
                }),
              );
            });
        }
        {
          BlockedSellers.data.length != 0 &&
            BlockedSellers.data.map((seller) => {
              dispatch(
                addBlockedSeller({
                  sellerId: seller._id,
                }),
              );
            });
        }
        //console.log('Blocked Buyers');
        //console.log(BlockedBuyers.data);
        {
          BlockedBuyers.data.length != 0 &&
            BlockedBuyers.data.map((buyer) => {
              dispatch(
                addBlockedBuyer({
                  buyerId: buyer._id,
                }),
              );
            });
        }
        {
          VerifiedSellers.data.length != 0 &&
            dispatch(
              addVerifiedSeller({
                number: VerifiedSellers.data.length,
              }),
            );
        }
        {
          VerifiedBuyers.data.length != 0 &&
            dispatch(
              addVerifiedBuyer({
                number: VerifiedBuyers.data.length,
              }),
            );
        }
      }

      const accountStatus = userData[1].accountStatus;
      //console.log(`refresh ${refresh_token}`);

      dispatch(setSelectedProfileBar({ SelectedProfileBar: 'Dashboard' }));
      dispatch(
        setUser({
          id: Id,
          email: userEmail,
          name: name,
          role: role,
          accountStatus: accountStatus,
          cart: cart,
        }),
      );
      setemail('');
      setPwd('');
      if (role === process.env.REACT_APP_BUYER_ROLE) {
        dispatch(
          setBuyer({
            country: country,
            region: region,
            city: city,
            streetAddress: streetAddress,
            additionalStreetAddress: additionalStreetAddress,
            zipCode: zipCode,
            phoneNumber: phoneNumber,
            postalCode: postalCode,
            homeNumber: homeNumber,
            imgUrl: userImgUrl,
          }),
        );
        navigate('/buyerprofile');
      } else if (role === process.env.REACT_APP_SELLER_ROLE) {
        const sellerData = {
          dailysales: dailysales.data,
          weeklysales: weeklysales.data,
          monthlysales: monthlysales.data,
          yearlysales: yearlysales.data,
          monthlySaleList: monthlySaleList.data,
          totalSellerItemlength: totalSellerItemlength.data,
        };
        dispatch(setSellerAdminData({ data: sellerData }));
        dispatch(
          setBuyer({
            country: country,
            region: region,
            city: city,
            streetAddress: streetAddress,
            additionalStreetAddress: additionalStreetAddress,
            zipCode: zipCode,
            phoneNumber: phoneNumber,
            postalCode: postalCode,
            homeNumber: homeNumber,
            imgUrl: userImgUrl,
            accountNumber: accountNumber,
            additionalAccountNumber: additionalAccountNumber,
          }),
        );
        navigate('/SellerDashBoard');
      } else if (role === process.env.REACT_APP_ADMIN_ROLE) {
        const adminData = {
          dailysales: dailysales.data,
          weeklysales: weeklysales.data,
          monthlysales: monthlysales.data,
          yearlysales: yearlysales.data,
          monthlySaleList: monthlySaleList.data,
        };
        dispatch(setSellerAdminData({ data: adminData }));

        navigate('/admin');
      }
    } catch (err) {
      if (!err?.originalStatus) {
        //console.log(err);
        // isLoading: true until timeout occurs
        setErrMsg(err.data.message);
      } else if (err.originalStatus === 400) {
        setErrMsg('Missing Username or Password');
      } else if (err.originalStatus === 401) {
        setErrMsg('Unauthorized');
      } else {
        setErrMsg('Login Failed');
      }
      // errRef.current.focus();
    } finally {
      setisLoggingIn(false);
    }
  };

  const handleUserInput = (e) => setemail(e.target.value);

  const handlePwdInput = (e) => setPwd(e.target.value);

  const content = (
    <div className="contaner">
      <div className="row">
        <section className="login">
          <Navbar></Navbar>
          <div className="justify-center gap-4 pt-12 h-screen flex mx-2 sm:mx-0">
            <div
              className="w-full max-w-xl px-8 bg-white border-dashed border-2 border-gray-200 pt-7 flex-col rounded-sm justify-center items-center"
              style={{ height: '500px' }}
            >
              <div className="text-2xl font-normal text-center mb-6">
                {t('login')}
              </div>
              <form className="mb-12" onSubmit={handleSubmit}>
                <label className="block mb-3">
                  <span className="block text-sm font-medium text-slate-700 pb-1">
                    {t('email')}
                  </span>
                  <input
                    value={email}
                    onChange={handleUserInput}
                    autoComplete="off"
                    required
                    type="email"
                    placeholder={t('email')}
                    className="mt block w-full px-3 py-2 bg-white border border-slate-300 rounded-sm text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  />
                </label>
                <label className="block">
                  <span className="block text-sm font-medium text-slate-700 pb-1">
                    {t('password')}
                  </span>
                  <input
                    type="password"
                    id="password"
                    onChange={handlePwdInput}
                    value={password}
                    required
                    placeholder={t('password')}
                    className="mt block w-full px-3 py-2 bg-white border border-slate-300 rounded-sm text-sm shadow-sm placeholder-slate-400
                      focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                      focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  />
                </label>
                <div className="mt-4 text-end">
                  <a
                    href="#"
                    className={`${
                      forgotting ? 'disabled:opacity-70' : ''
                    } my-1 ml-auto text-gray-600 hover:text-purple-500`}
                    onClick={async () => {
                      setForGotPswMsg(null);
                      setForgotting(true);
                      try {
                        const userDate = await resetPassword({ email: email });
                        //console.log(userDate);
                        if (userDate.error.data === email) {
                          setForGotPswMsg(t('resetLinkSent'));
                        } else {
                          setForGotPswMsg(t('usrNotFound'));
                        }
                      } catch {
                        setForGotPswMsg(t('usrNotFound'));
                      } finally {
                        setForgotting(false);
                      }
                    }}
                  >
                    {forgotting ? 'Processing...' : t('forgotpassword')}
                  </a>
                </div>
                {forgotPswMsg && (
                  <div className="text-red-600">{forgotPswMsg}</div>
                )}

                <div className="text-center mt-8">
                  <button
                    className={`mt-4 text-white text-center bg-purple-700 hover:bg-purple-600 rounded-sm w-28 py-1 px-3 ${
                      isLoggingIn ? 'disabled:bg-orange-500' : ''
                    }`}
                  >
                    {isLoggingIn ? 'Processing...' : t('signin')}
                  </button>
                  <p
                    className={`${
                      errMsg ? 'errmsg' : 'offscreen'
                    } my-4 text-red-600`}
                    aria-live="assertive"
                  >
                    {errMsg}
                  </p>
                  <div className="">
                    {t('noaccount')}{' '}
                    <span>
                      <a href="/signup" className="text-indigo-600">
                        {t('signup')}
                      </a>
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
      <Footer></Footer>
    </div>
  );
  return content;
};
export default Login;
