const categoriesData = [
  {
    name: "Electronics",
    t: "electronics",
    subcategories: [
      { name: "tablets", param: "tablet" },
      { name: "personalComputers", param: "pc" },
      { name: "smartPhones", param: "smartPhones" },
      { name: "camera", param: "camera" },
    ],
    brands: [
      { name: "apple", param: "apple" },
      { name: "samsung", param: "samsung" },
      { name: "itel", param: "itel" },
      { name: "huawei", param: "huawei" },
    ],
  },
  {
    name: "Fashion",
    t: "fashion",
    subcategories: [
      { name: "menClothing", param: "Men's Clothing" },
      { name: "womenClothing", param: "Women's Clothing" },
      { name: " Sun Glasses", param: " Sun Glasses" },
      { name: "Sneakers", param: "Sneakers" },
    ],
    brands: [
      { name: "supreme", param: "Supreme" },
      { name: "patagonia", param: "Patagonia" },
      { name: "Uniqlo", param: "Uniqlo" },
    ],
  },
  {
    name: "Sports",
    t: "sports",
    subcategories: [
      { name: "Swimming", param: "Swimming" },
      { name: "Athletics", param: "Athletics" },
      { name: "FootBall", param: "FootBall" },
      { name: "Tenis", param: "Tenis" },
    ],
    brands: [
      { name: "Nike", param: "Nike" },
      { name: "Adidas", param: "Adidas" },
      { name: "Puma", param: "Puma" },
      { name: "Fila", param: "Fila" },
    ],
  },
  {
    name: "Children",
    t: "children",
    subcategories: [
      { name: "Clothes", param: "Child Cloths" },
      { name: "Toys", param: "Toys" },
      { name: "Baby Care", param: "Baby Care" },
      { name: "Children Books", param: "Children Books" },
    ],
    brands: [
      { name: "Mattel", param: "Mattel" },
      { name: "H&M Kids", param: "H&M Kids" },
      { name: "Johnson's Baby", param: "Johnson's Baby" },
    ],
  },
  {
    name: "Ladies",
    t: "ladies",
    subcategories: [
      { name: "Women's Clothes", param: "Women's Clothes" },
      { name: "Women's Shoes", param: "Women's Shoes" },
      { name: "Jewlry", param: "Jewlry" },
      { name: "Handbags", param: "Handbags" },
    ],
    brands: [
      { name: "Zara", param: "Zara" },
      { name: "Tiffany & Co.", param: "Tiffany & Co." },
      { name: "Tote Bags", param: "Tote Bags" },
    ],
  },
  {
    name: "Man",
    t: "man",
    subcategories: [
      { name: "Men's Clothing", param: "Men's Clothing" },
      { name: "Men's FootWear", param: "Men's FootWear" },
      { name: "Accessories", param: "Accessories" },
      { name: "Grooming", param: "Grooming" },
    ],
    brands: [
      { name: "Ralph Lauren", param: "Ralph Lauren" },
      { name: "Clarks", param: "Clarks" },
      { name: "Fossil", param: "Fossil" },
      { name: "Gillette", param: "Gillette" },
    ],
  },
  {
    name: "Shoe",
    t: "shoes",
    subcategories: [
      { name: "Athletic Shoes", param: "Athletic Shoes" },
      { name: "Casual Shoes", param: "Casual Shoes" },
      { name: "Dress Shoes", param: "Dress Shoes" },
      { name: "Boots", param: "Boots" },
    ],
    brands: [
      { name: "Timberland", param: "Timberland" },
      { name: "Clarks", param: "Clarks" },
      { name: "Vans", param: "Vans" },
      { name: "Nike", param: "Nike" },
    ],
  },
  {
    name: "Clothes",
    t: "clothes",
    subcategories: [
      { name: "Casual Wear", param: "Casual Wear" },
      { name: "Sportswear", param: "Sportswear" },
      { name: "Formal Wear", param: "Formal Wear" },
      { name: "Sustainable Fashion", param: "Sustainable Fashion" },
    ],
    brands: [
      { name: "Levi's", param: "Levi's" },
      { name: "Nike", param: "Nike" },
      { name: "Hugo Boss", param: "Hugo Boss" },
      { name: "Patagonia", param: "Patagonia" },
    ],
  },
  {
    name: "Beauty",
    t: "beauty",
    subcategories: [
      { name: "Skincare", param: "Skincare" },
      { name: "Makeup", param: "Makeup" },
      { name: "Haircare", param: "Haircare" },
      { name: "Fragrance", param: "Fragrance" },
    ],
    brands: [
      { name: "CeraVe", param: "CeraVe" },
      { name: "MAC Cosmetics", param: "MAC Cosmetics" },
      { name: "Dove", param: "Dove" },
      { name: "Chanel", param: "Chanel" },
    ],
  },
  {
    name: "Art",
    t: "art",
    subcategories: [
      { name: "Painting", param: "Painting" },
      { name: "Drawing", param: "Drawing" },
      { name: "Sculpture", param: "Sculpture" },
    ],
    brands: [
      { name: "Winsor & Newton", param: "Winsor & Newton" },
      { name: "Golden", param: "Golden" },
      { name: "Faber-Castell", param: "Faber-Castell" },
      { name: "Sculpey", param: "Sculpey" },
    ],
  },
  {
    name: "Home & Furniture",
    t: "Home & Furniture",
    subcategories: [
      { name: "Furniture", param: "Furniture" },
      { name: "Home Decor", param: "Home Decor" },
      { name: "Kitchen & Dining", param: "Kitchen & Dining" },
    ],
    brands: [
      { name: "Faber-Castell", param: "Faber-Castell" },
      { name: "Sculpey", param: "Sculpey" },
      { name: "IKEA", param: "IKEA" },
      { name: "Brooklinen", param: "Brooklinen" },
    ],
  },
];

export default categoriesData;
