import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentPendingOrders,
  selectCurrentUserId,
  setCatagoryIdTobeEdited,
  setSelectedItem,
} from "../../features/auth/authSlice";
import Modal from "react-modal";

import {
  useDeleteCatagoryByIdMutation,
  useGetBuyerByAccountStatusAndPageMutation,
  useGetCatagoriesByTypeMutation,
  useGetOrdersByStatusSellerMutation,
  useGetSellerByBusinessStatusAndPageMutation,
  useGetSellerByBusinessStatusMutation,
} from "../../features/auth/authApiSlice";
import UserDetail from "./userDetail";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";

function CatagoriesList({
  catagoryType,
  selectedSidebarItem,
  onSelectSidebarItem,
}) {
  const [callUseEffect, setCallUseEffect] = useState(1);
  const dispatch = useDispatch();

  const [catagoryId, setCatagoryId] = useState(null);

  const [fetchedCatagories, setFetchedCatgories] = useState([]);
  const [fetchedSubCatagories, setFetchedSubCatgories] = useState([]);
  const [fetchedChildCatagories, setFetchedChildCatgories] = useState([]);
  const [verifiedBuyers, setVerifiedBuyers] = useState([]);
  const [blockedBuyers, setBlockedBuyers] = useState([]);
  const [fetchedUsers, setFetchedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const pendingOrderIdsList = useSelector(selectCurrentPendingOrders);
  const sellerId = useSelector(selectCurrentUserId);

  const [getCatagoriesByType, { isSellerByPageLoading }] =
    useGetCatagoriesByTypeMutation();
  const [deleteCatagoryById, { isDeleteLoading }] =
    useDeleteCatagoryByIdMutation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        if (catagoryType === "Main Catagories") {
          const mainCatagories = await getCatagoriesByType({
            type: "mainCatagory",
          }).unwrap();
          setFetchedCatgories(mainCatagories);
        } else if (catagoryType === "Sub Catagories") {
          const subCatagories = await getCatagoriesByType({
            type: "subCatagory",
          }).unwrap();
          setFetchedCatgories(subCatagories);
        } else if (catagoryType === "Child Catagories") {
          const childcatagories = await getCatagoriesByType({
            type: "childCatagory",
          }).unwrap();
          setFetchedCatgories(childcatagories);
        }

        setIsLoading(false);
        // Update state with the fetched data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Invoke the fetchData function when the component mounts or when 'Id' changes
  }, [catagoryType, callUseEffect]); // Include 'Id' as a dependency to refetch data when 'Id' changes
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (catagoryId) => {
    setIsModalOpen(true);
    setCatagoryId(catagoryId);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setErrMsg(null);
    setSuccessMsg(null);
  };

  const customStyles = {
    content: {
      maxWidth: "400px", // Adjust the maximum width as needed
      maxHeight: "150px", // Adjust the maximum height as needed
      margin: "auto",
    },
  };
  const [isDeleting, setIsDeleting] = useState(false);
  const [successMessage, setSuccessMsg] = useState();
  const [errMsg, setErrMsg] = useState();

  const handleDelete = async (event) => {
    event.preventDefault();
    setIsDeleting(true);
    setErrMsg(null);
    setSuccessMsg(null);

    try {
      const deltedItem = await deleteCatagoryById({
        catagoryId: catagoryId,
      });

      setSuccessMsg("Item successfully Deleted");
    } catch (error) {
      setErrMsg("Un able to Delete the Item");
    } finally {
      setIsDeleting(false);
      setCallUseEffect(callUseEffect + 1);
    }
  };

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div
          className={` text-gray-800 px-6 py-4 bg-gray-100 w-full`}
          style={{ overflowY: "auto", height: "calc(100vh - 56px)" }}
        >
          <div className="w-full bg-white h-16 flex items-center px-2 mb-10 rounded-md  text-lg text-opacity-70">
            {catagoryType}
          </div>
          <div className="flex flex-wrap gap-2">
            {fetchedCatagories.length !== 0 &&
              fetchedCatagories.map((category, index) => (
                <div className="flex bg-white rounded px-2 py-1 mb-2 w-">
                  <div key={index} className="w-64">
                    {category.name}
                  </div>
                  <div
                    onClick={() => {
                      dispatch(
                        setCatagoryIdTobeEdited({
                          catagoryId: category._id,
                        })
                      );
                      dispatch(
                        setSelectedItem({ selectedItem: "Edit Catagory" })
                      );
                      onSelectSidebarItem("Edit Catagory");
                    }}
                    className="ml-20 cursor-pointer hover:text-teal-500"
                  >
                    <CiEdit size={24} color="#4CAF50" />
                  </div>
                  <div
                    className="mx-4 cursor-pointer hover:text-red-500"
                    onClick={() => openModal(category._id)}
                  >
                    <MdDelete size={24} color="#FF5252" />
                  </div>
                </div>
              ))}
            <Modal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              contentLabel="Example Modal"
              style={customStyles}
            >
              <div>
                <p className="text-center">
                  {catagoryType === "Main Catagories" ||
                  catagoryType === "Sub Catagories"
                    ? "Every Child Catagory associated with this modal will be deleted?"
                    : " Are You Sure You want to delete this child Catagory?"}
                </p>
                {successMessage && (
                  <p className="text-green-600 text-center">{successMessage}</p>
                )}
                {errMsg && <p className="text-red-600 text-center">{errMsg}</p>}
                <div className="flex justify-center mt-5">
                  <button
                    className="bg-gray-500 hover:bg-gray-400 w-20 px-2 py-1 rounded-md text-white"
                    type="button"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                  <button
                    className={`${
                      isDeleting ? "disabled:bg-red-400" : ""
                    } bg-red-600 hover:bg-red-500 w-20 mx-4 px-2 py-1 rounded-md text-white`}
                    type="button"
                    onClick={handleDelete}
                  >
                    {isDeleting ? "Deleting..." : "Delete"}
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      )}
    </>
  );
}

export default CatagoriesList;
