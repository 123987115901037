import axios from "axios";

const getUsersLocation = async () => {
  try {
    const response = await axios.get(
      "https://api.bigdatacloud.net/data/reverse-geocode-client"
    );
    const country = response.data.countryCode;
    switch (country) {
      case "US":
      case "CA":
        return "en";
      case "TR":
        return "en";
      case "DE":
        return "de";
      case "FR":
        return "en";
      default:
        return "en";
    }
  } catch (error) {
    return "tr";
  }
};

export default getUsersLocation;
