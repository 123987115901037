import React, { useState } from 'react';
import { selectSelectedItem } from '../../features/auth/authSlice';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setSelectedItem } from '../../features/auth/authSlice';

const Dropdown = ({
  title,
  items,
  selectedSidebarItem,
  onSelectSidebarItem,
}) => {

  const [isOpen, setIsOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(selectedSidebarItem);
  const dispatch = useDispatch();

  const selectedItem = useSelector(selectSelectedItem);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleItemClick = (item) => {
    dispatch(setSelectedItem({ selectedItem: item }));
    onSelectSidebarItem(item);
  };

  return (
    <div className="relative pt-1">
      <button
        onClick={toggleDropdown}
        className="flex  justify-center gap-4 cursor-pointer hover:bg-gray-100 pt-1 w-full"
      >
        <span className="opacity-100" style={{ fontSize: '24px' }}>
          {title.icon}
        </span>
        <p className="text-opacity-75" style={{ fontSize: '16px' }}>
          {title.text}
        </p>
        <div className="ml-auto mt-1">
          {isOpen ? (
            <span className="opacity-70 mr-2" style={{ fontSize: '14px' }}>
              {title.openIcon}
            </span>
          ) : (
            <span className="opacity-70 mr-2" style={{ fontSize: '14px' }}>
              {title.closeIcon}
            </span>
          )}
        </div>
      </button>
      {isOpen && (
        <div className="">
          {/* Dropdown content goes here */}
          <ul>
            {items.map((item, index) => (
              <li
                key={index}
                className={`${
                  selectedItem == item ? 'bg-gray-200' : ''
                } hover:bg-gray-100 w-full flex`}
                onClick={() => {
                  handleItemClick(item);
                }}
              >
                <p className="pt-1 pl-3" style={{ fontSize: '15px' }}>
                  <span className="bullet mr-2 ml-6 hover:text-blue-500">
                    &#8226;
                  </span>{' '}
                  {item}
                </p>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
