import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import { addPendingOrder, addPendingSeller, selectCurrentPendingOrders, selectCurrentPendingSellers, selectCurrentUserId } from '../../features/auth/authSlice';

function NotifyNewSeller() {
    const [istherenew, setistherenew] = useState(false);
    const pendingSellersList = useSelector(selectCurrentPendingSellers);
    const currentSellerId = String(useSelector(selectCurrentUserId));
    const dispatch = useDispatch();

  const socket = io(process.env.REACT_APP_BACKEND_API);
  socket.on('newSeller', (data) => {
    const seller  = data.updatedProfile;

    
    // Handle the new order event
    
   
    if (!pendingSellersList.includes(seller._id)) {
      dispatch(addPendingSeller({
        sellerId: seller._id
      }));

      setistherenew(true);
    } else {
    }
    setistherenew(true);
 
    // Display a notification or update the order list in the frontend
  });
  
    return <>
    {istherenew && <div></div>}
    </>
}

export default NotifyNewSeller;