import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import { addPendingOrder, selectCurrentPendingOrders, selectCurrentUserId } from '../../features/auth/authSlice';

function NotifyOrder() {
    const [istherenew, setistherenew] = useState(false);
    const pendingOrdersList = useSelector(selectCurrentPendingOrders);
    const currentSellerId = String(useSelector(selectCurrentUserId));
    const dispatch = useDispatch();

  const socket = io(process.env.REACT_APP_BACKEND_API);
  socket.on('newOrder', (data) => {
    ////console.log('data')
    ////console.log(data.order.sellerId)
    const order  = data.order;
    ////console.log(typeof order.sellerId);
////console.log(typeof currentSellerId);
    const condition = String(order.sellerId) === String(currentSellerId);
    ////console.log(!condition);
    if(condition){

    //console.log('alllah');
    
    // Handle the new order event
    //console.log("orderId")
    //console.log(order._id)
   
    if (!pendingOrdersList.includes(order._id)) {
      dispatch(addPendingOrder({
        orderId: order._id
      }));

      //console.log('Order added to pendingOrdersList:', order._id);
      setistherenew(true);
    } else {
      //console.log('Order already in pendingOrdersList:', order._id);
    }
    //console.log(order);
    setistherenew(true);
  } else {
    //console.log("still la ilaha ille llah" )
  }
    // Display a notification or update the order list in the frontend
  });
  
    return <>
    {istherenew && <div></div>}
    </>
}

export default NotifyOrder;