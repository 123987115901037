import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";

import {
  setProductId,
  setProductToBeDetailedId,
  setSelectedItem,
} from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import Modal from "react-modal";
import { useState } from "react";
import {
  useAddSliderMutation,
  useAddSliderSideMutation,
  useDeleteItemMutation,
  useRemoveFromSliderMutation,
} from "../../features/auth/authApiSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IoMdAddCircle } from "react-icons/io";
import { CgAddR } from "react-icons/cg";

function AdminDiscountCard({
  item,
  selectedSidebarItem,
  onSelectSidebarItem,
  handlePageChange,
  page,
}) {
  const { t } = useTranslation();

  const [addSlider, { isLoading: addItemIsLoading }] = useAddSliderMutation();

  const [addSliderSide, { isLoading: addItemhhjIsLoading }] =
    useAddSliderSideMutation();

  const [removeFromSlider, { isLoading: addItesmhhjIsLoading }] =
    useRemoveFromSliderMutation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);

  const [isThirdModalOpen, setIsThirdModalOpen] = useState(false);

  const openModal = () => {
    setErrMsg(null);
    setSuccessMsg(null);
    setIsModalOpen(true);
  };
  const openSecondModal = () => {
    setErrMsg(null);
    setSuccessMsg(null);
    setIsSecondModalOpen(true);
  };

  const openThirdModal = () => {
    setErrMsg(null);
    setSuccessMsg(null);
    setIsThirdModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeSecondModal = () => {
    setIsSecondModalOpen(false);
  };

  const closeThirdModal = () => {
    setIsThirdModalOpen(false);
  };

  const customStyles = {
    content: {
      maxWidth: "400px", // Adjust the maximum width as needed
      maxHeight: "150px", // Adjust the maximum height as needed
      margin: "auto",
    },
  };
  const rating = 5;

  const renderRatingStars = (rating) => {
    if (!rating) {
      return null;
    }
    const filledStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    const stars = [];

    for (let i = 0; i < filledStars; i++) {
      stars.push(
        <span key={i} className="text-orange-600">
          &#9733;
        </span>
      );
    }

    if (hasHalfStar) {
      stars.push(
        <span key={filledStars} className="text-orange-600">
          &#9733;
        </span>
      );
    }

    const emptyStars = 5 - filledStars - (hasHalfStar ? 1 : 0);

    for (let i = 0; i < emptyStars; i++) {
      stars.push(
        <span key={`empty-${i}`} className="text-gray-300">
          &#9733;
        </span>
      );
    }

    return stars;
  };

  const dispatch = useDispatch();

  const [isAddingToSlider, setIsAddingtoSlider] = useState(false);
  const [successMessage, setSuccessMsg] = useState();
  const [errMsg, setErrMsg] = useState();
  const handleAddSLiderSide = async (event) => {
    event.preventDefault();
    setIsAddingtoSlider(true);
    setErrMsg(null);
    setSuccessMsg(null);

    try {

      const addedItem = await addSliderSide({
        id: item._id,
      });
      if (addedItem.data.itemName) {
        setSuccessMsg(t("itemSuccesfullyAdded"));
      } else if (addedItem.data.isAlreadyAdded) {
        setSuccessMsg(t("itemAlreadyInSlider"));
      } else if (addedItem.data.MaximumAdded) {
        setErrMsg(t("maxNumberSliders"));
      }
    } catch (error) {
      setErrMsg("Un able to add to slider");
    } finally {
      setIsAddingtoSlider(false);
    }
  };

  const handleRemoveFromSlideOrSide = async (event) => {
    event.preventDefault();
    setIsAddingtoSlider(true);
    setErrMsg(null);
    setSuccessMsg(null);

    try {

      const addedItem = await removeFromSlider({
        id: item._id,
      });
      if (addedItem.data.itemName) {
        setSuccessMsg(t("itemSuccessfullyRemoved"));
      } else if (addedItem.data.isNotSlider) {
        setSuccessMsg(t("itemnotInslider"));
      } else if (addedItem.data.MaximumAdded) {
        setErrMsg(t("maxNumberSliders"));
      }
    } catch (error) {
      setErrMsg("Un able to remove from slider");
    } finally {
      setIsAddingtoSlider(false);
    }
  };

  const handleAddSLider = async (event) => {
    event.preventDefault();
    setIsAddingtoSlider(true);
    setErrMsg(null);
    setSuccessMsg(null);

    try {

      const addedItem = await addSlider({
        id: item._id,
      });
      if (addedItem.data.itemName) {
        setSuccessMsg(t("itemSuccesfullyAdded"));
      } else if (addedItem.data.isAlreadyAdded) {
        setSuccessMsg(t("itemAlreadyInSlider"));
      } else if (addedItem.data.MaximumAdded) {
        setErrMsg("Maximum Number of Sliders Are Added");
      }
    } catch (error) {
      setErrMsg("Un able to add to slider");
    } finally {
      setIsAddingtoSlider(false);
    }
  };
  const navigate = useNavigate();

  return (
    <>
      <div className="mt-2 flex bg-white p-2  rounded-md">
        <div>
          <img
            onClick={() => {
              dispatch(
                setProductToBeDetailedId({
                  productId: item._id,
                })
              );
              navigate("/productdetail");
            }}
            src={item.avatarUrl[0]}
            className="w-20 h-20 shrink-0 transform hover:scale-105 hover:duration-300"
          ></img>
        </div>
        <div className="ml-2">
          <p>{item.itemName}</p>
          <div className="flex items-center">
            {renderRatingStars(item.rating)}
          </div>
          <p className="text-orange-500">{item.discountAmount}%</p>
        </div>
        <div className="flex-col ml-2">
          <div
            onClick={openModal}
            className="ml-2 cursor-pointer hover:text-orange-600"
          >
            <IoMdAddCircle
              size={24}
              color="rgb(156 163 175 / var(--tw-text-opacity))"
              title={t("addToSlider")}
            />
          </div>
          <div
            type="button"
            onClick={openSecondModal}
            className="self-end mt-3 ml-2 cursor-pointer hover:text-gray-400"
            // onClick={openDeleteModal}
          >
            <CgAddR
              size={24}
              color="rgb(156 163 175 / var(--tw-text-opacity))"
              title={t("addToSliderSide")}
            />
          </div>
          <div
            type="button"
            onClick={openThirdModal}
            className="self-end mt-3 ml-2 cursor-pointer hover:text-gray-400"
            // onClick={openDeleteModal}
          >
            <MdDelete
              size={24}
              color="rgb(156 163 175 / var(--tw-text-opacity))"
              title={t("removeFromSlider")}
            />
          </div>
          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
            style={customStyles}
          >
            <div>
              <p className="text-center"> Add this Item to slider?</p>
              {successMessage && (
                <p className="text-green-600 text-center">{successMessage}</p>
              )}
              {errMsg && <p className="text-red-600 text-center">{errMsg}</p>}
              <div className="flex justify-center mt-5">
                <button
                  className="bg-gray-500 hover:bg-gray-400 w-20 px-2 py-1 rounded-md text-white"
                  type="button"
                  onClick={closeModal}
                >
                  {t("close")}
                </button>
                <button
                  className={`${
                    isAddingToSlider ? "disabled:bg-red-400" : ""
                  } bg-red-600 hover:bg-red-500  mx-4 px-2 py-1 rounded-md text-white`}
                  type="button"
                  onClick={handleAddSLider}
                >
                  {isAddingToSlider ? "adding..." : "add to Slider"}
                </button>
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={isSecondModalOpen}
            onRequestClose={closeSecondModal}
            contentLabel="Example Modal"
            style={customStyles}
          >
            <div>
              <p className="text-center"> Add this Item to slider Side?</p>
              {successMessage && (
                <p className="text-green-600 text-center">{successMessage}</p>
              )}
              {errMsg && <p className="text-red-600 text-center">{errMsg}</p>}
              <div className="flex justify-center mt-5">
                <button
                  className="bg-gray-500 hover:bg-gray-400 w-20 px-2 py-1 rounded-md text-white"
                  type="button"
                  onClick={closeSecondModal}
                >
                  {t("close")}
                </button>
                <button
                  className={`${
                    isAddingToSlider ? "disabled:bg-red-400" : ""
                  } bg-red-600 hover:bg-red-500  mx-4 px-2 py-1 rounded-md text-white`}
                  type="button"
                  onClick={handleAddSLiderSide}
                >
                  {isAddingToSlider ? "adding..." : "add to Slider Side"}
                </button>
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={isThirdModalOpen}
            onRequestClose={closeThirdModal}
            contentLabel="Example Modal"
            style={customStyles}
          >
            <div>
              <p className="text-center">
                {" "}
                Remove this Item from slider or Side?
              </p>
              {successMessage && (
                <p className="text-green-600 text-center">{successMessage}</p>
              )}
              {errMsg && <p className="text-red-600 text-center">{errMsg}</p>}
              <div className="flex justify-center mt-5">
                <button
                  className="bg-gray-500 hover:bg-gray-400 w-20 px-2 py-1 rounded-md text-white"
                  type="button"
                  onClick={closeThirdModal}
                >
                  {t("close")}
                </button>
                <button
                  className={`${
                    isAddingToSlider ? "disabled:bg-red-400" : ""
                  } bg-red-600 hover:bg-red-500  mx-4 px-2 py-1 rounded-md text-white`}
                  type="button"
                  onClick={handleRemoveFromSlideOrSide}
                >
                  {isAddingToSlider ? "removing..." : "Remove"}
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
}
export default AdminDiscountCard;
