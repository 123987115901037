import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Public from "./components/Public";
import Login from "./features/auth/Login";
import Welcome from "./features/auth/Welcome";
import RequireAuth from "./features/auth/RequireAuth";
import UsersList from "./features/users/UsersList";
import Success from "./features/stripe_test/success";
import Failure from "./features/stripe_test/Failure";
import CheckOutButton from "./features/stripe_test/stripe";
import SellerDashBoard from "./features/auth/SellerDashBoard";
import SignUpBody from "./features/auth/signup";
import SellerSignUpBody from "./features/auth/sellerSignUp";
import BuyerProfile from "./components/Buyer/BuyerProfile";
import ProductDetailsPage from "./components/ProductDetailsPage";
import AdminNavBar from "./components/Admin/adminNavBar";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import SearchResults from "./components/searchResultsPage";
import ResetPasswordPage from "./features/auth/resetPasswordPage";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { LoaderContext } from "./contexts/LoaderContext";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  useGetAllItemNumbersMutation,
  useGetAllReviewNumbersMutation,
  useGetBuyerByAccountStatusMutation,
  useGetCurrentUserByCookyMutation,
  useGetMonthlySalesListMutation,
  useGetOrdersByStatusBuyerMutation,
  useGetOrdersByStatusSellerMutation,
  useGetSellerByBusinessStatusMutation,
  useGetTotalDailySalesMutation,
  useGetTotalMonthlySalesMutation,
  useGetTotalWeeklySalesMutation,
  useGetTotalYearlySalesMutation,
} from "./features/auth/authApiSlice";
import {
  setCredentials,
  setUser,
  setSelectedProfileBar,
  setBuyer,
  addPendingOrder,
  addPreparingOrder,
  addDeliveredOrders,
  addCanceledOrders,
  addOntheWayOrder,
  addPendingSeller,
  addBlockedSeller,
  addBlockedBuyer,
  addVerifiedSeller,
  addVerifiedBuyer,
  setTotalReviewsAndItems,
  setSellerAdminData,
} from "./features/auth/authSlice";
import Cookies from "js-cookie";
import MyLoadingSpinner from "./components/Spinner";

// legal pages
import {
  Conditions,
  CookiePolicy,
  Imprint,
  PrivacyPolicy,
  RightOfWithdrawal,
} from "./legal";

function App() {
  const dispatch = useDispatch();

  const [isUserDataLoading, setIsUserDataLoading] = useState(true);

  const [getCurrentUserByCooky, { iscookyLoading }] =
    useGetCurrentUserByCookyMutation();

  const [getOrdersByStatusAndSeller, { isSellerLoading }] =
    useGetOrdersByStatusSellerMutation();

  const [getAllItemNumbers, { isAllItemsLoading }] =
    useGetAllItemNumbersMutation();

  const [getTotalDailySales, { isDailyLoading }] =
    useGetTotalDailySalesMutation();
  const [getMonthlySalesList, { isMlistLoading }] =
    useGetMonthlySalesListMutation();
  const [getTotalWeeklySales, { isweekLoading }] =
    useGetTotalWeeklySalesMutation();
  const [getTotalMonthlySales, { isDyLoading }] =
    useGetTotalMonthlySalesMutation();
  const [getTotalYearlySales, { isDaiyLoading }] =
    useGetTotalYearlySalesMutation();

  const [getAllReviewNumbers, { isAllReviewLaoding }] =
    useGetAllReviewNumbersMutation();

  const [getOrdersByStatusAndBuyer, { isBuyerLoading }] =
    useGetOrdersByStatusBuyerMutation();

  const [getBuyerByAccountStatus, { isBuyerAccountLoading }] =
    useGetBuyerByAccountStatusMutation();

  const [getSellerByBusinessStatus, { isSellerAccountLoadingLoading }] =
    useGetSellerByBusinessStatusMutation();

  useEffect(() => {
    // Perform backend operations (e.g., fetch data) here
    const fetchData = async () => {
      try {
        setIsUserDataLoading(true);
        const refreshToken = Cookies.get("refreshToken");
        if (refreshToken) {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_API}/auth/getCurrentUser`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${refreshToken}`,
              },
              // body: JSON.stringify(yourData),
            }
          );
          const userData = await response.json();

          if (userData[1].accountStatus === "pending") {
            // Display a message when the account status is "pending"
            setIsUserDataLoading(false);
            return; // Prevent further execution
          }

          let dailysales;
          let weeklysales;
          let monthlysales;
          let yearlysales;
          let monthlySaleList;
          let totalSellerItemlength;
          const Id = userData[1]._id;

          const userEmail = userData[1].email;

          const access_token = Cookies.get("accessToken");
          const refresh_token = Cookies.get("refreshToken");

          dispatch(
            setCredentials({
              user: userEmail,
              accessToken: access_token,
              refreshtoken: refresh_token,
            })
          );

          const role = userData[1].role;
          const cart = userData[1].cart;
          const country = userData[1].country ? userData[1].country : null;
          const region = userData[1].state ? userData[1].state : null;
          const city = userData[1].city ? userData[1].city : null;
          const accountNumber = userData[1].accountNumber
            ? userData[1].accountNumber
            : null;
          const additionalAccountNumber = userData[1].additionalAccountNumber
            ? userData[1].additionalAccountNumber
            : null;
          const additionalStreetAddress = userData[1].additionalStreetAddress
            ? userData[1].additionalStreetAddress
            : null;
          const zipCode = userData[1].zipCode ? userData[1].zipCode : null;
          const streetAddress = userData[1].streetAddress
            ? userData[1].streetAddress
            : null;
          const phoneNumber = userData[1].phoneNumber
            ? userData[1].phoneNumber
            : null;

          const postalCode = userData[1].postalCode
            ? userData[1].postalCode
            : null;
          const homeNumber = userData[1].homeNumber
            ? userData[1].homeNumber
            : null;
          const userImgUrl = userData[1].imgUrl ? userData[1].imgUrl : null;

          let name;
          if (role == process.env.REACT_APP_BUYER_ROLE) {
            name = userData[1].fullName;
            const preparingOrders = await getOrdersByStatusAndBuyer({
              orderStatus: "preparing",
              buyerId: Id,
            });
            const onthewayOrders = await getOrdersByStatusAndBuyer({
              orderStatus: "ontheway",
              buyerId: Id,
            });
            const pendingOrders = await getOrdersByStatusAndBuyer({
              orderStatus: "pending",
              buyerId: Id,
            }).unwrap();
            const deliveredOrders = await getOrdersByStatusAndBuyer({
              orderStatus: "delivered",
              buyerId: Id,
            });
            const cancelledOrders = await getOrdersByStatusAndBuyer({
              orderStatus: "cancelled",
              buyerId: Id,
            });

            {
              preparingOrders.data?.length != 0 &&
                preparingOrders.data?.map((order) => {
                  dispatch(
                    addPreparingOrder({
                      orderId: order._id,
                    })
                  );
                });
            }
            {
              onthewayOrders.data?.length != 0 &&
                onthewayOrders.data?.map((order) => {
                  dispatch(
                    addOntheWayOrder({
                      orderId: order._id,
                    })
                  );
                });
            }

            {
              pendingOrders?.length != 0 &&
                pendingOrders?.map((order) => {
                  dispatch(
                    addPendingOrder({
                      orderId: order._id,
                    })
                  );
                });
            }
            {
              deliveredOrders.data?.length != 0 &&
                deliveredOrders.data.map((order) => {
                  dispatch(
                    addDeliveredOrders({
                      orderId: order._id,
                    })
                  );
                });
            }
            {
              cancelledOrders.data.length &&
                cancelledOrders.data.map((order) => {
                  dispatch(
                    addCanceledOrders({
                      orderId: order._id,
                    })
                  );
                });
            }
          } else if (role == process.env.REACT_APP_SELLER_ROLE) {
            totalSellerItemlength = await getAllItemNumbers({
              sellerId: userData._id,
            });

            dailysales = await getTotalDailySales({
              sellerId: userData._id,
            });
            weeklysales = await getTotalWeeklySales({
              sellerId: userData._id,
            });
            monthlysales = await getTotalMonthlySales({
              sellerId: userData._id,
            });
            yearlysales = await getTotalYearlySales({
              sellerId: userData._id,
            });
            monthlySaleList = await getMonthlySalesList({
              sellerId: userData._id,
            });
            const preparingOrders = await getOrdersByStatusAndSeller({
              orderStatus: "preparing",
              sellerId: Id,
            });
            const onthewayOrders = await getOrdersByStatusAndSeller({
              orderStatus: "ontheway",
              sellerId: Id,
            });
            const pendingOrders = await getOrdersByStatusAndSeller({
              orderStatus: "pending",
              sellerId: Id,
            }).unwrap();
            const deliveredOrders = await getOrdersByStatusAndSeller({
              orderStatus: "delivered",
              sellerId: Id,
            });
            const cancelledOrders = await getOrdersByStatusAndSeller({
              orderStatus: "cancelled",
              sellerId: Id,
            });

            {
              preparingOrders.data.length != 0 &&
                preparingOrders.data.map((order) => {
                  dispatch(
                    addPreparingOrder({
                      orderId: order._id,
                    })
                  );
                });
            }
            {
              onthewayOrders.data.length != 0 &&
                onthewayOrders.data.map((order) => {
                  dispatch(
                    addOntheWayOrder({
                      orderId: order._id,
                    })
                  );
                });
            }

            {
              pendingOrders.length != 0 &&
                pendingOrders.map((order) => {
                  dispatch(
                    addPendingOrder({
                      orderId: order._id,
                    })
                  );
                });
            }
            {
              deliveredOrders.data.length != 0 &&
                deliveredOrders.data.map((order) => {
                  dispatch(
                    addDeliveredOrders({
                      orderId: order._id,
                    })
                  );
                });
            }
            {
              cancelledOrders.data.length &&
                cancelledOrders.data.map((order) => {
                  dispatch(
                    addCanceledOrders({
                      orderId: order._id,
                    })
                  );
                });
            }

            name = userData[1].name;
            const businessStatus = userData[1].businessStatus;
            if (businessStatus == "pending") {
              setIsUserDataLoading(false);
              return;
            }
          } else if (role === process.env.REACT_APP_ADMIN_ROLE) {
            const totalItemlength = await getAllItemNumbers();
            const totalReviewLength = await getAllReviewNumbers();

            dailysales = await getTotalDailySales();
            weeklysales = await getTotalWeeklySales();
            monthlysales = await getTotalMonthlySales();
            yearlysales = await getTotalYearlySales();
            monthlySaleList = await getMonthlySalesList();

            dispatch(
              setTotalReviewsAndItems({
                reviewLength: totalReviewLength.data,
                itemLength: totalItemlength.data,
              })
            );
            const PendingSellers = await getSellerByBusinessStatus({
              businessStatus: "pending",
            });
            const VerifiedSellers = await getSellerByBusinessStatus({
              businessStatus: "verified",
            });
            const BlockedSellers = await getSellerByBusinessStatus({
              businessStatus: "blocked",
            });
            const BlockedBuyers = await getBuyerByAccountStatus({
              accountStatus: "blocked",
            });
            const VerifiedBuyers = await getBuyerByAccountStatus({
              accountStatus: "verified",
            });

            {
              PendingSellers.data.length != 0 &&
                PendingSellers.data.map((seller) => {
                  dispatch(
                    addPendingSeller({
                      sellerId: seller._id,
                    })
                  );
                });
            }
            {
              BlockedSellers.data.length != 0 &&
                BlockedSellers.data.map((seller) => {
                  dispatch(
                    addBlockedSeller({
                      sellerId: seller._id,
                    })
                  );
                });
            }

            {
              BlockedBuyers.data.length != 0 &&
                BlockedBuyers.data.map((buyer) => {
                  dispatch(
                    addBlockedBuyer({
                      buyerId: buyer._id,
                    })
                  );
                });
            }
            {
              VerifiedSellers.data.length != 0 &&
                dispatch(
                  addVerifiedSeller({
                    number: VerifiedSellers.data.length,
                  })
                );
            }
            {
              VerifiedBuyers.data.length != 0 &&
                dispatch(
                  addVerifiedBuyer({
                    number: VerifiedBuyers.data.length,
                  })
                );
            }
          }

          const accountStatus = userData[1].accountStatus;

          dispatch(setSelectedProfileBar({ SelectedProfileBar: "Dashboard" }));
          dispatch(
            setUser({
              id: Id,
              email: userEmail,
              name: name,
              role: role,
              accountStatus: accountStatus,
              cart: cart,
            })
          );

          if (role === process.env.REACT_APP_BUYER_ROLE) {
            dispatch(
              setBuyer({
                country: country,
                region: region,
                city: city,
                streetAddress: streetAddress,
                additionalStreetAddress: additionalStreetAddress,
                zipCode: zipCode,
                phoneNumber: phoneNumber,
                postalCode: postalCode,
                homeNumber: homeNumber,
                imgUrl: userImgUrl,
              })
            );
            setIsUserDataLoading(false);
          } else if (role === process.env.REACT_APP_SELLER_ROLE) {
            const sellerData = {
              dailysales: dailysales.data,
              weeklysales: weeklysales.data,
              monthlysales: monthlysales.data,
              yearlysales: yearlysales.data,
              monthlySaleList: monthlySaleList.data,
              totalSellerItemlength: totalSellerItemlength.data,
            };
            dispatch(setSellerAdminData({ data: sellerData }));
            dispatch(
              setBuyer({
                country: country,
                region: region,
                city: city,
                streetAddress: streetAddress,
                additionalStreetAddress: additionalStreetAddress,
                zipCode: zipCode,
                phoneNumber: phoneNumber,
                postalCode: postalCode,
                homeNumber: homeNumber,
                imgUrl: userImgUrl,
                accountNumber: accountNumber,
                additionalAccountNumber: additionalAccountNumber,
              })
            );
            setIsUserDataLoading(false);
          } else if (role === process.env.REACT_APP_ADMIN_ROLE) {
            const adminData = {
              dailysales: dailysales.data,
              weeklysales: weeklysales.data,
              monthlysales: monthlysales.data,
              yearlysales: yearlysales.data,
              monthlySaleList: monthlySaleList.data,
            };
            dispatch(setSellerAdminData({ data: adminData }));

            setIsUserDataLoading(false);
          }
        } else {
          console.error("Error fetching backend data:");
          setIsUserDataLoading(false);
        } // Update state with backend data
      } catch (error) {
        console.error("Error fetching backend data:", error);
        setIsUserDataLoading(false);
      }
    };

    fetchData();
  }, []);

  return isUserDataLoading ? (
    <MyLoadingSpinner></MyLoadingSpinner>
  ) : (
    <PayPalScriptProvider
      options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}
    >
      <div>
        <TawkMessengerReact
          propertyId="656205a3ba9fcf18a80ece58"
          widgetId="1hg3ec2a2"
        />
        <LoaderContext>
          <Routes>
            <Route path="/" element={<Layout />}>
              {/* public routes */}
              <Route index element={<Welcome />} />{" "}
              {/* Make Welcome the default landing page */}
              <Route path="login" element={<Login />} />
              <Route path="success" element={<Success />} />
              <Route path="failure" element={<Failure />} />
              <Route path="public" element={<Public />} />
              <Route path="signup" element={<SignUpBody />} />
              <Route path="signupseller" element={<SellerSignUpBody />} />
              <Route path="productdetail" element={<ProductDetailsPage />} />
              <Route path="searchResultsPage" element={<SearchResults />} />
              <Route path="resetPassword" element={<ResetPasswordPage />} />
              {/* Legal Pages */}
              <Route path="Conditions" element={<Conditions />} />
              <Route path="Cookie_Policy" element={<CookiePolicy />} />
              <Route path="Imprint" element={<Imprint />} />
              <Route path="Privacy_Policy" element={<PrivacyPolicy />} />
              <Route
                path="Right_Of_Withdrawal"
                element={<RightOfWithdrawal />}
              />
              {/* protected routes */}
              <Route element={<RequireAuth />}>
                <Route path="SellerDashBoard" element={<SellerDashBoard />} />
                <Route path="SellerDashBoard2" element={<SellerDashBoard />} />
                <Route path="buyerprofile" element={<BuyerProfile />} />
                <Route path="userslist" element={<UsersList />} />
                <Route path="admin" element={<AdminNavBar />} />

                <Route path="stripe" element={<CheckOutButton />} />
              </Route>
            </Route>
          </Routes>
        </LoaderContext>
      </div>
    </PayPalScriptProvider>
  );
}

export default App;
