import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const SalesChart = ({ data }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');

      chartInstance.current = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          datasets: [
            {
              label: 'Monthly Sales',
              data: data,
              backgroundColor: 'orange',
              borderWidth: 1,
              borderRadius: 10, 
              
            },
          ],
        },
        options: {
          scales: {
            x: {
                display: false, // Hide x-axis line and labels
              },
              y: {
                display: false, // Hide y-axis line and labels
                beginAtZero: true,
              },
          },
        },
      });
    }
  }, [data]);

  return (
    <div style={{ width: '600px', height: '400px' }}>
      <canvas ref={chartRef} width={600} height={400} />
    </div>
  );
};

export default SalesChart;
