// PDFComponent.js
import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 20,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#6B46C1', // Set the background color to the darker purple
    padding: 10,
    marginBottom: 10,
  },
  headerText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#fff', // Text color
  },
  companyName: {
    fontSize: 14,
    color: '#fff', // Text color
  },
  line: {
    borderBottom: 2,
    marginTop: 10,
    marginBottom: 10,
    width: '40%',
  },
  seller: {
    fontSize: 12,
    marginBottom: 10,
  },
  buyerInfo: {
    fontSize: 12,
    marginBottom: 10,
  },
  table: {
    display: 'table',
    width: 'auto',
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
  },
  tableCell: {
    width: '20%',
    borderRightWidth: 1,
    fontSize: 10,
    padding: 5,
  },
});

const PDFComponent = ({ order }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <View>
          <Text style={styles.headerText}>Fatura</Text>
        </View>
        <View>
          <Text style={styles.companyName}>Saletoeurope</Text>
        </View>
      </View>
      <View style={styles.line} />
      <Text style={styles.seller}>{order.order.sellerName}</Text>
      <Text style={styles.seller}>
        {order.seller.streetAddress},{order.seller.city}
      </Text>
      <Text style={styles.seller}>
        {order.seller.state},{order.seller.country}
      </Text>
      <Text style={styles.seller}>Tel: {order.seller.phoneNumber}</Text>
      <Text style={styles.seller}>Email: {order.seller.email}</Text>
      <View style={styles.line} />
      <Text style={styles.buyerInfo}>SAYIN</Text>
      <Text style={styles.buyerInfo}>{order.order.buyerName}</Text>
      <Text style={styles.buyerInfo}>
        {order.order.buyerStreetAddress}, {order.order.buyerCity}
      </Text>
      <Text style={styles.buyerInfo}>
        {order.order.buyerState}, {order.order.buyerCountry}
      </Text>
      <Text style={styles.buyerInfo}>{'\n'}</Text>
      <Text style={styles.buyerInfo}>Website: saletoeurope.com</Text>

      <View style={styles.line} />

      {/* Table 1 */}
      <View style={styles.table}>
        {/* Rows of the table */}
        {/* Customize the data based on your needs */}
        <View style={styles.tableRow}>
          <Text style={styles.tableCell}>Özelleştirme</Text>
          <Text style={styles.tableCell}>TR1.2</Text>
          {/* Add other cells as needed */}
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableCell}>No</Text>
          <Text style={styles.tableCell}>Senaryo</Text>
          {/* Add other cells as needed */}
        </View>
        {/* Add more rows as needed */}
      </View>

      <View style={styles.line} />

      {/* Table 2 */}
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <Text style={styles.tableCell}>Sıra No</Text>
          <Text style={styles.tableCell}>Mal Hizmet</Text>
          {/* Add other cells as needed */}
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableCell}>1</Text>
          <Text style={styles.tableCell}>
            Lelas 1614 55 Ml Prive Lelas Noir 55 Ml Kadın Erkek Parfümü
          </Text>
          {/* Add other cells as needed */}
        </View>
        {/* Add more rows as needed */}
      </View>
    </Page>
  </Document>
);

export default PDFComponent;
