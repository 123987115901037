import React, { createContext, useEffect, useState } from 'react'


const WelcomeLoader = createContext();

function LoaderContext({children}) {
  const [isWelcomeloading, setIsWelcomeloading] = useState(true);
  const [isSliderLoading, setIsSliderLoading] = useState(true);
  const [isNewItemsLoading, setIsNewItemsLoading] = useState(true);
  const [isTrendigLoading, setIsTrendigLoading] = useState(true);

  useEffect(() => {
    const setData = () => {
      if (!isSliderLoading && !isNewItemsLoading && !isTrendigLoading) {
        setIsWelcomeloading(false);
      }
    }; 
    
    setData();
  }, [isSliderLoading, isNewItemsLoading, isTrendigLoading, isWelcomeloading])
  
  const contextValue = {
    isWelcomeloading,
    setIsWelcomeloading,
    isSliderLoading,
    setIsSliderLoading,
    isNewItemsLoading,
    setIsNewItemsLoading,
    isTrendigLoading,
    setIsTrendigLoading,
  };

  return (
    <WelcomeLoader.Provider value={contextValue}>
      {children}
    </WelcomeLoader.Provider>
  )
}

export { LoaderContext, WelcomeLoader }