import { apiSlice } from '../../app/api/apiSlice';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: '/auth/login',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    addItem: builder.mutation({
      query: (credentials) => ({
        url: '/seller/addItem',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    addReview: builder.mutation({
      query: (credentials) => ({
        url: '/buyer/addReview',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    isEligibleToReview: builder.mutation({
      query: (credentials) => ({
        url: '/buyer/isEligibleToReview',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    createCoupon: builder.mutation({
      query: (credentials) => ({
        url: '/seller/createCoupon',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    addCart: builder.mutation({
      query: (credentials) => ({
        url: '/buyer/addCart',
        method: 'POST',
        body: { ...credentials },
      }),
    }),

    removeCart: builder.mutation({
      query: (credentials) => ({
        url: '/buyer/removeCart',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getTotalDailySales: builder.mutation({
      query: (credentials) => ({
        url: '/admin/getTotalSalesDaily',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getTotalWeeklySales: builder.mutation({
      query: (credentials) => ({
        url: '/admin/getTotalSalesWeekly',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getTotalMonthlySales: builder.mutation({
      query: (credentials) => ({
        url: '/admin/getTotalSalesMonthly',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getMonthlySalesList: builder.mutation({
      query: (credentials) => ({
        url: '/admin/getMonthlySalesList',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getTotalYearlySales: builder.mutation({
      query: (credentials) => ({
        url: '/admin/getTotalSalesYearly',
        method: 'POST',
        body: { ...credentials },
      }),
    }),

    addBuyer: builder.mutation({
      query: (credentials) => ({
        url: '/auth/signup/buyer',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    addSeller: builder.mutation({
      query: (credentials) => ({
        url: '/auth/signup/seller',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    updateBuyer: builder.mutation({
      query: (credentials) => ({
        url: '/auth/update/buyer',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    updateBuyerByAdmin: builder.mutation({
      query: (credentials) => ({
        url: '/auth/update/buyerByAdmin',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    updateItem: builder.mutation({
      query: (credentials) => ({
        url: '/seller/updateItem',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    deleteItem: builder.mutation({
      query: (credentials) => ({
        url: '/seller/deleteItem',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    updateSeller: builder.mutation({
      query: (credentials) => ({
        url: '/auth/update/seller',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    createCatagory: builder.mutation({
      query: (credentials) => ({
        url: '/admin/createCategory',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getCatagoriesByType: builder.mutation({
      query: (credentials) => ({
        url: '/admin/getCatagoriesByType',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getItemsByMainCatagory: builder.mutation({
      query: (credentials) => ({
        url: '/admin/getAllItemsByMainCatagory',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getItemsBySubCatagory: builder.mutation({
      query: (credentials) => ({
        url: '/admin/getAllItemsBySubCatagory',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getItemsByChildCatagory: builder.mutation({
      query: (credentials) => ({
        url: '/admin/getAllItemsByChildCatagory',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    updateSellerBusinnessStatus: builder.mutation({
      query: (credentials) => ({
        url: '/auth/update/updateSellerBusinessStatus',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getItems: builder.mutation({
      query: (credentials) => ({
        url: '/admin/getItemss',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getReviewsOfProduct: builder.mutation({
      query: (credentials) => ({
        url: '/buyer/getReviewsOfProduct',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getSellerItemsByPage: builder.mutation({
      query: (credentials) => ({
        url: '/admin/getSellerItemsbyPage',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getAllItemsByPage: builder.mutation({
      query: (credentials) => ({
        url: '/admin/getItemsbyPage',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getSellerStockOutItems: builder.mutation({
      query: (credentials) => ({
        url: '/seller/getAllStockOutItemsOfSeller',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getCartItems: builder.mutation({
      query: (credentials) => ({
        url: '/admin/getItembyId',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    changeProfile: builder.mutation({
      query: (credentials) => ({
        url: '/buyer/updateProfile',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    changeSellerProfile: builder.mutation({
      query: (credentials) => ({
        url: '/seller/updateProfile',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    stripePay: builder.mutation({
      query: (credentials) => ({
        url: '/stripe/stripe',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    PayPalPay: builder.mutation({
      query: (credentials) => ({
        url: '/stripe/paypal-webhook',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    stripePayUsingCoupon: builder.mutation({
      query: (credentials) => ({
        url: '/stripe/stripeUsingCoupon',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getOrdersByStatusSeller: builder.mutation({
      query: (credentials) => ({
        url: '/seller/getOrdersByStatusAndSeller',
        method: 'POST',
        body: { ...credentials },
      }),
    }),

    getOrdersByStatusOnly: builder.mutation({
      query: (credentials) => ({
        url: '/admin/getOrdersByStatusOnly',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getOrdersByStatusBuyer: builder.mutation({
      query: (credentials) => ({
        url: '/buyer/getOrdersByStatusAndBuyer',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    deleteCatagoryById: builder.mutation({
      query: (credentials) => ({
        url: '/admin/deleteCatagoryById',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getMainCatagoriesSeller: builder.mutation({
      query: (credentials) => ({
        url: '/seller/getAvailableMainCategoriesForSeller',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getSubCatagoriesSeller: builder.mutation({
      query: (credentials) => ({
        url: '/seller/getAvailableSubCategoriesForSeller',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getChildCatagoriesSeller: builder.mutation({
      query: (credentials) => ({
        url: '/seller/getAvailableChildCategoriesForSeller',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getAllItemsOfSeller: builder.mutation({
      query: (credentials) => ({
        url: '/seller/getAllItemsOfSeller',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getItemById: builder.mutation({
      query: (credentials) => ({
        url: '/admin/getItembyId',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    searchItemsOfSeller: builder.mutation({
      query: (credentials) => ({
        url: '/seller/SearchItemsBySeller',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    searchAllItems: builder.mutation({
      query: (credentials) => ({
        url: '/admin/searchAllitems',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getAllCouponsOfSeller: builder.mutation({
      query: (credentials) => ({
        url: '/seller/getAllCouponsOfSeller',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getCouponById: builder.mutation({
      query: (credentials) => ({
        url: '/seller/getCouponByID',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getSellerByBusinessStatus: builder.mutation({
      query: (credentials) => ({
        url: '/admin/getSellersByBusinessStatus',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getSellerByBusinessStatusAndPage: builder.mutation({
      query: (credentials) => ({
        url: '/admin/getSellerByBusinessStatusAndPage',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getBuyerByAccountStatus: builder.mutation({
      query: (credentials) => ({
        url: '/admin/getBuyersByAccountStatus',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getBuyerByAccountStatusAndPage: builder.mutation({
      query: (credentials) => ({
        url: '/admin/getBuyerByAccountStatusAndPage',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    editCoupon: builder.mutation({
      query: (credentials) => ({
        url: '/seller/editCoupon',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    deleteCoupon: builder.mutation({
      query: (credentials) => ({
        url: '/seller/deleteCoupon',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    updateOrderStatus: builder.mutation({
      query: (credentials) => ({
        url: '/buyer/updateOrder',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    editCatagory: builder.mutation({
      query: (credentials) => ({
        url: '/admin/editCatagoryById',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getCatagoryById: builder.mutation({
      query: (credentials) => ({
        url: '/admin/getCatagoryById',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getAllItemNumbers: builder.mutation({
      query: (credentials) => ({
        url: '/admin/getAllItemNumbers',
        method: 'POST',
        body: { ...credentials },
      }),
    }),

    getLoginInfo: builder.mutation({
      query: (credentials) => ({
        url: '/auth/isLoggedIn',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    resetPassword: builder.mutation({
      query: (credentials) => ({
        url: '/auth/resetPassword',
        method: 'POST',
        body: { ...credentials },
      }),
    }),

    changePassword: builder.mutation({
      query: (credentials) => ({
        url: '/auth/changePassWord',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    addSlider: builder.mutation({
      query: (credentials) => ({
        url: '/seller/addToSlider',
        method: 'POST',
        body: { ...credentials },
      }),
    }),

    addShippingCountry: builder.mutation({
      query: (credentials) => ({
        url: '/admin/addCountry',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    addShippingRule: builder.mutation({
      query: (credentials) => ({
        url: '/admin/addShippingRule',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getShippingRules: builder.mutation({
      query: (credentials) => ({
        url: '/admin/getShippingRules',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getShippingCountries: builder.mutation({
      query: (credentials) => ({
        url: '/admin/getShippingCountries',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    deleteShippingRegion: builder.mutation({
      query: (credentials) => ({
        url: '/admin/DeleteShippingRegion',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    updateShippingRule: builder.mutation({
      query: (credentials) => ({
        url: '/admin/editshippingRule',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    deleteShippingRule: builder.mutation({
      query: (credentials) => ({
        url: '/admin/deleteShippingRule',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    addShippingProvider: builder.mutation({
      query: (credentials) => ({
        url: '/admin/addShippingProvider',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    deleteShippingProvider: builder.mutation({
      query: (credentials) => ({
        url: '/admin/deleteShippingProvider',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    addSliderSide: builder.mutation({
      query: (credentials) => ({
        url: '/seller/addToSliderSide',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getSliderAndSideItems: builder.mutation({
      query: (credentials) => ({
        url: '/seller/getSliderAndSideItems',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    removeFromSlider: builder.mutation({
      query: (credentials) => ({
        url: '/seller/removeFromSlider',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    removeFromSliderSide: builder.mutation({
      query: (credentials) => ({
        url: '/seller/removeFromSliderSide',
        method: 'POST',
        body: { ...credentials },
      }),
    }),

    checkResetPassword: builder.mutation({
      query: (credentials) => ({
        url: '/auth/checkResetPassword',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getAllReviewNumbers: builder.mutation({
      query: (credentials) => ({
        url: '/admin/getallReviewsNumbers',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getCurrentUserByCooky: builder.mutation({
      query: (credentials) => ({
        url: '/auth/getCurrentUser',
        method: 'POST',
        body: { ...credentials },
      }),
    }),

    getShippingProviders: builder.mutation({
      query: (credentials) => ({
        url: '/admin/getShippingProviders',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    getItemsWithDiscounts: builder.mutation({
      query: (credentials) => ({
        url: '/admin/getAllItemsWithDiscounts',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useAddItemMutation,
  useCreateCouponMutation,
  useAddBuyerMutation,
  useUpdateBuyerMutation,
  useAddSellerMutation,
  useUpdateSellerMutation,
  useUpdateSellerBusinnessStatusMutation,
  useGetItemsMutation,
  useAddCartMutation,
  useRemoveCartMutation,
  useGetCartItemsMutation,
  useChangeProfileMutation,
  useStripePayMutation,
  usePayPalPayMutation,
  useGetOrdersByStatusSellerMutation,
  useGetOrdersByStatusBuyerMutation,
  useUpdateOrderStatusMutation,
  useGetMainCatagoriesSellerMutation,
  useGetSubCatagoriesSellerMutation,
  useGetChildCatagoriesSellerMutation,
  useGetAllItemsOfSellerMutation,
  useGetAllCouponsOfSellerMutation,
  useGetCouponByIdMutation,
  useEditCouponMutation,
  useDeleteCouponMutation,
  useChangeSellerProfileMutation,
  useSearchItemsOfSellerMutation,
  useGetSellerItemsByPageMutation,
  useGetItemByIdMutation,
  useUpdateItemMutation,
  useDeleteItemMutation,
  useGetSellerStockOutItemsMutation,
  useAddReviewMutation,
  useIsEligibleToReviewMutation,
  useGetReviewsOfProductMutation,
  useGetSellerByBusinessStatusMutation,
  useGetBuyerByAccountStatusMutation,
  useGetSellerByBusinessStatusAndPageMutation,
  useGetBuyerByAccountStatusAndPageMutation,
  useUpdateBuyerByAdminMutation,
  useCreateCatagoryMutation,
  useGetCatagoriesByTypeMutation,
  useDeleteCatagoryByIdMutation,
  useEditCatagoryMutation,
  useGetCatagoryByIdMutation,
  useGetAllItemsByPageMutation,
  useSearchAllItemsMutation,
  useGetOrdersByStatusOnlyMutation,
  useGetAllItemNumbersMutation,
  useGetAllReviewNumbersMutation,
  useGetItemsByMainCatagoryMutation,
  useGetItemsBySubCatagoryMutation,
  useGetItemsByChildCatagoryMutation,
  useGetTotalDailySalesMutation,
  useGetTotalWeeklySalesMutation,
  useGetTotalMonthlySalesMutation,
  useGetTotalYearlySalesMutation,
  useGetMonthlySalesListMutation,
  useGetLoginInfoMutation,
  useResetPasswordMutation,
  useCheckResetPasswordMutation,
  useChangePasswordMutation,
  useStripePayUsingCouponMutation,
  useGetItemsWithDiscountsMutation,
  useAddSliderMutation,
  useAddSliderSideMutation,
  useGetSliderAndSideItemsMutation,
  useRemoveFromSliderMutation,
  useAddShippingCountryMutation,
  useAddShippingRuleMutation,
  useGetShippingRulesMutation,
  useGetShippingCountriesMutation,
  useDeleteShippingRegionMutation,
  useUpdateShippingRuleMutation,
  useDeleteShippingRuleMutation,
  useGetCurrentUserByCookyMutation,
  useAddShippingProviderMutation,
  useDeleteShippingProviderMutation,
  useGetShippingProvidersMutation
  
} = authApiSlice;
