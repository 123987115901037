// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

//Language resources
import global_en from "./locales/en/translate.json";
import global_tr from "./locales/tr/translate.json";
import global_dt from "./locales/de/translate.json";
import getUsersLocation from "./userLocation";

//GetUsersLocation
const language = (await getUsersLocation()) || "en";

const resources = {
  en: {
    translation: global_en,
  },

  tr: {
    translation: global_tr,
  },
  de: {
    translation: global_dt,
  },
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: language,
    interpolation: { escapeValue: false },
  });

export default i18n;
