import { usePerformPayementQuery } from './StripeApiSlice';

const CheckOutButton = () => {
  const { data, isLoading, error } = usePerformPayementQuery({
    order: {
      itemId: '65508c0a801e3cd4fdf1ec0f',
      quantity: 3,
      buyerId: 'string',
      sellerId: '654ab694d11b5274942c1dff',
      isUsingCoupon: false,
      couponId: "653d77701dc0d80c33c22746"

    },
  });

  let content;

  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (data) {
    <button onClick={(window.location = data.url)}>checkout</button>;
  } else if (error) {
    content = <p>{JSON.stringify(error)}</p>;
  }

  return content;
};
export default CheckOutButton;
