import React from "react";

const PrivacyText = () => {
  return (
    <div className="font-sans text-gray-800 bg-gray-100 p-4 sm:p-8 md:p-12 lg:p-16">
      <div className="container mx-auto my-8 p-4">
        <h2 className="text-2xl font-bold mb-4">Datenschutz</h2>

        <p>
          Vielen Dank für dein Interesse an unserem Online-Shop. Um dir diesen
          Online-Shop anzubieten, sammeln wir Informationen über dich, die
          entweder direkt von dir, über dich oder über andere Kanäle zur
          Verfügung gestellt werden. Wir nehmen den Schutz deiner Privatsphäre
          sehr ernst. Nachfolgend informieren wir dich ausführlich über die
          Erhebung und Verwendung deiner personenbezogenen Daten.
        </p>

        <section className="my-8">
          <h3 className="text-lg font-bold mb-2">Einleitung</h3>
          <p>
            (1) Sollten personenbezogene Daten (z.B. Name, Anschrift,
            E-Mail-Adresse) erhoben werden, verpflichten wir uns dazu, Ihre
            vorherige Einverständnis einzuholen. Wir verpflichten uns dazu,
            keine Daten an Dritte weiterzugeben, es sei denn, Sie haben zuvor
            eingewilligt.
          </p>
          <p>
            (2) Wir weisen darauf hin, dass die Übertragung von Daten im
            Internet (z. B. per E-Mail) Sicherheitslücken aufweisen kann.
            Demnach kann ein fehlerfreier und störungsfreier Schutz der Daten
            Dritter nicht vollständig gewährleistet werden. Diesbezüglich ist
            unsere Haftung ausgeschlossen.
          </p>
          <p>
            (3) Dritte sind nicht dazu berechtigt, Kontaktdaten für gewerbliche
            Aktivitäten zu nutzen, sofern der Anbieter den betroffenen Personen
            vorher eine schriftliche Einwilligung erteilt hat.
          </p>
          <p>
            (4) Sie haben jederzeit das Recht, von SALE TO EUROPE über den Sie
            betreffenden Datenbestand vollständig und unentgeltlich Auskunft zu
            erhalten.
          </p>
          <p>
            (5) Des Weiteren besteht ein Recht auf Berichtigung/Löschung von
            Daten/Einschränkung der Verarbeitung für den Nutzer.
          </p>
          <p>
            (6) Um den Webshop zu betreiben und die von uns angebotenen
            Dienstleistungen zu erbringen, werden deine personenbezogenen Daten
            in die Türkei übermittelt.
          </p>
          <p>
            Darüber hinaus befinden sich einige unserer Dienstleister, die wir
            einsetzen, um dir unsere Dienste zur Verfügung zu stellen, in
            Ländern, die kein Datenschutzniveau bieten, das dem der Europäischen
            Union entspricht oder ähnlich ist.
          </p>
          <p>
            Wenn wir dies tun, ist dies entweder erforderlich, um dir die von
            dir angeforderten Leistungen mit deiner ausdrücklichen Einwilligung,
            oder anderen Übertragungsmechanismen zur Verfügung zu stellen, die
            nach dem Gesetz verfügbar sein können, wie z. B.
            Standardvertragsklausel.
          </p>
        </section>

        <section className="my-8">
          <h3 className="text-lg font-bold mb-2">
            Welche Daten sammeln wir von dir
          </h3>
          <p>
            Wenn du Sale to Europe nutzt oder mit uns auf eine andere Weise mit
            uns kommunizierst, erfassen wir Daten entweder direkt von dir oder
            über dich.
          </p>
          <p>
            Wir speichern daten von dir, wenn du bei uns ein Kundenkonto
            eröffnet hast. Diese Daten können Namen, E-Mail-Adresse,
            Telefonnummer, Geburtsdatum, Geschlechtsinformationen und
            Social-Media-Profilinformationen enthalten, wenn du dich auf diese
            Weise anmeldest.
          </p>
          <p>
            Wir sammeln deine Zahlungsinformationen, um deine Zahlung zu
            erleichtern. Dies kann die Art der verwendeten Zahlungsmethode,
            Zahlungsdetails wie Kreditkartennummer oder Bankkontonummer und
            Informationen zur Zahlungsüberprüfung umfassen.
          </p>
          <p>
            <strong>
              Daten, die direkt von dir oder von deinem Gerät stammen:
            </strong>
          </p>
          <p>
            Wenn du unsere Website besuchst oder unsere App verwendest, erfassen
            wir bestimmte Informationen über dein Gerät.
          </p>
          <p>
            Diese Informationen werden benötigt, damit du dich einloggen kannst
            und damit wir dir ein besseres, optimiertes Produkt anbieten können.
            Diese Informationen können dein Betriebssystem, deine IP-Adresse,
            Standortdaten, Werbung und Geräte-IDs enthalten sowie die Art des
            verwendeten Geräts und Browserinformationen.
          </p>
          <p>
            <strong>Cookie-Daten:</strong> Wenn du auf unserer Website oder in
            unseren Apps surfst und du deren Verwendung zustimmst, platzieren
            wir Cookies oder ähnliche Technologien auf deinem Gerät, um deine
            Nutzung unserer Website zu verstehen.
          </p>
          <p>
            Weitere Informationen über ihre Verwendung und welche Art von Daten
            sie sammeln, findest du in den Einwilligungsbanner.
          </p>
          <p>
            Wenn du bei Sale to Europe einkaufst, verwenden wir deine Daten:
          </p>
          <ul className="list-disc pl-8">
            <li>
              Damit du bei uns auf der Plattform einkaufen kannst und du dein
              Kundenkonto verwalten, aktualisieren oder löschen kannst.
            </li>
            <li>
              Damit wir deine bestellten Waren liefern und du Waren zurückgeben
              kannst.
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default PrivacyText;
