import React, { useState, useEffect } from 'react';
import {
  useCreateCatagoryMutation,
  useEditCatagoryMutation,
  useGetCatagoriesByTypeMutation,
  useGetCatagoryByIdMutation,
} from '../../features/auth/authApiSlice';
import { useSelector } from 'react-redux';
import { selectCurrentCatagoryIdTobeEdited } from '../../features/auth/authSlice';

const EditCatagoryForm = ({ catagory }) => {
  const [name, setName] = useState(null);
  const [type, setType] = useState(null);
  const [parentCategory, setParentCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const catagoryId = useSelector(selectCurrentCatagoryIdTobeEdited);

  const [editCatagory, { isLoading: createCatagoryIsLoading }] =
    useEditCatagoryMutation();

  const [getCatagoriesByType, { isLoading: getCatagoriesByTypeIsLoading }] =
    useGetCatagoriesByTypeMutation();
  const [getCatagoryById, { isLoading: getCatagoryByIdIsLoading }] =
    useGetCatagoryByIdMutation();
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const catagory = await getCatagoryById({
          catagoryId: catagoryId,
        }).unwrap();

        setName(catagory?.name);
        setType(catagory?.type);
        setParentCategory(catagory?.parent);

        const result = await getCatagoriesByType().unwrap();
        

        setCategories(result);

        setIsLoading(false);
        // Update state with the fetched data
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Invoke the fetchData function when the component mounts or when 'Id' changes
  }, []);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMsg, setSuccessMsg] = useState(null);
  const [ErrMsg, setErrMsg] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    // Make a request to your backend API to add the new category
    try {
      const response = await editCatagory({
        id: catagoryId,
        name,
        type,
        parent: parentCategory,
      }).unwrap();
      const result = await getCatagoriesByType().unwrap();
      

      setCategories(result);

      setSuccessMsg('Catagory Edited successfully');
    } catch (e) {
      setErrMsg('unable to edit Catagory');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div
        className={`  px-6 w-full py-4 bg-gray-100`}
        style={{ overflowY: 'auto', height: 'calc(100vh - 56px)' }}
      >
        {/* Add your list of items or content in this section */}
        <div className="w-full mb-10 bg-white h-16 flex items-center px-2 rounded-md  text-lg text-opacity-70">
          Edit Catagory
        </div>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <label className="block">CatagoryName:</label>

            <input
              className="w-full h-10  border-gray-400   border 
               focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded-md px-2 mb-4"
              id="validationDefault01"
              required
              type="text"
              value={name}
              placeholder="Catagory Name"
              onChange={(e) => setName(e.target.value)}
            />
            <label className="block">Type:</label>

            <select
              className="w-full h-10  border-gray-400   border 
               focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded-md px-2 mb-4"
              id="validationDefault01"
              required
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="mainCatagory">Main</option>
              <option value="subCatagory">Sub</option>
              <option value="childCatagory">Child</option>
            </select>
            {type !== 'mainCatagory' &&
              (type === 'subCatagory' ? (
                <label>
                  Parent Category:
                  <select
                    value={parentCategory}
                    onChange={(e) => setParentCategory(e.target.value)}
                    className="w-full h-10  border-gray-400   border 
                    focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded-md px-2 mb-4"
                  >
                    <option value="">Select Parent Category</option>
                    {categories
                      .filter((category) => category.type === 'mainCatagory')
                      .map((mainCategory) => (
                        <option key={mainCategory._id} value={mainCategory._id}>
                          {mainCategory.name}
                        </option>
                      ))}
                  </select>
                </label>
              ) : (
                <label>
                  Parent Category:
                  <select
                    value={parentCategory}
                    onChange={(e) => setParentCategory(e.target.value)}
                    className="w-full h-10  border-gray-400   border 
                    focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded-md px-2 mb-4"
                    style={{ backgroundColor: '#e2e8f0' }}
                  >
                    <option value="">Select Parent Category</option>
                    {categories
                      .filter((category) => category.type === 'subCatagory')
                      .map((mainCategory) => (
                        <option key={mainCategory._id} value={mainCategory._id}>
                          {mainCategory.name}
                        </option>
                      ))}
                  </select>
                </label>
              ))}
            <button
              className="bg-orange-600 hover:bg-orange-500 text-white px-2 py-1 rounded-md"
              type="submit"
            >
              {isSubmitting ? 'Editing...' : 'Edit Category'}
            </button>
            {successMsg && (
              <p className="bg-white text-green-600 p-2 rounded-md mt-3">
                {successMsg}
              </p>
            )}
            {ErrMsg && (
              <p className="bg-white text-green-600 p-2 rounded-md mt-3">
                {ErrMsg}
              </p>
            )}
          </form>
        )}
      </div>
    </>
  );
};

export default EditCatagoryForm;
