import { useTranslation } from "react-i18next";
import Navbar from "../../components/Navbar";
import { useAddBuyerMutation, useUpdateBuyerMutation } from "./authApiSlice";
import { useRef, useState } from "react";
import Footer from "../../components/Footer";
import Modal from "react-modal";
import PrivacyText from "../../legal/PrivacyText";

function SignUpBody() {
  const { t } = useTranslation();

  const userRef = useRef();
  const errRef = useRef();
  const verificationCodeRef = useRef(); // Add a new ref for the verification code input
  const verificationErrRef = useRef();

  const customStyles = {
    content: {
      maxWidth: "600px",
      backgroundColor: "#F3F4F6CC",
      margin: "auto", // Adjust the maximum width as needed
    },
  };

  const [emailForSignUp, setEmailSignUp] = useState("");
  const [passwordForsignUP, setPassWordSignUp] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [isEmailverified, setIsEmailVerified] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [passwordmatch, setPasswordMatch] = useState(false);

  const [errMsgForVerfication, setErrMsgForVerify] = useState("");
  const [isOnVerification, setIsOnVerification] = useState(false);

  const [addBuyer, { isBuyerLoading }] = useAddBuyerMutation();
  const [updateBuyer, { isBuyerUpdateLoading }] = useUpdateBuyerMutation();

  const [isAgreementChecked, setAgreementChecked] = useState(false);

  const handleAgreementCheck = () => {
    setAgreementChecked(!isAgreementChecked);
  };

  const handelVerifyCode = async (e) => {
    e.preventDefault();
    try {
      await updateBuyer({
        fullName,
        email: emailForSignUp,
        verifyCode: verificationCode,
      }).unwrap();

      setIsEmailVerified(true);
    } catch (err) {
      if (!err?.originalStatus) {
        // isLoading: true until timeout occurs
        setErrMsgForVerify("Connection problem or invalid verification code");
      } else if (err.originalStatus === 400) {
        setErrMsgForVerify("verification Code not Correct");
      } else if (err.originalStatus === 401) {
        setErrMsgForVerify("Unauthorized");
      } else {
        setErrMsgForVerify("Login Failed");
      }
      verificationErrRef.current.focus();
    }
  };

  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      if (passwordForsignUP !== confirmpassword) {
        setErrMsg("Password Must Match");
        setPasswordMatch(true);
        return;
      }
      const userData = await addBuyer({
        fullName,
        email: emailForSignUp,
        password: passwordForsignUP,
        userLanguage: selectedLanguage,
      }).unwrap();
      if (userData[1] === "Email already exists") {
        if (userData[2] === "pending") {
          setIsOnVerification(true);
          return;
        }
        setErrMsg("Email Already exists");
        return;
      }

      setIsOnVerification(true);
    } catch (err) {
      if (!err?.originalStatus) {
        // isLoading: true until timeout occurs
        setErrMsg("No Server Response");
      } else if (err.originalStatus === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.originalStatus === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
    }
  };

  const handleEmailSignUpchange = (e) => setEmailSignUp(e.target.value);
  const { i18n } = useTranslation();
  const selectedLanguage = i18n.language;

  // Now you can use the selectedLanguage variable in your component

  const handlefullNameChangeInput = (e) => {
    setFullName(e.target.value);
  };

  const handleVerifyCodeChangeInput = (e) =>
    setVerificationCode(e.target.value);

  const handelPwdSignUpChange = (e) => setPassWordSignUp(e.target.value);
  const handleConfirmPwdChange = (e) => setConfirmPassword(e.target.value);

  return (
    <>
      <Navbar></Navbar>
      <div className="justify-center gap-4 pt-12 h-screen flex px-[3%]">
        <div
          className={`${
            isOnVerification ? "hidden" : ""
          } w-full max-w-lg h-max bg-white border-dashed border-2 pt-7 flex-col px-3 rounded-lg justify-center items-center`}
        >
          <div className="text-2xl font-normal text-center mb-6">
            {t("signup")}
          </div>
          <form className="mb-12" onSubmit={handleSignup}>
            <label className="block mb-3">
              <span className="block text-sm font-medium text-slate-700">
                {t("fullname")}
              </span>
              <input
                ref={userRef}
                value={fullName}
                onChange={handlefullNameChangeInput}
                autoComplete="off"
                required
                type="text"
                placeholder={t("fullname")}
                className="mt block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
              />
            </label>

            <label className="block mb-3">
              <span className="block text-sm font-medium text-slate-700">
                {t("email")}
              </span>
              <input
                ref={userRef}
                value={emailForSignUp}
                onChange={handleEmailSignUpchange}
                autoComplete="off"
                required
                type="email"
                placeholder={t("email")}
                className="mt block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
              />
            </label>

            <label className="block">
              <span className="block text-sm font-medium text-slate-700">
                {t("password")}
              </span>
              <input
                type="password"
                id="password"
                onChange={handelPwdSignUpChange}
                value={passwordForsignUP}
                required
                placeholder={t("password")}
                className="mt block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
              />
            </label>

            <label className="block mt-4">
              <span className="block text-sm font-medium text-slate-700">
                {t("confirmPassword")}
              </span>
              <input
                type="password"
                id="Confirmpassword"
                onChange={handleConfirmPwdChange}
                value={confirmpassword}
                required
                placeholder={t("confirmPassword")}
                className={`mt block w-full px-3 py-2 bg-white border  ${
                  passwordmatch ? "border-red-500" : "border-slate-300"
                } rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 focus:invalid:border-pink-500 focus:invalid:ring-pink-500`}
              />
            </label>

            <label className="mt-4 flex">
              <input
                type="checkbox"
                checked={isAgreementChecked}
                onChange={() => {
                  handleAgreementCheck();
                  openModal();
                }}
                className="mt-1 mr-2 block self-start"
                required
              />
              <span className="text-sm font-medium text-slate-700">
                {t("agreeingtoPrivacyPolicy")}
              </span>
            </label>

            <span className="text-sm font-medium text-slate-700 cursor-pointer ml-5 hover:text-sky-500">
              <a href="/Privacy_Policy" className="text-purple-600">
                {t("viewPrivacyPolicy")}
              </a>
            </span>

            <Modal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              style={customStyles}
            >
              <PrivacyText />
              <button
                className="w-full bg-purple-600 py-2 justify-center text-white rounded-sm"
                onClick={closeModal}
              >
                okay
              </button>
            </Modal>

            <div className="text-center mt-8">
              <button
                className={` ${
                  isBuyerLoading ? "disabled:bg-orange-300" : ""
                } mt-4 text-white  text-center  w-28 py-1 px-3 bg-purple-700 hover:bg-purple-600 rounded-full`}
              >
                {isBuyerLoading ? "Processing..." : `${t("signup")}`}
              </button>
              <div className="mt-4">
                {t("alreadyhaveaccount")}?{" "}
                <span>
                  <a href="/login" className="">
                    {t("login")}
                  </a>
                </span>
              </div>
              <p
                ref={errRef}
                className={`${
                  errMsg ? "errmsg" : "offscreen"
                } my-4 text-red-600`}
                aria-live="assertive"
              >
                {errMsg}
              </p>
            </div>
          </form>
        </div>

        <div
          className={`${
            isOnVerification ? "" : "hidden"
          } w-full md:w-4/12  bg-white pt-7 flex-col px-3 rounded-lg justify-center items-center`}
        >
          <div className="text-black">
            {t("verificationemailmessage")}{" "}
            <span className="text-gray-700">{emailForSignUp}</span>{" "}
            {t("verificationcode")}
          </div>
          <form className="mb-12" onSubmit={handelVerifyCode}>
            <label className="block mb-3">
              <span className="block text-sm font-medium text-slate-700">
                Verification Code
              </span>
              <input
                ref={verificationCodeRef}
                value={verificationCode}
                onChange={handleVerifyCodeChangeInput}
                autoComplete="off"
                required
                type="text"
                placeholder="Code"
                className="mt block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
              />
            </label>
            <button
              className={`${
                isBuyerUpdateLoading ? "disabled:bg-orange-300" : ""
              } bg-purple-700 hover:bg-purple-600 text-white rounded-md px-2 py-1 `}
            >
              {isBuyerUpdateLoading ? t("verifying") : "Verify"}
            </button>
          </form>
          {isEmailverified && (
            <div className="text-green-500">
              {t("verifiedmessage")}{" "}
              <span>
                <a href="/login" className="text-black hover:underline">
                  {t("login")}
                </a>
              </span>
            </div>
          )}
          <p
            ref={verificationErrRef}
            className={`${
              errMsgForVerfication ? "errmsg" : "offscreen"
            } my-4 text-red-600`}
            aria-live="assertive"
          >
            {errMsgForVerfication}
          </p>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default SignUpBody;
