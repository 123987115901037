// components/AddShippingProvider.js
import React, { useState, useEffect } from 'react';
import {
  useAddShippingProviderMutation,
  useCreateCatagoryMutation,
  useDeleteShippingProviderMutation,
  useGetCatagoriesByTypeMutation,
  useGetShippingProvidersMutation,
} from '../../features/auth/authApiSlice';
import { useTranslation } from 'react-i18next';

const AddShippingProvider = () => {
  const [idtobeDeleted, setIdTobedeleted] = useState(false);
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [shippingProviders, setShippingProviders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addShippingProvider, { isLoading: createCShippingIsLoading }] =
    useAddShippingProviderMutation();

  const [deleteShippingProvider, { isLoading: deleteShippingIsLoading }] =
    useDeleteShippingProviderMutation();

  const [getShippingProviders, { isLoading: getShippingProvidersLoading }] =
    useGetShippingProvidersMutation();
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const result = await getShippingProviders().unwrap();

        setShippingProviders(result);

        setIsLoading(false);
        // Update state with the fetched data
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Invoke the fetchData function when the component mounts or when 'Id' changes
  }, []);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDelting] = useState(false);
  const [successMsg, setSuccessMsg] = useState(null);
  const [ErrMsg, setErrMsg] = useState(null);

  const handleAddAnother = async (e) => {
    e.preventDefault();

    setName('');
    setEmail('');

    setSuccessMsg(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    // Make a request to your backend API to add the new category
    try {
      const response = await addShippingProvider({
        name,
        email,
      });
      const result = await getShippingProviders().unwrap();
      
      setShippingProviders(result);

      setSuccessMsg('Shipping Provider Added successfully');
    } catch (e) {
      setErrMsg('unable to add Provider');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (e, id) => {
    e.preventDefault();
    setIsDelting(true);
    // Make a request to your backend API to add the new category
    try {
      const response = await deleteShippingProvider({
        itemId: id,
      });
      const result = await getShippingProviders().unwrap();
    
      setShippingProviders(result);
    } catch (e) {
    } finally {
      setIsDelting(false);
    }
  };
  return (
    <>
      <div
        className={`  px-6 w-full py-4 bg-gray-100`}
        style={{ overflowY: 'auto', height: 'calc(100vh - 56px)' }}
      >
        {/* Add your list of items or content in this section */}
        <div className="w-full mb-10 bg-white h-16 flex items-center px-2 rounded-md  text-lg text-opacity-70">
          {t('AddShippingProviders')}
        </div>

        {isLoading ? (
          <p>{t('loading')}...</p>
        ) : (
          <div className="flex">
            <form onSubmit={handleSubmit} className="w-1/2">
              <label className="block">{t('Shipping Company Name')}:</label>

              <input
                className="w-[300px] h-10  border-gray-400   border 
               focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded-md px-2 mb-4"
                id="validationDefault01"
                required
                type="text"
                value={name}
                placeholder="Name"
                onChange={(e) => setName(e.target.value)}
              />
              <label className="block">{t('Email')}:</label>

              <input
                className="w-[300px] h-10 block border-gray-400   border 
   focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded-md px-2 mb-4"
                id="validationDefault01"
                required
                type="text"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                className="bg-orange-600 hover:bg-orange-500 text-white px-2 py-1 rounded-md"
                type="submit"
              >
                {isSubmitting ? t('adding') : t('addCategory')}
              </button>
              {successMsg && (
                <div className="flex gap-4  items-center">
                  <div className="bg-white text-green-600 p-2 rounded-md mt-3">
                    {t('catagorysuccessMsg')}
                  </div>
                  <div>
                    <button
                      onClick={handleAddAnother}
                      className="bg-orange-600 block mt-3 h-8 hover:bg-orange-500 px-2 py-1 text-white rounded-md"
                    >
                      {t('addAnother')}
                    </button>
                  </div>
                </div>
              )}
              {ErrMsg && (
                <p bg-white text-red-600 p-2 rounded-md mt-3>
                  {t('catagoryerrMsg')}
                </p>
              )}
            </form>
            <div className="flex-col">
              <div>Added Shipping Providers</div>
              {shippingProviders.map((provider, index) => (
                <div className=" flex my-2" key={index}>
                  <div>
                    {index + 1}.{provider.name}
                  </div>
                  <button
                    onClick={(e) => {
                      setIdTobedeleted(provider._id);
                      handleDelete(e, provider._id);
                    }}
                    className="bg-red-700 hover:bg-red-600 text-white px-2 py-[1px] ml-auto"
                  >
                    {isDeleting && idtobeDeleted === provider._id
                      ? t('deleting')
                      : t('delete')}
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AddShippingProvider;
