import { useContext, useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import CustomSlider from "../../components/slider";
import NewItems from "../../components/sliderNeighbour";
import Footer from "../../components/Footer";
import TrendingProds from "../../components/TrendingProds";
import Advert from "../../components/advert";
import { WelcomeLoader } from "../../contexts/LoaderContext";
import LoadingPlaceholder from "../../components/LoadingPlaceholder";
import PopularCategories from "../../components/PopularCategories";
import "../../styles/welcome.css";




const Welcome = () => {
  const {isWelcomeloading} = useContext(WelcomeLoader);

  

  const content = (
    <div>
      <section className="welcome">
        <Navbar/>
        
        {isWelcomeloading && (<LoadingPlaceholder />)}
        
        <div style={{ padding: "0 4%" }}>
          <div className="mt-5 md:mt-0">
            <div className="grid md:grid-cols-2 grid-flow-row-dense gap-4">
              <CustomSlider/>
              <NewItems/>
            </div>
            <TrendingProds/>
            <Advert/>
            <PopularCategories />
          </div>
        </div>
        <Footer/>
      </section>
    </div>
  );

  return content;
};
export default Welcome;
