import { useState } from 'react';
import { useChangePasswordMutation } from '../../features/auth/authApiSlice';
import { useSelector } from 'react-redux';
import {
  selectCurrenRole,
  selectCurrentEmail,
  selectCurrentUserId,
} from '../../features/auth/authSlice';
import { useTranslation } from 'react-i18next';

function ResetPasswordBuyer() {
  const { t } = useTranslation();
  const [newPassword, setNewPassword] = useState('');
  const [confirmnewPassword, setConfirmNewPassword] = useState('');
  const currentRole = useSelector(selectCurrenRole);
  const currentUserId = useSelector(selectCurrentUserId);
  const currentEmail = useSelector(selectCurrentEmail);
  const [changePassWord, { isChangePasswordLoading }] =
    useChangePasswordMutation();
  const [confirmPwdMsg, setConfirmPswMsg] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [successMsg, setSuccessMsg] = useState(null);
  const handleResetPassword = async (e) => {
    e.preventDefault();
    setConfirmPswMsg(null);
    if (newPassword !== confirmnewPassword) {
      setConfirmPswMsg(t('pwdMustMatch'));
      return;
    }
    setIsProcessing(true);

    const userData = await changePassWord({
      id: currentUserId,
      email: currentEmail,
      role: currentRole,

      password: newPassword,
    });

    if (userData.data.email === currentEmail) {
      setSuccessMsg(t('changePwdSucces'));
    } else {
      setSuccessMsg(t('errorOccured'));
    }
    setIsProcessing(false);

    // Send a request to your NestJS backend to update the password
    // Use newPassword and resetToken in the request body
  };
  return (
    <>
      <div className="flex-col justify-center items-center  pl-5 ">
        <label className="block">{t('newPassword')}:</label>
        <input
          type="password"
          value={newPassword}
          className="mt block w-11/12 px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <label className="block">{t('confirmPassword')}:</label>
        <input
          type="password"
          className="mt block w-11/12 px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
          value={confirmnewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
        />
        <button
          onClick={handleResetPassword}
          className="block bg-purple-600 hover:bg-purple-500 text-white rounded-md px-2 py-1 mt-3"
        >
          {isProcessing ? 'Processing...' : t('resetPassword')}
        </button>
        {confirmPwdMsg && <p>{confirmPwdMsg}</p>}
        {successMsg && <p>{successMsg}</p>}
      </div>
    </>
  );
}

export default ResetPasswordBuyer;
