import { useState } from 'react';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaUserAlt } from 'react-icons/fa';
import Cookies from 'js-cookie';

import {
  FaHome,
  FaListAlt,
  FaChevronUp,
  FaChevronDown,
  FaShoppingCart,
  FaMoneyBillWave,
} from 'react-icons/fa';
import { SiProducthunt } from 'react-icons/si';
import { LiaFirstOrder } from 'react-icons/lia';
import { RiCouponFill, RiLuggageDepositFill } from 'react-icons/ri';
import '../../styles/sellerDashboard.css';
import Dropdown from '../Seller/dropdown';
import SalesChart from '../Seller/SalesChart';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentBlockedBuyers,
  selectCurrentBlockedSellers,
  selectCurrentCanceledOrders,
  selectCurrentDeliveredOrders,
  selectCurrentEmail,
  selectCurrentName,
  selectCurrentOntheWayOrders,
  selectCurrentPendingOrders,
  selectCurrentPendingSellers,
  selectCurrentPreparingOrders,
  selectCurrentSellerAdminData,
  selectCurrentTotalNumberOfItems,
  selectCurrentTotalNumberOrReviews,
  selectCurrentUserImgUrl,
  selectCurrentVerifiedSellers,
  selectCurrentverifiedBuyers,
  setAllUserStatus,
  setBuyer,
  setCredentials,
  setSelectedItem,
  setSellerAdminData,
  setUser,
} from '../../features/auth/authSlice';
// import AddProduct from './AddProduct';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import UserStatusDetailsPage from './userStatusDetailsPage';
import NotifyNewSeller from './adminSocket';
import AddCategoryForm from './CatagoryPage';
import CatagoriesList from './CatagoriesList';
import EditCatagoryForm from './editCatagory';
import AllProduct from './AllProductsbyAll';
import OrderDetailsPage from './orderDetailsPage';
import { useTranslation } from 'react-i18next';
import DiscountedItems from './dicountedItems';
import LanguageSwitcher from '../languageSwitcher';
import ShippingRule from './addShippingRule';
import AddShippingProvider from './addShippingProvider';
// import NotifyOrder from './Sockeio.realtime';
// import OrderDetailsPage from './OrderDetailsPage';
// import CreateCoupon from './CreateCoupon';
// import MyCouponsPage from './MyCoupons';
// import EditCouponPage from './EditCouponPage';
// import SellerEditProfile from './EditProfile';
// import AllProduct from './AllProducts';
// import EditProduct from './EditProduct';
// import StockOutProducts from './StockOutProducts';
function AdminNavBar() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();
  const adminData = useSelector(selectCurrentSellerAdminData);

  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [selectedSidebarItem, setSelectedSidebarItem] = useState('Dashboard');

  const monthlySalesData = [
    100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650,
  ];

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handelLogout = () => {
    dispatch(
      setCredentials({
        user: null,
        accessToken: null,
        refreshtoken: null,
      }),
    );
    dispatch(
      setUser({
        id: null,
        email: null,
        name: null,
        role: null,
        accountStatus: null,
        cart: null,
      }),
    );
    dispatch(
      setBuyer({
        country: null,
        region: null,
        city: null,
        streetAddress: null,
        phoneNumber: null,
        postalCode: null,
        homeNumber: null,
        additionalStreetAddress: null,
        zipCode: null,
      }),
    );

    const sellerData = {
      dailysales: null,
      weeklysales: null,
      monthlysales: null,
      yearlysales: null,
      monthlySaleList: null,
      totalSellerItemlength: null,
    };
    dispatch(setSellerAdminData({ data: sellerData }));
    dispatch(setAllUserStatus());

    Cookies.remove('accessToken');
    Cookies.remove('refreshToken');

    navigate('/');
  };

  const categoriesDropdownProps = {
    title: {
      text: t('categories'),
      icon: (
        <FaListAlt className="opacity-70 ml-4" style={{ fontSize: '24px' }} />
      ),
      openIcon: (
        <FaChevronUp className="opacity-70 mr-2" style={{ fontSize: '14px' }} />
      ),
      closeIcon: (
        <FaChevronDown
          className="opacity-70 mr-2"
          style={{ fontSize: '14px' }}
        />
      ),
    },
    items: [
      t('addCategories'),
      t('AddProductmainCategory'),
      t('AddProductsubCategory'),
      t('AddProductchildCategory'),
    ],
    selectedSidebarItem: selectedSidebarItem,
    onSelectSidebarItem: setSelectedSidebarItem,
  };

  const productsDropdownProps = {
    title: {
      text: t('products'),
      icon: (
        <SiProducthunt
          className="opacity-70 ml-4"
          style={{ fontSize: '24px' }}
        />
      ),
      openIcon: (
        <FaChevronUp className="opacity-70 mr-2" style={{ fontSize: '14px' }} />
      ),
      closeIcon: (
        <FaChevronDown
          className="opacity-70 mr-2"
          style={{ fontSize: '14px' }}
        />
      ),
    },
    items: [t('allProducts')],
    selectedSidebarItem,
    onSelectSidebarItem: setSelectedSidebarItem,
  };

  const discountsDropdownProps = {
    title: {
      text: 'Discounts',
      icon: (
        <SiProducthunt
          className="opacity-70 ml-4"
          style={{ fontSize: '24px' }}
        />
      ),
      openIcon: (
        <FaChevronUp className="opacity-70 mr-2" style={{ fontSize: '14px' }} />
      ),
      closeIcon: (
        <FaChevronDown
          className="opacity-70 mr-2"
          style={{ fontSize: '14px' }}
        />
      ),
    },
    items: ['Discounted Items'],
    selectedSidebarItem,
    onSelectSidebarItem: setSelectedSidebarItem,
  };
  const shippingRuleDropdownProps = {
    title: {
      text: 'Shipping',
      icon: (
        <SiProducthunt
          className="opacity-70 ml-4"
          style={{ fontSize: '24px' }}
        />
      ),
      openIcon: (
        <FaChevronUp className="opacity-70 mr-2" style={{ fontSize: '14px' }} />
      ),
      closeIcon: (
        <FaChevronDown
          className="opacity-70 mr-2"
          style={{ fontSize: '14px' }}
        />
      ),
    },
    items: ['Shipping Rule', 'Add Shipping Company'],
    selectedSidebarItem,
    onSelectSidebarItem: setSelectedSidebarItem,
  };

  const ordersDropdownProps = {
    title: {
      text: t('orders'),
      icon: (
        <LiaFirstOrder
          className="opacity-70 ml-4"
          style={{ fontSize: '24px' }}
        />
      ),
      openIcon: (
        <FaChevronUp className="opacity-70 mr-2" style={{ fontSize: '14px' }} />
      ),
      closeIcon: (
        <FaChevronDown
          className="opacity-70 mr-2"
          style={{ fontSize: '14px' }}
        />
      ),
    },
    items: [
      t('pendingorders'),
      t('preparingorders'),
      t('ontheway'),
      t('deliveredorders'),
      t('cancelledorders'),
    ],
    selectedSidebarItem,
    onSelectSidebarItem: setSelectedSidebarItem,
  };
  const CouponsDropdownProps = {
    title: {
      text: 'Coupons',
      icon: (
        <RiCouponFill
          className="opacity-70 ml-4"
          style={{ fontSize: '24px' }}
        />
      ),
      openIcon: (
        <FaChevronUp className="opacity-70 mr-2" style={{ fontSize: '14px' }} />
      ),
      closeIcon: (
        <FaChevronDown
          className="opacity-70 mr-2"
          style={{ fontSize: '14px' }}
        />
      ),
    },
    items: ['Create Coupon', 'My Coupons'],
    selectedSidebarItem,
    onSelectSidebarItem: setSelectedSidebarItem,
  };
  const salesDropdownProps = {
    title: {
      text: 'Sales',
      icon: (
        <RiLuggageDepositFill
          className="opacity-70 ml-4"
          style={{ fontSize: '24px' }}
        />
      ),
      openIcon: (
        <FaChevronUp className="opacity-70 mr-2" style={{ fontSize: '14px' }} />
      ),
      closeIcon: (
        <FaChevronDown
          className="opacity-70 mr-2"
          style={{ fontSize: '14px' }}
        />
      ),
    },
    items: [
      'Total Sales',
      'Todays Sales',
      'This Month Sales',
      'This Year Sales',
    ],
    selectedSidebarItem,
    onSelectSidebarItem: setSelectedSidebarItem,
  };

  const LogoutDropdownProps = {
    title: {
      text: t('logout'),
      icon: (
        <RiCouponFill
          className="opacity-70 ml-4"
          style={{ fontSize: '24px' }}
        />
      ),
      openIcon: (
        <FaChevronUp className="opacity-70 mr-2" style={{ fontSize: '14px' }} />
      ),
      closeIcon: (
        <FaChevronDown
          className="opacity-70 mr-2"
          style={{ fontSize: '14px' }}
        />
      ),
    },
    items: [t('logout')],
    selectedSidebarItem,
    onSelectSidebarItem: setSelectedSidebarItem,
  };

  const pendingSellers = useSelector(selectCurrentPendingSellers);
  const verifiedSellers = useSelector(selectCurrentVerifiedSellers);
  const blockedSellers = useSelector(selectCurrentBlockedSellers);
  const verifiedBuyers = useSelector(selectCurrentverifiedBuyers);
  const blockedBuyers = useSelector(selectCurrentBlockedBuyers);
  const sellerName = useSelector(selectCurrentName);
  const sellerImg = useSelector(selectCurrentUserImgUrl);
  const totalReviews = useSelector(selectCurrentTotalNumberOrReviews);
  const totalNumberOfItems = useSelector(selectCurrentTotalNumberOfItems);

  const renderContent = () => {
    switch (selectedSidebarItem) {
      case 'Dashboard':
        return (
          <div
            className={`  px-6 py-4 bg-gray-100`}
            style={{ overflowY: 'auto', height: 'calc(100vh - 56px)' }}
          >
            {/* Add your list of items or content in this section */}
            <div className="w-full bg-white h-16 flex items-center px-2 rounded-md  text-lg text-opacity-70">
              {t('dashboard')}
            </div>

            <div className=" flex flex-wrap mt-4">
              <div
                onClick={() => {
                  setSelectedSidebarItem('Pending Sellers');
                  dispatch(
                    setSelectedItem({ selectedItem: 'Pending Sellers' }),
                  );
                }}
                className=" flex gap-3 mb-4 h-28 w-56 mr-3 rounded-md bg-white justify-center items-center"
              >
                <div className=" h-16 w-16 bg-orange-500 rounded-md flex justify-center items-center">
                  <FaUserAlt style={{ fontSize: '30px', color: '#fff' }} />
                </div>
                <div>
                  <p className="pt-2 w-32">{t('pendingSellers')}</p>
                  <p>{pendingSellers?.length}</p>
                </div>
              </div>
              <div
                onClick={() => {
                  setSelectedSidebarItem('Verified Sellers');
                  dispatch(
                    setSelectedItem({ selectedItem: 'Verified Sellers' }),
                  );
                }}
                className=" flex gap-3 mb-4 h-28 w-56 mr-3 rounded-md bg-white justify-center items-center"
              >
                <div className=" h-16 w-16 bg-orange-500 rounded-md flex justify-center items-center">
                  <FaUserAlt style={{ fontSize: '30px', color: '#fff' }} />
                </div>
                <div>
                  <p className="pt-2 w-32">{t('verifiedSellers')}</p>
                  <p>{verifiedSellers}</p>
                </div>
              </div>
              <div
                onClick={() => {
                  setSelectedSidebarItem('Blocked Sellers');
                  dispatch(
                    setSelectedItem({ selectedItem: 'Blocked Sellers' }),
                  );
                }}
                className=" flex gap-3 mb-4 h-28 w-56 mr-3 rounded-md bg-white justify-center items-center"
              >
                <div className=" h-16 w-16 bg-orange-500 rounded-md flex justify-center items-center">
                  <FaUserAlt style={{ fontSize: '30px', color: '#fff' }} />
                </div>
                <div>
                  <p className="pt-2 w-32">{t('blockedSellers')}</p>
                  <p>{blockedSellers?.length}</p>
                </div>
              </div>
              <div
                onClick={() => {
                  setSelectedSidebarItem('Blocked Buyers');
                  dispatch(setSelectedItem({ selectedItem: 'Blocked Buyers' }));
                }}
                className=" flex gap-3 mb-4 h-28 w-56 mr-3 rounded-md bg-white justify-center items-center"
              >
                <div className=" h-16 w-16 bg-orange-500 rounded-md flex justify-center items-center">
                  <FaUserAlt style={{ fontSize: '30px', color: '#fff' }} />
                </div>
                <div>
                  <p className="pt-2 w-32">{t('blockedBuyers')}</p>
                  <p>{blockedBuyers?.length}</p>
                </div>
              </div>
              <div
                onClick={() => {
                  setSelectedSidebarItem('Verified Buyers');
                  dispatch(
                    setSelectedItem({ selectedItem: 'Verified Buyers' }),
                  );
                }}
                className=" flex gap-3 mb-4 h-28 w-56 mr-3 rounded-md bg-white justify-center items-center"
              >
                <div className=" h-16 w-16 bg-orange-500 rounded-md flex justify-center items-center">
                  <FaUserAlt style={{ fontSize: '30px', color: '#fff' }} />
                </div>
                <div>
                  <p className="pt-2 w-32">{t('verifiedBuyers')}</p>
                  <p>{verifiedBuyers}</p>
                </div>
              </div>
              <div className=" flex gap-3 mb-4 h-28 w-56 mr-3 rounded-md bg-white justify-center items-center">
                <div className=" h-16 w-16 bg-blue-500 rounded-md flex justify-center items-center">
                  <FaMoneyBillWave
                    style={{ fontSize: '30px', color: '#fff' }}
                  />
                </div>
                <div>
                  <p className="pt-2 w-32">{t('todaySales')}</p>
                  <p>{adminData.dailysales}$</p>
                </div>
              </div>
              <div className=" flex gap-3 mb-4 h-28 w-56 mr-3 rounded-md bg-white justify-center items-center">
                <div className=" h-16 w-16 bg-blue-500 rounded-md flex justify-center items-center">
                  <FaMoneyBillWave
                    style={{ fontSize: '30px', color: '#fff' }}
                  />
                </div>
                <div>
                  <p className="pt-2">{'thisWeeksSales'}</p>
                  <p>{adminData.weeklysales}$</p>
                </div>
              </div>
              <div className=" flex gap-3 mb-4 h-28 w-56 mr-3 rounded-md bg-white justify-center items-center">
                <div className=" h-16 w-16 bg-blue-500 rounded-md flex justify-center items-center">
                  <FaMoneyBillWave
                    style={{ fontSize: '30px', color: '#fff' }}
                  />
                </div>
                <div>
                  <p className="pt-2 w-32">{t('thisMonthSales')}</p>
                  <p>{adminData.monthlysales}$</p>
                </div>
              </div>
              <div className=" flex gap-3 mb-4 h-28 w-56 mr-3 rounded-md bg-white justify-center items-center">
                <div className=" h-16 w-16 bg-blue-500 rounded-md flex justify-center items-center">
                  <FaMoneyBillWave
                    style={{ fontSize: '30px', color: '#fff' }}
                  />
                </div>
                <div>
                  <p className="pt-2 w-32">{t('thisYearSales')}</p>
                  <p>{adminData.yearlysales}$</p>
                </div>
              </div>
              <div className=" flex gap-3 mb-4 h-28 w-56 mr-3 rounded-md bg-white justify-center items-center">
                <div className=" h-16 w-16 bg-red-500 rounded-md flex justify-center items-center">
                  <SiProducthunt style={{ fontSize: '30px', color: '#fff' }} />
                </div>
                <div>
                  <p className="pt-2 w-32">{t('totalProducts')}</p>
                  <p>{totalNumberOfItems}</p>
                </div>
              </div>{' '}
              <div className=" flex gap-3 mb-4 h-28 w-56 mr-3 rounded-md bg-white justify-center items-center">
                <div className=" h-16 w-16 bg-red-500 rounded-md flex justify-center items-center">
                  <SiProducthunt style={{ fontSize: '30px', color: '#fff' }} />
                </div>
                <div>
                  <p className="pt-2 w-32">{t('totalReviews')}</p>
                  <p>{totalReviews}</p>
                </div>
              </div>{' '}
            </div>
            <div>
              <h2>{t('monthlySalesProgress')}</h2>

              <div
                className="border-2 flex p-8 rounded-lg "
                style={{ width: '670px' }}
              >
                {' '}
                <div className="flex-col">
                  <SalesChart data={adminData.monthlySaleList} />
                  <div className="flex">
                    <p className="pt-1 ml-2">Jan</p>
                    <p className="pt-1 ml-6">Feb</p>
                    <p className="pt-1 ml-6">Mar</p>
                    <p className="pt-1 ml-6">Apr</p>
                    <p className="pt-1 ml-6">May</p>
                    <p className="pt-1 ml-6">Jun</p>
                    <p className="pt-1 ml-6">Jul</p>
                    <p className="pt-1 ml-6">Aug</p>
                    <p className="pt-1 ml-6">Sep</p>
                    <p className="pt-1 ml-6">Oct</p>
                    <p className="pt-1 ml-6">Nov</p>
                    <p className="pt-1 ml-6">Dec</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case t('addCategories'):
        return <AddCategoryForm />;
      case 'Add Shipping Company':
        return <AddShippingProvider />;
      case t('logout'):
        return (
          <div className="w-full bg-gray-100 flex-col">
            <div className=" w-96 h-32 rounded-md">
              <p className="pl-2 py-4 text-lg text-gray-700 text-center">
                {t('logout_confirmation')}
              </p>
              <div className="flex justify-center">
                <button
                  onClick={handelLogout}
                  className="w-24 px-2 text-center py-1 rounded-md hover:bg-red-500 bg-red-600 text-white"
                >
                  {t('logout')}
                </button>
              </div>
            </div>
          </div>
        );
      case 'Discounted Items':
        return <DiscountedItems></DiscountedItems>;
      case 'Shipping Rule':
        return <ShippingRule></ShippingRule>;
      case 'Add product':
      // return <AddProduct />;
      case 'Create Coupon':
      // return <CreateCoupon />;
      case 'Edit Coupon':
      // return <EditCouponPage />;
      case 'Edit Profile':
      // return <SellerEditProfile />
      case t('allProducts'):
        return (
          <AllProduct
            selectedSidebarItem={selectedSidebarItem}
            onSelectSidebarItem={setSelectedSidebarItem}
          />
        );
      // 'Stock Out products':
      //   return <StockOutProducts selectedSidebarItem={selectedSidebarItem}
      //   onSelectSidebarItem={setSelectedSidebarItem} />
      case t('AddProductmainCategory'):
        return (
          <CatagoriesList
            catagoryType="Main Catagories"
            selectedSidebarItem={selectedSidebarItem}
            onSelectSidebarItem={setSelectedSidebarItem}
          />
        );
      case t('AddProductsubCategory'):
        return (
          <CatagoriesList
            catagoryType="Sub Catagories"
            selectedSidebarItem={selectedSidebarItem}
            onSelectSidebarItem={setSelectedSidebarItem}
          />
        );
      case t('AddProductchildCategory'):
        return (
          <CatagoriesList
            catagoryType="Child Catagories"
            selectedSidebarItem={selectedSidebarItem}
            onSelectSidebarItem={setSelectedSidebarItem}
          />
        );
      case 'Edit Catagory':
        return <EditCatagoryForm />;
      case 'My Coupons':
      //     return <MyCouponsPage selectedSidebarItem={selectedSidebarItem}
      //     onSelectSidebarItem={setSelectedSidebarItem}/>;
      //   // Add cases for other sidebar items as needed
      case 'Pending Sellers':
        return (
          <UserStatusDetailsPage userStatus="Pending Seller"></UserStatusDetailsPage>
        );
      case 'Verified Sellers':
        return (
          <UserStatusDetailsPage userStatus="Verified Seller"></UserStatusDetailsPage>
        );
      case 'Blocked Sellers':
        return (
          <UserStatusDetailsPage userStatus="Blocked Seller"></UserStatusDetailsPage>
        );
      case 'Verified Buyers':
        return (
          <UserStatusDetailsPage userStatus="Verified Buyer"></UserStatusDetailsPage>
        );
      case 'Blocked Buyers':
        return (
          <UserStatusDetailsPage userStatus="Blocked Buyer"></UserStatusDetailsPage>
        );
      case t('pendingorders'):
        return <OrderDetailsPage orderStatus="pending"></OrderDetailsPage>;
      case t('preparingorders'):
        return <OrderDetailsPage orderStatus="preparing"></OrderDetailsPage>;

      case t('ontheway'):
        return <OrderDetailsPage orderStatus="ontheway"></OrderDetailsPage>;
      case t('deliveredorders'):
        return <OrderDetailsPage orderStatus="delivered"></OrderDetailsPage>;
      case t('cancelledorders'):
        return <OrderDetailsPage orderStatus="cancelled"></OrderDetailsPage>;

      default:
        return null; // Render nothing by default
    }
  };

  const dispatch = useDispatch();

  return (
    <>
      <div
        className="flex w-full bg-white h-12 pt-3 shadow-md"
        style={{ borderBottom: '1px solid #ccc', width: '100%' }}
      >
        <h5
          style={{ cursor: 'pointer' }}
          onClick={() => navigate('/')}
          className="ml-12 text-violet-800 text-[24px] font-bold font-['Poppins'] leading-7 tracking-tight"
        >
          Saletoeurope
        </h5>
        <div className="ml-20">
          <button
            onClick={toggleSidebar}
            className="block text-black-600 focus:outline-none mr-4"
          >
            <FontAwesomeIcon icon={faBars} style={{ fontSize: '24px' }} />
          </button>
        </div>
        <div className="ml-auto mr-8 z-40">
          <LanguageSwitcher></LanguageSwitcher>
          <NotifyNewSeller></NotifyNewSeller>
        </div>
      </div>
      <div className="w-full  fixed top-12 bottom-0">
        <div className="flex">
          {isSidebarOpen && (
            <div
              className={`h-screen  bg-white w-64 ${
                isSidebarOpen ? 'show' : ''
              } shadow-md  sidebar-slide-in`}
              style={{
                flexShrink: 0,
              }}
            >
              <div
                className="flex py-3 pl-4"
                style={{ borderBottom: '1px solid #ccc', width: '100%' }}
              >
                <img
                  src={sellerImg}
                  className={`h-14 w-14 rounded-full ${
                    !sellerImg ? 'bg-blue-500' : '' // Apply blue background if buyerImg is null
                  }`}
                />
                <div className="pl-2 pt-2">
                  <p className="text-black text-opacity-70 text-sm">
                    {sellerName}
                  </p>
                  <p className="text-sm pt-1">{t('administrator')}</p>
                </div>
              </div>

              <div className="pt-4 ">
                <div
                  className={`${
                    selectedSidebarItem == 'Dashboard' ? 'bg-gray-200' : ''
                  } flex gap-4 items-center hover:bg-gray-100 w-full py-2 cursor-pointer`}
                  onClick={() => {
                    setSelectedSidebarItem('Dashboard');
                    dispatch(setSelectedItem({ selectedItem: 'Dashboard' }));
                  }}
                >
                  <FaHome
                    className="opacity-70 ml-4"
                    style={{ fontSize: '24px' }}
                  />
                  <p className="text-opacity-75" style={{ fontSize: '16px' }}>
                    {t('dashboard')}
                  </p>
                </div>

                {/* Categories Dropdown */}

                <Dropdown {...categoriesDropdownProps} />
                <Dropdown {...productsDropdownProps} />
                <Dropdown {...ordersDropdownProps} />
                <Dropdown {...discountsDropdownProps} />
                <Dropdown {...shippingRuleDropdownProps} />
                <Dropdown {...LogoutDropdownProps} />
              </div>
            </div>
          )}
          {renderContent()}
        </div>
      </div>
    </>
  );
}

export default AdminNavBar;
