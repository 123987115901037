import React from "react";

function LoadingPlaceholder() {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="animate-pulse bg-gray-300 w-full h-full rounded-md"></div>
    </div>
  );
}

export default LoadingPlaceholder;
