import { useTranslation } from 'react-i18next';
import Navbar from '../../components/Navbar';
import { useAddSellerMutation, useUpdateSellerMutation } from './authApiSlice';
import { useRef, useState } from 'react';
import Footer from '../../components/Footer';
import Modal from 'react-modal';

function SellerSignUpBody() {
  const { t } = useTranslation();

  const [isModalOpen, setModalOpen] = useState(false);

  // Function to open the modal
  const openModal = () => {
    setModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setModalOpen(false);
  };

  const [isAgreementChecked, setAgreementChecked] = useState(false);

  const handleAgreementCheck = () => {
    setAgreementChecked(!isAgreementChecked);
  };

  const customStyles = {
    content: {
      maxWidth: '600px',
      backgroundColor: '#F3F4F6CC',
      margin: 'auto', // Adjust the maximum width as needed
    },
  };

  const userRef = useRef();
  const errRef = useRef();
  const verificationCodeRef = useRef(); // Add a new ref for the verification code input
  const verificationErrRef = useRef();
  const [confirmpassword, setConfirmPassword] = useState('');
  const [passwordmatch, setPasswordMatch] = useState(false);

  const handleConfirmPwdChange = (e) => setConfirmPassword(e.target.value);

  const [emailForSignUp, setEmailSignUp] = useState('');
  const [passwordForsignUP, setPassWordSignUp] = useState('');
  const [fullName, setFullName] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isEmailverified, setIsEmailVerified] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [errMsgForVerfication, setErrMsgForVerify] = useState('');
  const [isOnVerification, setIsOnVerification] = useState(false);

  const [isSigningUp, setIsSigningUP] = useState(false);
  const [IsVerifying, setIsVerfying] = useState(false);

  const [addSeller] = useAddSellerMutation();
  const [updateSeller] = useUpdateSellerMutation();

  const handelVerifyCode = async (e) => {
    e.preventDefault();
    setIsVerfying(true);
    try {
      const userData = await updateSeller({
        fullName,
        email: emailForSignUp,
        verifyCode: verificationCode,
      }).unwrap();

      if (userData[1] === 'Email already exists') {
        if (userData[2] === 'pending') {
          setIsOnVerification(true);
          return;
        }
        setErrMsg('Email Already exists');
        return;
      }

      setIsEmailVerified(true);
    } catch (err) {
      if (!err?.originalStatus) {
        // isLoading: true until timeout occurs
        setErrMsgForVerify('Connection problem or invalid verification code');
      } else if (err.originalStatus === 400) {
        setErrMsgForVerify('verification Code not Correct');
      } else if (err.originalStatus === 401) {
        setErrMsgForVerify('Unauthorized');
      } else {
        setErrMsgForVerify('Login Failed');
      }
      verificationErrRef.current.focus();
    } finally {
      setIsVerfying(false);
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    if (passwordForsignUP !== confirmpassword) {
      setErrMsg('Password Must Match');
      setPasswordMatch(true);
      return;
    }

    setIsSigningUP(true);
    try {
      const userData = await addSeller({
        name: fullName,
        email: emailForSignUp,
        password: passwordForsignUP,
        userLanguage: selectedLanguage,
      }).unwrap();
      if (userData[1] === 'Email already exists') {
        if (userData[2] === 'pending') {
          setIsOnVerification(true);
          return;
        }
        setErrMsg('Email Already exists');
        return;
      }

      setIsOnVerification(true);
    } catch (err) {
      if (!err?.originalStatus) {
        // isLoading: true until timeout occurs
        setErrMsg('No Server Response');
      } else if (err.originalStatus === 400) {
        setErrMsg('Missing Username or Password');
      } else if (err.originalStatus === 401) {
        setErrMsg('Unauthorized');
      } else {
        setErrMsg('Login Failed');
      }
      errRef.current.focus();
    } finally {
      setIsSigningUP(false);
    }
  };

  const handleEmailSignUpchange = (e) => setEmailSignUp(e.target.value);

  const { i18n } = useTranslation();
  const selectedLanguage = i18n.language;

  const handlefullNameChangeInput = (e) => setFullName(e.target.value);

  const handleVerifyCodeChangeInput = (e) =>
    setVerificationCode(e.target.value);

  const handelPwdSignUpChange = (e) => setPassWordSignUp(e.target.value);

  return (
    <>
      <Navbar></Navbar>
      <div className="justify-center gap-4 pt-12 h-screen flex">
        <div
          className={`${
            isOnVerification ? 'hidden' : ''
          }  w-full md:w-4/12  bg-white pt-7 flex-col border-dashed border-2 px-3 rounded-lg justify-center items-center`}
          style={{ height: '500px' }}
        >
          <div className="text-2xl font-normal text-center mb-6">
            {t('signup')}
          </div>
          <form className="mb-12" onSubmit={handleSignup}>
            <label className="block mb-3">
              <span className="block text-sm font-medium text-slate-700">
                {t('companysellername')}
              </span>
              <input
                ref={userRef}
                value={fullName}
                onChange={handlefullNameChangeInput}
                autoComplete="off"
                required
                type="text"
                placeholder="Full Name"
                className="mt block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
      focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
       
      focus:invalid:border-pink-500 focus:invalid:ring-pink-500
    "
              />
            </label>
            <label className="block mb-3">
              <span className="block text-sm font-medium text-slate-700">
                {t('email')}
              </span>
              <input
                ref={userRef}
                value={emailForSignUp}
                onChange={handleEmailSignUpchange}
                autoComplete="off"
                required
                type="email"
                placeholder="Email"
                className="mt block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
      focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
       
      focus:invalid:border-pink-500 focus:invalid:ring-pink-500
    "
              />
            </label>
            <label className="block">
              <span className="block text-sm font-medium text-slate-700">
                {t('password')}
              </span>
              <input
                type="password"
                id="password"
                onChange={handelPwdSignUpChange}
                value={passwordForsignUP}
                required
                placeholder="Password"
                className="mt block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
      focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
       
      focus:invalid:border-pink-500 focus:invalid:ring-pink-500
    "
              />
            </label>
            <label className="block mt-4">
              <span className="block text-sm font-medium text-slate-700">
                Confirm Password
              </span>
              <input
                type="password"
                id="Confirmpassword"
                onChange={handleConfirmPwdChange}
                value={confirmpassword}
                required
                placeholder="Password"
                className={`mt block w-full px-3 py-2 bg-white border  ${
                  passwordmatch ? 'border-red-500' : 'border-slate-300'
                } rounded-md text-sm shadow-sm placeholder-slate-400
      focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
       
      focus:invalid:border-pink-500 focus:invalid:ring-pink-500
    `}
              />
            </label>
            <label className="block mt-4">
              <input
                type="checkbox"
                checked={isAgreementChecked}
                onChange={handleAgreementCheck}
                className="mr-2"
                required
              />
              <span className="text-sm font-medium text-slate-700">
                {t('agreeingtoPrivacyPolicy')}
              </span>
            </label>
            <span
              className="text-sm font-medium text-slate-700 cursor-pointer ml-5  underline hover:text-sky-500"
              onClick={openModal}
            >
              {t('viewPrivacyPolicy')}
            </span>

            {/* Modal */}
            <Modal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              style={customStyles}
            >
              {/* Add your agreement content here */}
              <div>
                <div className="font-sans text-gray-800 bg-gray-100 p-4 sm:p-8 md:p-12 lg:p-16">
                  <div className="container mx-auto my-8 p-4">
                    <h2 className="text-2xl font-bold mb-4">Datenschutz</h2>{' '}
                    background-color: rgb(243 244 246 / var(--tw-bg-opacity));
                    <p>
                      Vielen Dank für dein Interesse an unserem Online-Shop. Um
                      dir diesen Online-Shop anzubieten, sammeln wir
                      Informationen über dich, die entweder direkt von dir, über
                      dich oder über andere Kanäle zur Verfügung gestellt
                      werden. Wir nehmen den Schutz deiner Privatsphäre sehr
                      ernst. Nachfolgend informieren wir dich ausführlich über
                      die Erhebung und Verwendung deiner personenbezogenen
                      Daten.
                    </p>
                    <section className="my-8">
                      <h3 className="text-lg font-bold mb-2">Einleitung</h3>
                      <p>
                        (1) Sollten personenbezogene Daten (z.B. Name,
                        Anschrift, E-Mail-Adresse) erhoben werden, verpflichten
                        wir uns dazu, Ihre vorherige Einverständnis einzuholen.
                        Wir verpflichten uns dazu, keine Daten an Dritte
                        weiterzugeben, es sei denn, Sie haben zuvor
                        eingewilligt.
                      </p>
                      <p>
                        (2) Wir weisen darauf hin, dass die Übertragung von
                        Daten im Internet (z. B. per E-Mail) Sicherheitslücken
                        aufweisen kann. Demnach kann ein fehlerfreier und
                        störungsfreier Schutz der Daten Dritter nicht
                        vollständig gewährleistet werden. Diesbezüglich ist
                        unsere Haftung ausgeschlossen.
                      </p>
                      <p>
                        (3) Dritte sind nicht dazu berechtigt, Kontaktdaten für
                        gewerbliche Aktivitäten zu nutzen, sofern der Anbieter
                        den betroffenen Personen vorher eine schriftliche
                        Einwilligung erteilt hat.
                      </p>
                      <p>
                        (4) Sie haben jederzeit das Recht, von SALE TO EUROPE
                        über den Sie betreffenden Datenbestand vollständig und
                        unentgeltlich Auskunft zu erhalten.
                      </p>
                      <p>
                        (5) Des Weiteren besteht ein Recht auf
                        Berichtigung/Löschung von Daten/Einschränkung der
                        Verarbeitung für den Nutzer.
                      </p>
                      <p>
                        (6) Um den Webshop zu betreiben und die von uns
                        angebotenen Dienstleistungen zu erbringen, werden deine
                        personenbezogenen Daten in die Türkei übermittelt.
                      </p>
                      <p>
                        Darüber hinaus befinden sich einige unserer
                        Dienstleister, die wir einsetzen, um dir unsere Dienste
                        zur Verfügung zu stellen, in Ländern, die kein
                        Datenschutzniveau bieten, das dem der Europäischen Union
                        entspricht oder ähnlich ist.
                      </p>
                      <p>
                        Wenn wir dies tun, ist dies entweder erforderlich, um
                        dir die von dir angeforderten Leistungen mit deiner
                        ausdrücklichen Einwilligung, oder anderen
                        Übertragungsmechanismen zur Verfügung zu stellen, die
                        nach dem Gesetz verfügbar sein können, wie z. B.
                        Standardvertragsklausel.
                      </p>
                    </section>
                    <section className="my-8">
                      <h3 className="text-lg font-bold mb-2">
                        Welche Daten sammeln wir von dir
                      </h3>
                      <p>
                        Wenn du Sale to Europe nutzt oder mit uns auf eine
                        andere Weise mit uns kommunizierst, erfassen wir Daten
                        entweder direkt von dir oder über dich.
                      </p>
                      <p>
                        Wir speichern daten von dir, wenn du bei uns ein
                        Kundenkonto eröffnet hast. Diese Daten können Namen,
                        E-Mail-Adresse, Telefonnummer, Geburtsdatum,
                        Geschlechtsinformationen und
                        Social-Media-Profilinformationen enthalten, wenn du dich
                        auf diese Weise anmeldest.
                      </p>
                      <p>
                        Wir sammeln deine Zahlungsinformationen, um deine
                        Zahlung zu erleichtern. Dies kann die Art der
                        verwendeten Zahlungsmethode, Zahlungsdetails wie
                        Kreditkartennummer oder Bankkontonummer und
                        Informationen zur Zahlungsüberprüfung umfassen.
                      </p>
                      <p>
                        <strong>
                          Daten, die direkt von dir oder von deinem Gerät
                          stammen:
                        </strong>
                      </p>
                      <p>
                        Wenn du unsere Website besuchst oder unsere App
                        verwendest, erfassen wir bestimmte Informationen über
                        dein Gerät.
                      </p>
                      <p>
                        Diese Informationen werden benötigt, damit du dich
                        einloggen kannst und damit wir dir ein besseres,
                        optimiertes Produkt anbieten können. Diese Informationen
                        können dein Betriebssystem, deine IP-Adresse,
                        Standortdaten, Werbung und Geräte-IDs enthalten sowie
                        die Art des verwendeten Geräts und Browserinformationen.
                      </p>
                      <p>
                        <strong>Cookie-Daten:</strong> Wenn du auf unserer
                        Website oder in unseren Apps surfst und du deren
                        Verwendung zustimmst, platzieren wir Cookies oder
                        ähnliche Technologien auf deinem Gerät, um deine Nutzung
                        unserer Website zu verstehen.
                      </p>
                      <p>
                        Weitere Informationen über ihre Verwendung und welche
                        Art von Daten sie sammeln, findest du in den
                        Einwilligungsbanner.
                      </p>
                      <p>
                        Wenn du bei Sale to Europe einkaufst, verwenden wir
                        deine Daten:
                      </p>
                      <ul className="list-disc pl-8">
                        <li>
                          Damit du bei uns auf der Plattform einkaufen kannst
                          und du dein Kundenkonto verwalten, aktualisieren oder
                          löschen kannst.
                        </li>
                        <li>
                          Damit wir deine bestellten Waren liefern und du Waren
                          zurückgeben kannst.
                        </li>
                      </ul>
                    </section>
                  </div>
                </div>
              </div>
            </Modal>

            <div className="text-center mt-8">
              <button
                className={` ${
                  isSigningUp ? 'disabled:bg-orange-300' : ''
                } mt-4 text-white  text-center bg-purple-700 hover:bg-purple-600 rounded-full w-28 py-1 px-3`}
              >
                {isSigningUp ? 'Processing...' : t('signup')}
              </button>
              <div className="mt-4">
                {t('alreadyhaveaccount')}?{' '}
                <span>
                  <a href="/login" className="">
                    {t('login')}
                  </a>
                </span>
              </div>
              <p
                ref={errRef}
                className={`${
                  errMsg ? 'errmsg' : 'offscreen'
                } my-4 text-red-600`}
                aria-live="assertive"
              >
                {errMsg}
              </p>
            </div>
          </form>
        </div>
        <div
          className={`${
            isOnVerification ? '' : 'hidden'
          } w-full md:w-4/12  bg-white pt-7 flex-col px-3 rounded-lg justify-center items-center`}
        >
          <div className="text-black">
            {t('verificationemailmessage')}{' '}
            <span className="text-gray-700">{emailForSignUp}</span>{' '}
            {t('verificationcode')}
          </div>
          <form className="mb-12" onSubmit={handelVerifyCode}>
            <label className="block mb-3">
              <span className="block text-sm font-medium text-slate-700">
                Verification Code
              </span>
              <input
                ref={verificationCodeRef}
                value={verificationCode}
                onChange={handleVerifyCodeChangeInput}
                autoComplete="off"
                required
                type="text"
                placeholder="Code"
                className="mt block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
      focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
       
      focus:invalid:border-pink-500 focus:invalid:ring-pink-500
    "
              />
            </label>

            <button
              className={`${
                IsVerifying ? 'disabled:bg-orange-300' : ''
              } bg-purple-700 hover:bg-purple-600 text-white rounded-md px-2 py-1 `}
            >
              {IsVerifying ? 'Verifying...' : 'Verify'}
            </button>
          </form>
          {isEmailverified && (
            <div className="text-green-500">{t('verifiedmessage')}</div>
          )}
          <p
            ref={verificationErrRef}
            className={`${
              errMsgForVerfication ? 'errmsg' : 'offscreen'
            } my-4 text-red-600`}
            aria-live="assertive"
          >
            {errMsgForVerfication}
          </p>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default SellerSignUpBody;
