import React, { useContext, useEffect, useState } from "react";
import "../styles/carousel.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderItem from "./SliderItem";
import { useGetSliderAndSideItemsMutation } from "../features/auth/authApiSlice";
import { WelcomeLoader } from "../contexts/LoaderContext";

const CustomSlider = () => {
  const [getSliderAndSideItems, { isSliderLoading }] =
    useGetSliderAndSideItemsMutation();
  const [fetchedSLiderItems, setFetchedSliderItems] = useState([]);
  const { setIsSliderLoading } = useContext(WelcomeLoader);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sliderSideItems = await getSliderAndSideItems();
        setFetchedSliderItems(sliderSideItems.data.sliderItems);
        setIsSliderLoading(false);
      } catch (error) {
        setIsSliderLoading(false);
      }
    };

    fetchData();
  }, [getSliderAndSideItems, setIsSliderLoading, setFetchedSliderItems]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    direction: "rtl",
    customPaging: (i) => (
      <div
        className="bg-purple-300 hidden md:inline-flex"
        style={{
          width: "10px", // Adjust the width of your custom dot
          height: "10px", // Adjust the height of your custom dot
          borderRadius: "50%", // Adjust the border-radius for a rounded look
        }}
      />
    ),
  };

  return (
    <div className="custom_slider_item bg-purple-100 w-[90vw] md:w-full rounded-md">
      <Slider {...settings}>
        {fetchedSLiderItems.map((slide, index) => (
          <SliderItem key={index} slide={slide} index={index} />
        ))}
      </Slider>
    </div>
  );
};

export default CustomSlider;
