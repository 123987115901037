// PDFViewerComponent.js
import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import PDFComponent from './PDFComponent';

const PDFViewerComponent = ({order}) => (
  <PDFViewer width="100%" height="600px">
    <PDFComponent order={order}/>
  </PDFViewer>
);
/*fsdhjsd*/
export default PDFViewerComponent;
