import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectCurrentPendingOrders,
  selectCurrentUserId,
} from "../../features/auth/authSlice";
import {
  useGetAllCouponsOfSellerMutation,
  useGetOrdersByStatusSellerMutation,
} from "../../features/auth/authApiSlice";
import OrderDetail from "./OrderDetail";
import CouponDetail from "./CouponDetail";
import { useTranslation } from "react-i18next";

function MyCouponsPage({ selectedSidebarItem, onSelectSidebarItem }) {
  const [fetchedCoupons, setFetchedCoupons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const sellerId = useSelector(selectCurrentUserId);
  const [getAllCouponsOfSeller, { isSellerLoading }] =
    useGetAllCouponsOfSellerMutation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const result = await getAllCouponsOfSeller({
          sellerId: sellerId,
        }).unwrap();
        

        setFetchedCoupons(result);

        setIsLoading(false);
        // Update state with the fetched data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Invoke the fetchData function when the component mounts or when 'Id' changes
  }, [sellerId]); // Include 'Id' as a dependency to refetch data when 'Id' changes

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div
          className={` text-gray-800 px-6 py-4 bg-gray-100 w-full`}
          style={{ overflowY: "auto", height: "calc(100vh - 56px)" }}
        >
          <div className="w-full bg-white h-16 flex items-center px-2 rounded-md  text-lg text-opacity-70">
            {t("myCoupons")}
          </div>
          {fetchedCoupons.map((coupon, index) => (
            <CouponDetail
              coupon={coupon}
              selectedSidebarItem={selectedSidebarItem}
              onSelectSidebarItem={onSelectSidebarItem}
            />
          ))}
        </div>
      )}
    </>
  );
}

export default MyCouponsPage;
