import { useEffect, useState } from "react";
import Modal from "react-modal";

import {
  setCouponIdTobeEdited,
  setSelectedItem,
} from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import {
  useDeleteCouponMutation,
  useUpdateOrderStatusMutation,
} from "../../features/auth/authApiSlice";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import { useTranslation } from "react-i18next";

function CouponDetail({ coupon, selectedSidebarItem, onSelectSidebarItem }) {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusSuccessMsg, setstatusSuccessMsg] = useState(null);
  const [statusErrMsg, setstatusErrMsg] = useState(null);

  const { t } = useTranslation();

  const [deleteCoupon, { isLoading }] = useDeleteCouponMutation();

  const [showDetails, setShowDetails] = useState(false);
  const handleDetailsToggle = () => {
    setShowDetails(!showDetails);
  };

  const customStyles = {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  };

  // ... (your existing code)

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // ... (your existing code)
  const [message, setMessage] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    // Implement the logic to delete the coupon here
    try {
      setIsDeleting(true);

      const userData = await deleteCoupon({
        couponId: coupon._id,
      });

      // Show success message
      setMessage({ text: "Coupon deleted successfully!", type: "success" });
    } catch (error) {
      // Handle errors
      console.error("Error deleting coupon:", error);
      setMessage({
        text: "Failed to delete Coupon. Please try again.",
        type: "error",
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };
  return (
    <>
      <div key={coupon._id} className={`bg-white  rounded-md hover:bg-teal-50`}>
        <div
          className="felxColumnforMobile md:flex md:flex-wrap md:items-center rounded-md  mt-4  w-full "
          style={{ transition: "height 2s, width 2s, background 2s" }}
        >
          <div className="ml-2 flex  py-2 items-center">
            <p className="text-md text-gray-500 w-32">{coupon.couponTitle}</p>
          </div>
          <p className="ml-4 md:ml-20">
            <span className="font-extralight w-28">{t("items")}:</span>
            {coupon.forWhichItems}
          </p>
          <div className="flex ml-12 ">
            <p className="mr-1">
              {t("discountAmount")}:{" "}
              <span className="opacity-75" style={{ opacity: "0.75" }}>
                {coupon.discountamount}
                {coupon.couponType === "moneyOff" ? "$" : "%"}
              </span>
            </p>
          </div>
          <div className="ml-auto">
            {t("expiryDate")}:{" "}
            <span className="opacity-75" style={{ opacity: "0.75" }}>
              {new Date(coupon.expiryDate).toLocaleDateString("en-US")}
            </span>
          </div>
          <div
            onClick={() => {
              dispatch(
                setCouponIdTobeEdited({
                  couponId: coupon._id,
                })
              );
              dispatch(setSelectedItem({ selectedItem: "Edit Coupon" }));
              onSelectSidebarItem("Edit Coupon");
            }}
            className="ml-auto cursor-pointer hover:text-teal-500"
          >
            <CiEdit size={24} color="#4CAF50" />
          </div>
          <div
            className="mx-4 cursor-pointer hover:text-red-500"
            onClick={openDeleteModal}
          >
            <MdDelete size={24} color="#FF5252" />
          </div>
          {showDeleteModal && (
            <div className="flex justify-center items-center">
              <div className="ml-3 my-2">
                <p>{t("deleteCouponConfirmation")}</p>
                <p>{message.text}</p>
                <button
                  onClick={handleDelete}
                  className={`bg-red-600 px-2 py-1 rounded-md text-white mx-3 ${
                    isDeleting ? "disabled:bg-red-400" : ""
                  }`}
                >
                  {isDeleting ? "Deleting..." : t("delete")}{" "}
                </button>
                <button
                  onClick={closeDeleteModal}
                  className="bg-gray-800 px-2 py-1 rounded-md text-white"
                >
                  {t("close")}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default CouponDetail;
