import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import { addCanceledOrders, addDeliveredOrders, addOntheWayOrder, addPendingOrder, addPreparingOrder, removeFromCanceledOrder, removeFromDeliveredOrder, removeFromOnthewayOrder, removeFromPendingOrder, removeFromPreparingOrder, selectCurrentCanceledOrders, selectCurrentDeliveredOrders, selectCurrentEmail, selectCurrentName, selectCurrentOntheWayOrders, selectCurrentPendingOrders, selectCurrentPreparingOrders, selectCurrentUserId, setUser } from '../../features/auth/authSlice';
import { useRemoveCartMutation } from '../../features/auth/authApiSlice';

function NotifyBuyerOrder() {
    const [istherenew, setistherenew] = useState(false);
    const pendingOrdersList = useSelector(selectCurrentPendingOrders);

    const preparingOrdersList = useSelector(selectCurrentPreparingOrders);
    const onthewayOrdersList = useSelector(selectCurrentOntheWayOrders);

    const deliveredOrdersList = useSelector(selectCurrentDeliveredOrders);

    const canceledOrdersList = useSelector(selectCurrentCanceledOrders);
    const email = useSelector(selectCurrentEmail);
  const fullName = useSelector(selectCurrentName);

    const currentBuyerId = String(useSelector(selectCurrentUserId));
    const dispatch = useDispatch();
    const [removeCart, { isLoading }] = useRemoveCartMutation();


  const socket = io(process.env.REACT_APP_BACKEND_API);
//   const socket2 = io('https://www.abdulmelikambaw.tech');
//   socket2.on('newOrder', async (data) => {
//     console.log('data')
//     console.log(data.order.buyerId)
//     const order  = data.order;
   
//     const condition = String(order.buyerId) === String(currentBuyerId);
//     console.log(!condition);
//     if(condition){
//         const userData = await removeCart({ fullName, email, cartItemId: order.itemId }).unwrap();
//         dispatch(
//             setUser({
//               id: userData._id,
//               email: userData.email,
//               name: userData.fullName,
//               role: userData.role,
//               accountStatus: userData.accountStatus,
//               cart: userData.cart
//             }),
//           );

//     }})

  socket.on('updatedOrder', (data) => {
    
    const order  = data.updatedOrder;

    const condition = String(order.buyerId) === String(currentBuyerId);
    if(condition){

    
    // Handle the new order event
  
    if(String(order.orderStatus) === "pending"){
        dispatch(addPendingOrder({
            orderId: order._id
          }));
          dispatch(removeFromCanceledOrder({
            orderId: order._id
        }))
        dispatch(removeFromDeliveredOrder({
            orderId: order._id
        }))  
        dispatch(removeFromPreparingOrder({
            orderId: order._id
        }))
        dispatch(removeFromOnthewayOrder({
            orderId: order._id
        }))
    } else if (String(order.orderStatus) === "preparing"){
        dispatch(addPreparingOrder({
            orderId: order._id
          }));
          dispatch(removeFromCanceledOrder({
            orderId: order._id
        }))
        dispatch(removeFromDeliveredOrder({
            orderId: order._id
        }))  
        dispatch(removeFromPendingOrder({
            orderId: order._id
        }))
        dispatch(removeFromOnthewayOrder({
            orderId: order._id
        }))
    } else if (String(order.orderStatus) === "ontheway"){
        dispatch(addOntheWayOrder({
            orderId: order._id
          }));
          dispatch(removeFromCanceledOrder({
            orderId: order._id
        }))
        dispatch(removeFromDeliveredOrder({
            orderId: order._id
        }))  
        dispatch(removeFromPendingOrder({
            orderId: order._id
        }))
        dispatch(removeFromPreparingOrder({
            orderId: order._id
        }))
    } else if (String(order.orderStatus) === "delivered"){
        dispatch(addDeliveredOrders({
            orderId: order._id
          }));
          dispatch(removeFromCanceledOrder({
            orderId: order._id
        }))
        dispatch(removeFromPreparingOrder({
            orderId: order._id
        }))  
        dispatch(removeFromPendingOrder({
            orderId: order._id
        }))
        dispatch(removeFromOnthewayOrder({
            orderId: order._id
        }))
    }else if (String(order.orderStatus) === "cancelled"){
        dispatch(addCanceledOrders({
            orderId: order._id
          }));
          dispatch(removeFromPreparingOrder({
            orderId: order._id
        }))
        dispatch(removeFromDeliveredOrder({
            orderId: order._id
        }))  
        dispatch(removeFromPendingOrder({
            orderId: order._id
        }))
        dispatch(removeFromOnthewayOrder({
            orderId: order._id
        }))
    }
   
    
    console.log(order);
    setistherenew(true);
  }
    // Display a notification or update the order list in the frontend
  });
  
    return <>
    {istherenew && <div></div>}
    </>
}

export default NotifyBuyerOrder;