import { PayPalButtons } from '@paypal/react-paypal-js';
import {  useState } from 'react';
import { usePayPalPayMutation } from '../../features/auth/authApiSlice';
import { useTranslation } from 'react-i18next';

const PaypalCheckoutButton = (props) => {
  const { t } = useTranslation();
  const { product, product2 } = props;
  
  // const fetchedOrderList = product.map((order) => {
  //   return {
  //     description: 'Payment description',
  //     amount: {
  //       value: order.price,
  //     },
  //   };
  // });

  // const [orderlist, setOrderList] = useState(fetchedOrderList);
  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   setIsLoading(true);
  //   // Fetch or set orderlist here
  //   const fetchedOrderList = product.map((order) => {
  //     return {
  //       description: 'Payment description',
  //       amount: {
  //         value: order.price,
  //       },
  //     };
  //   });
  //   console.log('fetchedOrderList');
  //   console.log(fetchedOrderList);
  //   setOrderList(fetchedOrderList);
  //   setIsLoading(false);
  // }, [product]);
  const [PayPalPay, { isPayPalLoading }] = usePayPalPayMutation();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [paidFor, setPaidFor] = useState(false);
  const [error, setError] = useState(null);

  const handleApprove = async (orderId) => {
    // Call backend function to fulfill order
    const userData = await PayPalPay({ product }).unwrap();

    // if response is success
    setShowSuccessMessage(true);

    // Refresh user's account or subscription status

    // if response is error
    // alert("Your payment was processed successfully. However, we are unable to fulfill your purchase. Please contact us at support@designcode.io for assistance.");
  };

  if (showSuccessMessage) {
    return <div>{t('paypalPaymentSucces')}</div>;
  }

  if (paidFor) {
    // Display success message, modal or redirect user to success page
    alert('Thank you for your purchase!');
  }
  if (error) {
    // Display error message, modal or redirect user to error page
    alert(error);
  }

  return (
    <PayPalButtons
      onClick={(data, actions) => {
        // Validate on button click, client or server side
        const hasAlreadyBoughtCourse = false;

        if (hasAlreadyBoughtCourse) {
          setError(
            'You already bought this course. Go to your account to view your list of courses.',
          );

          return actions.reject();
        } else {
          return actions.resolve();
        }
      }}
      createOrder={(data, actions) => {
        return actions.order.create({
          purchase_units: product2,
        });
      }}
      onApprove={async (data, actions) => {
        const order = await actions.order.capture();

        handleApprove(data.orderID);
      }}
      onError={(err) => {
        setError(err);
        console.error('PayPal Checkout onError', err);
      }}
      onCancel={() => {
        // Display cancel message, modal or redirect user to cancel page or back to cart
      }}
    />
  );
};
export default PaypalCheckoutButton;
