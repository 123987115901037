import React, { useState } from "react";
import { GoSearch } from "react-icons/go";
import { TfiSearch } from "react-icons/tfi";

import { Link, NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faShoppingCart,
  faUserTag,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { FaRegUserCircle } from "react-icons/fa";
import { FiShoppingCart } from "react-icons/fi";
import "../styles/navbar.css";
import "../styles/welcome.css";
import {
  selectCurrentName,
  selectCurrentCart,
  selectCurrentUser,
  selectCurrenRole,
  setSelectedProfileBar,
  selectCurrentMainCatagoryNames,
  selectCurrentUserImgUrl,
} from "../features/auth/authSlice";

import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./languageSwitcher";
import {
  useGetLoginInfoMutation,
  useSearchAllItemsMutation,
} from "../features/auth/authApiSlice";

import NavCategories from "./NavCategories";

const Navbar = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const [isSearching, setIsSearching] = useState(false);
  const [searchAllItems, { isLoading }] = useSearchAllItemsMutation();
  const [getLoginInfo, { isLoggingLoading }] = useGetLoginInfoMutation();

  const navigate = useNavigate();

  const handleSearch = async () => {
    //console.log('searching...');
    //console.log(searchQuery);
    const SearchResults = await searchAllItems({
      query: searchQuery,
    });
    //console.log('SearchResults');
    //console.log(SearchResults);

    navigate("/searchResultsPage", {
      state: { searchResults: SearchResults.data, query: searchQuery },
    });
    // Add logic here to handle the search action based on the searchQuery.
  };

  const handleKeyPress = (event) => {
    // Check if the pressed key is Enter (keyCode 13)
    if (event.key === "Enter") {
      // Prevent the default behavior (form submission or page reload)
      event.preventDefault();
      // Call your handleSearch function
      if (searchQuery !== "") {
        handleSearch();
      }
    }
  };

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const dispatch = useDispatch();

  const mainCatagoryNamesList = useSelector(selectCurrentMainCatagoryNames);

  const handleCartIconClick = () => {
    // Dispatch an action to set some global state when the cart icon is clicked
    dispatch(setSelectedProfileBar({ SelectedProfileBar: "Cart" }));
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const cart = useSelector(selectCurrentCart);
  const currentUser = useSelector(selectCurrentUser);
  const CurrentName = useSelector(selectCurrentName);
  const imgUrl = useSelector(selectCurrentUserImgUrl);
  const currentRole = useSelector(selectCurrenRole);
  const isBuyer = currentRole === process.env.REACT_APP_BUYER_ROLE;
  const isSeller = currentRole === process.env.REACT_APP_SELLER_ROLE;
  const isAdmin = currentRole === process.env.REACT_APP_ADMIN_ROLE;

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    // You can add logic here to handle the search query (e.g., filter products).
  };

  const destinationLink = currentUser ? "/buyerprofile" : "/login";
  const handleProfileClick = async () => {
    if (currentUser) {
      const isTheUserLoggedIn = await getLoginInfo().unwrap();
      //console.log(isTheUserLoggedIn);
      if (isTheUserLoggedIn.islogged === "yeah") {
        if (isBuyer) {
          navigate("/buyerProfile");
        } else if (isSeller) {
          navigate("/SellerDashBoard");
        } else if (isAdmin) {
          navigate("/admin");
        }
      } else {
        navigate("/login");
      }
    } else {
      //console.log('pushhhhhhhhh');

      navigate("/login");
    }
  };

  const { t } = useTranslation();
  //console.log(categoriesData, "");

  return (
    <>
      <div className=" z-50 px-8 sm:px-8 md:px-0">
        <nav className="md:pl-24 flex flex-auto md:items-center  md:h-18 pt-2 md:pt-5">
          <div className="md:hidden self-start ml-2">
            <button
              onClick={toggleMobileMenu}
              className="block text-black-600  focus:outline-none mr-4"
            >
              <FontAwesomeIcon icon={faBars} style={{ fontSize: "24px" }} />{" "}
              {/* Replace with your hamburger icon */}
            </button>
          </div>
          <div className="pr-5">
            {/* Placeholder for icon */}
            <div
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
              className="text-violet-800 text-[35px] font-bold font-['Poppins'] leading-7 tracking-tight"
            >
              Saletoeurope
            </div>
          </div>

          {/* Search bar */}
          <form className="search-form">
            <div className="flex">
              <select
                className="hidden  h-10  w-32 bg-transparent border border-black border-opacity-30  focus:outline-none focus:ring-2 focus:border-r-2 focus:ring-purple-600 focus:border-transparent"
                aria-label="Search option"
              >
                <option value="all">{t("all")}</option>
                <option value="products">{t("products")}</option>
                <option value="users">{t("users")}</option>
                {/* Add more options as needed */}
              </select>

              <input
                className="hidden  md:inline-block h-10  border border-black border-opacity-30 rounded-l   pr-10 pl-3  focus:outline-none  focus:border-purple-700 "
                placeholder={`${t("search")}...`}
                onChange={handleSearchChange}
                type="text"
                style={{ width: currentRole ? "495px" : "525px" }}
                onKeyDown={handleKeyPress}
              />

              <div
                onClick={handleSearch}
                className="hidden cursor-pointer h-10 w-12 md:flex items-center justify-center rounded-r  text-white"
                style={{ backgroundColor: "var(--themeBold)" }}
              >
                <TfiSearch size={25} />
              </div>
            </div>
          </form>

          {/* Cart and other elements */}

          <ul
            className={`custumMarigin  flex flex-row ${
              currentRole ? "md:ml-14 md:gap-4" : "md:ml-20"
            }  `}
          >
            <li>
              {currentUser ? (
                <div
                  onClick={() => {
                    handleProfileClick();
                  }}
                  className="link cursor-pointer"
                >
                  <img
                    src={imgUrl}
                    className={`${
                      imgUrl ? "" : "bg-blue-600"
                    } h-8 w-8 rounded-full cursor-pointer`}
                  />
                  <span className="text-xs cursor-pointer relative right-2 hidden md:inline-block w-10 text-center">
                    {currentRole !== process.env.REACT_APP_ADMIN_ROLE
                      ? CurrentName?.length > 5
                        ? `${CurrentName?.slice(0, 5)}...`
                        : CurrentName
                      : "Admin"}
                  </span>
                </div>
              ) : (
                <div
                  onClick={() => {
                    handleProfileClick();
                  }}
                  className="link cursor-pointer"
                >
                  <FaRegUserCircle
                    className="curs"
                    style={{ fontSize: "23px" }}
                  />
                  <span className="text-black text-sm font-normal font-['Poppins'] leading-[10px] tracking-tight relative right-2 hidden md:inline-block">
                    {t("profile")}
                  </span>
                </div>
              )}
            </li>
            {/* Add more elements as needed */}

            <li className="mr-3 md:ml-4 mt-1">
              <Link
                onClick={handleCartIconClick}
                to={destinationLink}
                className="link cursor-pointer"
              >
                {currentUser && isBuyer && (
                  <FiShoppingCart style={{ fontSize: "25px" }} />
                )}
                {currentUser && isBuyer && cart?.length > 0 && (
                  <span className="text-xs ml-4 bg-purple-700 md:h-6  h-4 w-4 md:w-6 flex text-center justify-center items-center p-0 md:p-1 rounded-full text-white  md:inline-block absolute top-1 md:top-2 z-10">
                    <span>{cart?.length}</span>
                  </span>
                )}
                {currentUser && isBuyer && (
                  <span className="text-xs ml-1 mt-2 hidden md:inline-block">
                    {t("cart").length > 6
                      ? `${t("cart").slice(0, 6)}...`
                      : t("cart")}
                  </span>
                )}
              </Link>
            </li>
            <li className={`md:ml-2 md:mt-4 `} style={{ zIndex: "10" }}>
              <LanguageSwitcher></LanguageSwitcher>{" "}
            </li>
          </ul>
        </nav>

        <div
          className={`  ${
            isMobileMenuOpen
              ? "block transition-opacity duration-300 opacity-100"
              : "hidden opacity-0 transform scale-95"
          } md:hidden bg-white shadow-lg`}
        >
          <div className="bg-white absolute left-0 top-auto border-b-gray-500 w-full p-4">
            <ul className="text-black-600">
              <li>
                <NavLink
                  to="/"
                  className="block py-1 text-lg hover:text-purple-600"
                  onClick={toggleMobileMenu}
                >
                  {t("home")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/signupseller"
                  className="block py-1 text-lg hover:text-purple-600"
                  onClick={toggleMobileMenu}
                >
                  {t("sell")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/login"
                  className="block py-1 text-lg hover:text-purple-600"
                  onClick={toggleMobileMenu}
                >
                  {t("login")}
                </NavLink>
              </li>
              {/* Add more mobile menu items here */}
            </ul>
          </div>
        </div>

        <div className="md:hidden flex w-full mr-2 mt-2">
          <input
            className="flex-1 h-10 border border-black border-opacity-30 pr-3 pl-3 focus:outline-none focus:ring-2 focus:ring-purple-700 focus:border-transparent rounded-l-sm"
            placeholder="Search product..."
            onChange={handleSearchChange}
            type="text"
            style={{ width: "100%" }}
          />
          <div
            onClick={handleSearch}
            className="h-10 w-10 flex items-center justify-center rounded-r-sm bg-purple-700 text-white"
          >
            <FontAwesomeIcon icon={faSearch} />
          </div>
        </div>


        <NavCategories />
      </div>
    </>
  );
};

export default Navbar;
