import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectCurrentPendingOrders,
  selectCurrentUserId,
} from "../../features/auth/authSlice";
import {
  useGetBuyerByAccountStatusAndPageMutation,
  useGetOrdersByStatusSellerMutation,
  useGetSellerByBusinessStatusAndPageMutation,
  useGetSellerByBusinessStatusMutation,
} from "../../features/auth/authApiSlice";
import UserDetail from "./userDetail";

function UserStatusDetailsPage({ userStatus }) {
  const [pendingSellers, setPendingSellers] = useState([]);
  const [verifiedSellers, setVerifiedSellers] = useState([]);
  const [blockedSellers, setBlockedSellers] = useState([]);
  const [verifiedBuyers, setVerifiedBuyers] = useState([]);
  const [blockedBuyers, setBlockedBuyers] = useState([]);
  const [fetchedUsers, setFetchedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [firstFetchedItems, setfirstFetchedItems] = useState(null);

  const pendingOrderIdsList = useSelector(selectCurrentPendingOrders);
  const sellerId = useSelector(selectCurrentUserId);
  const [getBuyerByAccountStatusAndPage, { isSellerLoading }] =
    useGetBuyerByAccountStatusAndPageMutation();
  const [getSellerByBusinessStatusAndPage, { isSellerByPageLoading }] =
    useGetSellerByBusinessStatusAndPageMutation();

  useEffect(() => {
    const fetchData = async () => {
      
      try {
        setIsLoading(true);

        if (userStatus === "Pending Seller") {
          const page = 1;
          const result = await getSellerByBusinessStatusAndPage({
            businessStatus: "pending",
            page,
          }).unwrap();

          setFetchedUsers(result);
          setfirstFetchedItems(result.length);
        } else if (userStatus === "Verified Seller") {
          const page = 1;
          const result = await getSellerByBusinessStatusAndPage({
            businessStatus: "verified",
            page,
          }).unwrap();

          setFetchedUsers(result);
          setfirstFetchedItems(result.length);
        } else if (userStatus === "Blocked Seller") {
          const page = 1;
          const result = await getSellerByBusinessStatusAndPage({
            businessStatus: "blocked",
            page,
          }).unwrap();

          setFetchedUsers(result);
          setfirstFetchedItems(result.length);
        } else if (userStatus === "Verified Buyer") {
          const page = 1;
          const result = await getBuyerByAccountStatusAndPage({
            accountStatus: "verified",
            page,
          }).unwrap();

          setFetchedUsers(result);
          setfirstFetchedItems(result.length);
        } else if (userStatus === "Blocked Buyer") {
          const page = 1;
          const result = await getBuyerByAccountStatusAndPage({
            accountStatus: "blocked",
            page,
          }).unwrap();

          setFetchedUsers(result);
          setfirstFetchedItems(result.length);
        }

        setIsLoading(false);
        // Update state with the fetched data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Invoke the fetchData function when the component mounts or when 'Id' changes
  }, []); // Include 'Id' as a dependency to refetch data when 'Id' changes
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = async (newPage) => {
    try {
      setIsLoading(true);
      if (userStatus === "Pending Seller") {
        const result = await getSellerByBusinessStatusAndPage({
          businessStatus: "pending",
          page: newPage,
        }).unwrap();

        setFetchedUsers(result);
      } else if (userStatus === "Verified Seller") {
        
        const result = await getSellerByBusinessStatusAndPage({
          businessStatus: "verified",
          page: newPage,
        }).unwrap();


        setFetchedUsers(result);
      } else if (userStatus === "Blocked Seller") {
        const result = await getSellerByBusinessStatusAndPage({
          businessStatus: "blocked",
          page: newPage,
        }).unwrap();

        setFetchedUsers(result);
      } else if (userStatus === "Verified Buyer") {
        const result = await getBuyerByAccountStatusAndPage({
          accountStatus: "verified",
          page: newPage,
        }).unwrap();

        setFetchedUsers(result);
      } else if (userStatus === "Blocked Buyer") {
        const result = await getBuyerByAccountStatusAndPage({
          accountStatus: "blocked",
          page: newPage,
        }).unwrap();

        setFetchedUsers(result);
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div
          className={` text-gray-800 px-6 py-4 bg-gray-100 w-full`}
          style={{ overflowY: "auto", height: "calc(100vh - 56px)" }}
        >
          <div className="w-full bg-white h-16 flex items-center px-2 rounded-md  text-lg text-opacity-70">
            {userStatus.charAt(0).toUpperCase() + userStatus.slice(1)}
            {/* {userStatus === 'Verified Buyer' ||
              userStatus === 'Blocked Buyer' ? 'Buyers' : 'Sellers'} */}
          </div>
          {fetchedUsers.length !== 0 &&
            fetchedUsers.map((user, index) => (
              <UserDetail key={index} user={user} accountStatus={userStatus} />
            ))}
          {firstFetchedItems > 32 && (
            <div className="mt-4 flex items-center">
              <button
                onClick={() => {
                  setCurrentPage(currentPage - 1);

                  handlePageChange(currentPage - 1);
                }}
                className="px-4 py-1 bg-orange-600 hover:bg-orange-500 text-white rounded-md mr-2"
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <button
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                  handlePageChange(currentPage + 1);
                }}
                className="px-4 py-1 bg-orange-600 hover:bg-orange-500 text-white rounded-md"
              >
                Next
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default UserStatusDetailsPage;
