import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGetSliderAndSideItemsMutation } from "../features/auth/authApiSlice";
import { WelcomeLoader } from "../contexts/LoaderContext";

function NewItems() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [getSliderAndSideItems, { isSliderLoading }] =
    useGetSliderAndSideItemsMutation();
  const [fetchedSLiderSideItems, setFetchedSliderSideItems] = useState([]);
  const { setIsNewItemsLoading } = useContext(WelcomeLoader);

  useEffect(() => {
    const fetchData = async () => {
      const sliderSideItems = await getSliderAndSideItems();
      //console.log(sliderSideItems);
      setFetchedSliderSideItems(sliderSideItems.data.sliderSideItems);
      setIsNewItemsLoading(false);
    };
    fetchData();
  }, [getSliderAndSideItems]);

  return (
    <>
      <div className="grid grid-rows-2  grid-flow-col gap-2">
        {fetchedSLiderSideItems &&
          fetchedSLiderSideItems.map((slide, index) => (
            <div key={index}>
              <div className="h-full flex flex-col justify-center rounded-[3px] border border-stone-300 border-opacity-70 md:w-96 md:ml-10 md:px-6 hover:border-purple-400 pb-2">
                <div className=" grid grid-cols-2 gap-2 justify-items-stretch items-center ">
                  <div className="pl-2 md:pl-0 flex flex-col justify-items-center">
                    <div className="text-black text-[30px] font-semibold font-['Poppins'] leading-7 tracking-tight">
                      {`${slide.discountAmount}% ${t("off")}`}
                    </div>
                    <div className="text-black text-[22px] font-normal font-['Poppins'] leading-7 tracking-tight pt-2 pb-3">
                      {slide.itemName}
                    </div>
                    <button
                      onClick={() => {
                        navigate(`/productdetail?productId=${slide._id}`);
                      }}
                      className="text-white text-[17px] font-semibold font-['Poppins'] min-w-[149px] min-h-[44px] px-[30px] py-2 bg-purple-500 hover:bg-purple-400 rounded-[5px] border border-white max-h-max max-w-max text-center inline-flex"
                    >
                      {t("getitnow")}
                    </button>
                  </div>
                  {/* <div className=""> */}
                  <img
                    className="max-h-40 w-auto pl-6 justify-items-end"
                    src={slide.avatarUrl[0]}
                    style={{ objectFit: "contain" }}
                    alt="Samsung Galaxy"
                  />
                  {/* </div> */}
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}

export default NewItems;
