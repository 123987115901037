import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function Imprint() {
  return (
    <div>
      <Navbar />
      <div className="font-sans text-gray-800 bg-gray-100 p-4 sm:p-8 md:p-12 lg:p-16">
        <div className="container mx-auto my-8 p-4">
          <section>
            <h3 class="text-lg font-bold mb-2">Contact Information</h3>
            <p class="mb-2">
              Adresse: Emil Barth Straße 99, 40595 Düsseldorf, Deutschland
            </p>
            <p class="mb-2">Telefon: +4915112359297</p>
            <p class="mb-2">Email: info@saletoeurope.com</p>
            <p class="mb-2">Firmenregistrierung / Düsseldorf/Deutschland</p>
            <p class="mb-2">
              Für den Kundenservice im Falle von Rückerstattungen/Rückbuchungen
              für die Zahlung über Paypal und Sofort:
            </p>
            <p>
              Sale to Europe <br /> info@saletoeurope.com
            </p>
          </section>

          <section>
            <h3 class="text-lg font-bold mb-2">
              Online Dispute Resolution (ODR)
            </h3>
            <p class="mb-2">
              Die Europäische Kommission stellt eine Plattform zur
              Online-Streitbeilegung (OS) bereit, die Sie{" "}
              <a
                href="https://ec.europa.eu/consumers/odr/"
                class="text-blue-500 underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                hier finden
              </a>
              .
            </p>
            <p class="mb-2">
              Zur Teilnahme an einem Streitbeilegungsverfahren vor einer
              Verbraucherschlichtungsstelle sind wir nicht bereit und
              verpflichtet.
            </p>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Imprint;
