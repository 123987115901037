import { FaRegMoneyBillAlt, FaShippingFast } from "react-icons/fa";
import { MdOutlineSupportAgent } from "react-icons/md";
import { RiSecurePaymentFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";

const Advert = () => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-center">
      <div className="flex gap-16 my-20 flex-wrap">
        <div className="flex items-center gap-2 w-80 md:w-auto">
          <div
            className="h-14 w-14   flex justify-center items-center rounded-full "
            style={{ borderWidth: "3px", borderColor: "red" }}
          >
            <FaShippingFast size={30} style={{ color: "red" }} />
          </div>
          <div>
            <p className="font-semibold text-lg">{t("Advert.Fast_Shipping")}</p>
            <p className="opacity-70 text-base">{t("Advert.Fast")}</p>
          </div>
        </div>
        <div className="flex items-center gap-2 w-80 md:w-auto">
          <div
            className="h-14 w-14   flex justify-center items-center rounded-full "
            style={{ borderWidth: "3px", borderColor: "red" }}
          >
            <FaRegMoneyBillAlt size={30} style={{ color: "red" }} />
          </div>
          <div>
            <p className="font-semibold text-lg">{t("Advert.Money_Back")}</p>
            <p className="opacity-70 text-base">{t("Advert.Guaranty")}</p>
          </div>
        </div>
        <div className="flex items-center gap-2 w-80 md:w-auto">
          <div
            className="h-14 w-14   flex justify-center items-center rounded-full "
            style={{ borderWidth: "3px", borderColor: "red" }}
          >
            <MdOutlineSupportAgent size={30} style={{ color: "red" }} />
          </div>
          <div>
            <p className="font-semibold text-lg">{t("Advert.Support")}</p>
            <p className="opacity-70 text-base">{t("Advert.Technical")}</p>
          </div>
        </div>
        <div className="flex items-center gap-2 w-80 md:w-auto">
          <div
            className="h-14 w-14   flex justify-center items-center rounded-full "
            style={{ borderWidth: "3px", borderColor: "red" }}
          >
            <RiSecurePaymentFill size={30} style={{ color: "red" }} />
          </div>
          <div>
            <p className="font-semibold text-lg">{t("Advert.Secure")}</p>
            <p className="opacity-70 text-base">{t("Advert.Cards")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advert;
