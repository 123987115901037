import React from "react";
import CatagoryCard from "./CatagoryCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useMediaQuery } from "react-responsive";

import watch from "../assets/images/image_2023-12-03_19-49-46.png";
import spareParts from "../assets/images/spare.png";
import beauty from "../assets/images/beauty.jpg";
import item4 from "../assets/images/shoes.jpg";
import laptop from "../assets/images/laptop.webp";
import sports from "../assets/images/Sport_balls.svg.png";
import { useTranslation } from "react-i18next";
import CustomArrow from "../assets/svgs/CustomArrow";

const PopularCategories = () => {
  const { t } = useTranslation();

  const Catagories = [
    { name: t("watches"), image: watch },
    { name: t("spareParts"), image: spareParts },
    { name: t("wabeautytches"), image: beauty },
    { name: t("Shoe"), image: item4 },
    { name: t("laptop"), image: laptop },
    { name: t("sports"), image: sports },
    { name: t("watches"), image: watch },
    { name: t("sports"), image: sports },
  ];

  const responsive = {
    SXL: {
      breakpoint: { max: 4000, min: 1200 },
      items: 7,
    },
    SL: {
      breakpoint: { max: 1200, min: 991 },
      items: 6,
    },
    L: {
      breakpoint: { max: 991, min: 813 },
      items: 5,
    },
    SM: {
      breakpoint: { max: 813, min: 768 },
      items: 4,
    },
    M: {
      breakpoint: { max: 768, min: 601 },
      items: 4,
    },
    T: {
      breakpoint: { max: 601, min: 520 },
      items: 3,
    },
    S: {
      breakpoint: { max: 462, min: 0 },
      items: 2,
    },
  };
  const isLargeScreen = useMediaQuery({ minWidth: 1200 });

  const settings = {
    infinite: false,
    arrows: isLargeScreen,
    customLeftArrow: <CustomArrow direction="left" />,
    customRightArrow: <CustomArrow direction="right" />,
    responsive: responsive,
    position: "static",
  };
  return (
    <div className="popular_categories">
      <div className="font-bold text-2xl  mb-4 border-b border-solid border-gray-500 mt-10 pb-2">
        {t("popularcatagories")}
      </div>
      <div className="relative flex flex-col pb-8 items-center">
        <div className="w-full">
          <Carousel {...settings}>
            {Catagories.map((item, index) => (
              <CatagoryCard title={item.name} image={item.image} key={index} />
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default PopularCategories;
