import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const CookiePolicy = () => {
  return (
    <div>
      <Navbar />
      <div className="font-sans text-gray-800 bg-gray-100 p-4 sm:p-8 md:p-12 lg:p-16">
        <div className="container mx-auto my-8 p-4">
          <h2 className="text-2xl font-bold mb-4">Cookie-Richtlinien</h2>

          <p>
            Diese Website verwendet Cookies, um die Benutzererfahrung zu
            verbessern und den effizienten Betrieb der Website zu gewährleisten.
          </p>

          <p>
            Die Cookie-Benachrichtigung ist Teil der Datenschutzerklärung.
            Weitere Informationen zum Schutz unseres Unternehmens und der
            Besucherinformationen finden Sie in unserer Datenschutzerklärung.
          </p>

          <p>
            Damit wir Ihnen maßgeschneiderte und auf Ihre Bedürfnisse
            zugeschnittene Dienstleistungen anbieten können, müssen Ihre
            Informationen über die Nutzung dieser Website gespeichert und
            gespeichert werden. Alle diese Operationen werden über Cookies
            durchgeführt, die als kleine Textdateien bezeichnet werden. Cookies
            auf dieser Website speichern eine kleine Menge an Informationen und
            werden über den Server auf Ihrem Computer oder anderen Geräten
            installiert. Jedes Mal, wenn Sie diese Website besuchen, sendet Ihr
            Internetbrowser Cookies an diese Website; so erkennt die Website sie
            und erinnert sich an Ihre Informationen wie Benutzereinstellungen.
            Weitere Informationen zu Cookies und wie sie funktionieren{" "}
            <a
              href="http://www.aboutcookies.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              hier
            </a>{" "}
            können Sie Ihre Website besuchen.
          </p>

          <p>
            Jedes Mal, wenn Sie diese Website nutzen, können Informationen über
            Cookies und andere Technologien gesammelt werden. Durch die Nutzung
            dieser Website können Sie die in der Cookie-Benachrichtigung
            beschriebene Verwendung von Cookies sowie deloitte.com Sie stimmen
            auch der Verwendung von Cookies zu, die in den Cookie-Hinweisen auf
            dem Land, der Region oder anderen Service-basierten Websites
            beschrieben sind, die Sie besuchen können.
          </p>

          <h3 className="text-lg font-bold my-4">
            Arten und Gründe der verwendeten Cookies
          </h3>

          <p>
            Einige der verwendeten Cookies sind notwendig, damit Sie unsere
            Website nutzen und bestimmte Funktionen nutzen können, wie z. B. den
            Zugriff auf sichere Inhalte, die registrierten Benutzern zur
            Verfügung gestellt werden.
          </p>

          <p>
            Funktionale Cookies werden verwendet, um Präferenzen und
            Informationen, wie z. B. die Sprach- oder Regionsauswahl, die sie
            bei der Nutzung der Website vornehmen, aufzuzeichnen und Ihnen einen
            geeigneteren Service bieten zu können. Diese Informationen sind in
            der Regel anonym und werden nicht für andere Zwecke verwendet.
          </p>

          <p>
            Um die Effizienz unserer Inhalte und die Interessen unserer Nutzer
            zu verstehen und die Funktionsweise unserer Website zu verbessern,
            nutzen wir oder unsere Dienstleister auch analytische Dienste.
            Darüber hinaus wird der ① - Zeiger oder der Follow-up-Cursor
            verwendet, um die Anzahl der Besucher zu messen, und
            Performance-Cookies werden auf unserer Website verwendet, um die
            Anzahl der einzelnen Benutzer auf der Website und die Häufigkeit des
            Zugriffs von Benutzern auf die Website zu überwachen. Diese
            Informationen werden ausschließlich zu statistischen Zwecken
            verwendet und nicht zur individuellen Identifizierung der Nutzer.
            Wenn Sie sich jedoch auf unserer Website registrieren, können diese
            Informationen mit Informationen und Cookies aus den Analysediensten
            von ① kombiniert werden, um Ihre Nutzung der Website zu analysieren.
          </p>

          <p>
            Auf dieser Website werden keine Werbe-Cookies verwendet, die
            bestimmte Anzeigen an Benutzer weiterleiten.
          </p>

          <p>
            Kontaktieren Sie uns für weitere Informationen zu den verwendeten
            Cookies.
          </p>
          <h3 className="text-lg font-bold my-4">
            Wie werden Cookies kontrolliert?
          </h3>

          <p>
            Durch den Zugriff auf diese Website erklären Sie sich damit
            einverstanden, dass Cookies auf Ihrem Computer oder den oben
            beschriebenen Geräten platziert werden können. Sie können Cookies
            jedoch auf verschiedene Arten steuern und bearbeiten. Wenn Sie
            Cookies entfernen oder blockieren, weisen wir Sie darauf hin, dass
            dies Ihre Nutzung der Website beeinträchtigen kann und dass sie
            möglicherweise Schwierigkeiten beim Zugriff auf bestimmte Teile der
            Website haben.
          </p>

          <h4 className="text-lg font-bold my-2">Browsersteuerung</h4>

          <p>
            Die meisten Browser bieten die Möglichkeit, Cookies auf Ihrem
            Computer anzuzeigen, Cookies einzeln zu löschen oder Cookies von
            bestimmten Websites oder von allen Websites zu blockieren. Wir
            erinnern Sie daran, dass, wenn Sie alle Cookies löschen, alle
            Einstellungen, die sie gemacht haben - einschließlich der
            Möglichkeit, Cookies zu verwenden - gelöscht werden. Weitere
            Informationen darüber, wie Sie Cookies filtern oder blockieren
            können, indem Sie die Browsereinstellungen ändern{" "}
            <a
              href="http://www.aboutcookies.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              hier
            </a>{" "}
            oder{" "}
            <a
              href="http://www.cookiecentral.com/faq"
              target="_blank"
              rel="noopener noreferrer"
            >
              siehe Link
            </a>
            .
          </p>

          <h4 className="text-lg font-bold my-2">
            Analytische Cookie-Einstellungen
          </h4>

          <p>
            Durch eine Änderung Ihrer Einstellungen können Sie verhindern, dass
            ihre anonymen Suchaktivitäten auf Websites durch analytische Cookies
            gespeichert werden. Für weitere Informationen zu
            Datenschutzrichtlinien und Cookie-Einstellungen können Sie auf die
            Links unten klicken:
          </p>

          <ul className="list-disc pl-6">
            <li>
              <a
                href="http://www.adobe.com/privacy/opt-out.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Adobe
              </a>
            </li>
            <li>
              <a
                href="http://www.google.com/analytics/learn/privacy.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Analytics
              </a>
            </li>
          </ul>

          <h4 className="text-lg font-bold my-2">
            Lokale freigegebene Objekte (LSOs) oder Flash-Cookie-Einstellungen
          </h4>

          <p>
            Lokale freigegebene Objekte oder flash-Cookies sind wie alle anderen
            Cookies, können aber auch andere Arten von Informationen speichern.
            Die oben genannten Änderungen an der Einstellung dieser Cookies
            können nicht vorgenommen werden. In einigen Teilen dieser Website
            werden solche Cookies verwendet, um Benutzereinstellungen in Bezug
            auf media player-Funktionen zu speichern. Diese Cookies müssen auch
            verwendet werden, damit einige Videos ordnungsgemäß funktionieren.
            Sie können diese Cookies manuell bearbeiten, indem Sie die{" "}
            <a
              href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Adobe-Website
            </a>{" "}
            besuchen.
          </p>
          <h3 className="text-lg font-bold my-4">Soziale Tasten</h3>

          <p>
            "Social Keys" werden verwendet, um Internetseiten von Benutzern zu
            teilen oder zu markieren. Dies sind die Schlüssel für
            Social-Media-Websites Dritter, und diese Social-Media-Websites
            können Informationen über Ihre Aktivitäten im Internet,
            einschließlich dieser Website, aufzeichnen. Lesen Sie die
            Nutzungsbedingungen und Datenschutzrichtlinien dieser Websites, um
            zu erfahren, wie diese Websites Informationen über sie verwenden,
            wie Sie diese verhindern oder löschen können.
          </p>

          <h3 className="text-lg font-bold my-4">
            Auslagerungsdienste von lloweb
          </h3>

          <p>
            In einigen Fällen werden die Seiteninhalte auf dieser Website von
            den Auslagerungsdiensten von ① ausgenutzt. (Z. B. Bilder und Videos
            ansehen oder eine Umfrage durchführen). Wie bei den Social Keys
            können diese Websites oder ausgelagerten Arbeitsbereiche nicht daran
            gehindert werden, Informationen über die von Ihnen verwendeten
            Inhalte zu sammeln/zu speichern.
          </p>

          <h3 className="text-lg font-bold my-4">E-Mail-Kommunikation</h3>

          <p>
            Wir können Tracking-Technologien verwenden, um unsere Kommunikation
            interessanter und funktionaler zu gestalten, indem wir Informationen
            über bestimmte E-Mails erhalten, die wir an Sie senden, die gelesen,
            geöffnet oder an jemand anderen weitergeleitet wurden. Da wir die
            Tracking-Funktion in den von uns gesendeten E-Mails nicht
            deaktivieren können, müssen Sie Ihre Registrierung löschen, wenn sie
            nicht möchten, dass wir Informationen erhalten, dass die an Sie
            gesendeten Inhalte gelesen, geöffnet oder an jemand anderen
            weitergeleitet wurden. Sie können jederzeit Ihre
            Kontakteinstellungen im Abschnitt Kontakt aktualisieren oder Ihre
            Mitgliedschaft kündigen, indem Sie den Anweisungen in den E-Mails
            folgen, die Sie von uns erhalten.
          </p>

          <p>
            Unser Unternehmen kann diese Cookie-Benachrichtigung bei Bedarf
            ändern. Wenn die Benachrichtigung geändert wurde, wird das
            Aktualisierungsdatum oben auf der Seite von uns geändert, und die
            aktualisierte oder geänderte Cookie-Benachrichtigung wird ab dem
            angegebenen Datum wirksam. Wir empfehlen Ihnen, die
            Cookie-Benachrichtigung zu bestimmten Zeiten zu überprüfen, um sich
            über die Verwendung von Cookies zu informieren.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CookiePolicy;
