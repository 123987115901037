import { useEffect, useState, useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetItemsWithDiscountsMutation } from '../../features/auth/authApiSlice';
import AdminDiscountCard from './adminDiscountCard';


function DiscountedItems() {
  const [fetchedItems, setFetchedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const [getItemsWithDiscounts, { isSellerLoading }] =
    useGetItemsWithDiscountsMutation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const result = await getItemsWithDiscounts().unwrap();

        setFetchedItems(result);

        setIsLoading(false);
        // Update state with the fetched data
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Invoke the fetchData function when the component mounts or when 'Id' changes
  }, []);

  return (
    <>
      <div
        className={` text-gray-800 px-6 py-4 bg-gray-100 w-full`}
        style={{ overflowY: 'auto', height: 'calc(100vh - 56px)' }}
      >
        <div className="w-full bg-white h-16 flex items-center px-2 rounded-md  text-lg text-opacity-70">
          {t('discountedItems')}
        </div>
        <div className="flex md:flex-wrap gap-4 scrollpart">
            {fetchedItems.map((item, index) => (
              <AdminDiscountCard
                key={index}
                item={item}
                // selectedSidebarItem={selectedSidebarItem}
                // onSelectSidebarItem={onSelectSidebarItem}
                // handlePageChange={handlePageChange}
                // page={currentPage}
              />
            ))}
          </div>

      </div>
    </>
  );
}

export default DiscountedItems;
