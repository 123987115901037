import React from 'react';

const CustomArrow = ({ direction, onClick }) => (
  <div
    className={`absolute arrow-btn text-center p-2 cursor-pointer border-2 border-purple-400  rounded-full flex items-center ${direction === 'left' ? '-left-8' : '-right-0'}`}
    onClick={onClick}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="3"
      stroke="currentColor"
      className="w-6 h-6 text-purple-400"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d={direction === 'left' ? 'M15.75 19.5L8.25 12l7.5-7.5' : 'M8.25 4.5l7.5 7.5-7.5 7.5'}
      />
    </svg>
  </div>
);

export default CustomArrow;
