import { useRef, useEffect } from 'react';

import { useState } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import Select from 'react-select';
import { ImUpload3 } from 'react-icons/im';
import {
  useAddItemMutation,
  useGetCatagoriesByTypeMutation,
  useGetItemByIdMutation,
  useUpdateItemMutation,
} from '../../features/auth/authApiSlice';
import { useSelector } from 'react-redux';
import {
  selectCurrentProductIdTobeEdited,
  selectCurrentUserId,
} from '../../features/auth/authSlice';
import { selectCurrentName } from '../../features/auth/authSlice';
import { useTranslation } from 'react-i18next';

function EditProduct({ item }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [colorImages, setColorImages] = useState([]);

  const handleColorImageChange = (index, event) => {
    const newColorImages = [...colorImages];
    newColorImages.push(event.target.files[0]);
    setColorImages(newColorImages);
  };

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isMaximum, setIsMaximum] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [getItemById, { isLoading: getItemIsLoading }] =
    useGetItemByIdMutation();
  const [updateItem, { isLoading: updateItemIsLoading }] =
    useUpdateItemMutation();
  const [selectedColor, setSelectedColor] = useState('');
  const [previousAvatar, setPreviousAvatar] = useState([]);

  const [colorChoices, setColorChoices] = useState([]);
  const [message, setMessage] = useState(null); // Add message state

  const [productFetched, setProductFetched] = useState(null);
  const productId = useSelector(selectCurrentProductIdTobeEdited);

  const SellerId = useSelector(selectCurrentUserId);
  const companyName = useSelector(selectCurrentName);
  const [itemName, setItemName] = useState('');
  const [mainCatagory, setMainCatagory] = useState(null);
  const [subCatagory, setSubCatagory] = useState(null);
  const [fetchedSubCatagory, setfetchedSubCatagory] = useState(null);
  const [childCatagory, setChildCatagory] = useState(null);
  const [fetchedChildCatagory, setfetchedChildCatagory] = useState(null);
  const [dimention, setDimention] = useState(null);

  const [isFetchingCatagories, setIsFetchingCatagories] = useState(false);
  const [getCatagoriesByType, { isLoading: getCatagoriesByTypeIsLoading }] =
    useGetCatagoriesByTypeMutation();
  const transformCategories = async (categories) => {
    const mainCategoryOptions = categories
      .filter((category) => category.type === 'mainCatagory')
      .map((category) => ({ label: category.name, value: category._id }));


    const subCategoryOptions = {};
    const childCategoryOptions = {};

    categories.forEach((category) => {
      if (category.type === 'subCatagory') {
        subCategoryOptions[category.parent] =
          subCategoryOptions[category.parent] || [];
        subCategoryOptions[category.parent].push({
          label: category.name,
          value: category._id,
        });
      } else if (category.type === 'childCatagory') {
        childCategoryOptions[category.parent] =
          childCategoryOptions[category.parent] || [];
        childCategoryOptions[category.parent].push({
          label: category.name,
          value: category._id,
        });
      }
    });

    return { mainCategoryOptions, subCategoryOptions, childCategoryOptions };
  };

  const [mainCatagoryOptions, setmainCatagoryOptions] = useState(null);
  const [subcatagoryOptions, setsubcatagoryOptions] = useState(null);
  const [childcatagoryoptions, setchildcatagoryoptions] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const result = await getCatagoriesByType().unwrap();
        

        const transformedCategories = await transformCategories(result);
        const mainCatagoryOptions = transformedCategories.mainCategoryOptions;
        const subcatagoryOptions = transformedCategories.subCategoryOptions;
        const childcatagoryoptions = transformedCategories.childCategoryOptions;
        setchildcatagoryoptions(childcatagoryoptions);
        setmainCatagoryOptions(mainCatagoryOptions);
        setsubcatagoryOptions(subcatagoryOptions);

        const item = await getItemById({
          itemId: productId,
        });
        

        setItemName(item.data.itemName);

        setPrice(item.data.price[0]);
        setWeight(item.data.weight);

        setColorChoices(item.data.color);
        setPreviousAvatar(item.data.avatarUrl);

        
        const selectedOptionMain =
          mainCatagoryOptions && mainCatagoryOptions.length >= 0
            ? mainCatagoryOptions.find(
                (option) => option.label === item.data.mainCatagory,
              )
            : null;
        const selectedOptionSub =
          subcatagoryOptions && subcatagoryOptions.length >= 0
            ? subcatagoryOptions[item.data.mainCatagory].find(
                (option) => option.label === item.data.subCatagory,
              )
            : null;

        const selectedOptionChild =
          childcatagoryoptions && childcatagoryoptions.length >= 0
            ? childcatagoryoptions[item.data.subCatagory].find(
                (option) => option.label === item.data.childCatagory,
              )
            : null;
        const selectedItemStatusOption = itemStatusOptions.find(
          (option) => option.value === item.data.itemStatus,
        );
        const selectedDimentionOption = dimentionOptions.find(
          (option) => option.value === item.data.dimentions,
        );

        setDimention(selectedDimentionOption ? selectedDimentionOption : null);
        setItemStatus(
          selectedItemStatusOption ? selectedItemStatusOption : null,
        );

        setMainCatagory(selectedOptionMain ? selectedOptionMain : null);
        setSubCatagory(selectedOptionSub ? selectedOptionSub : null);
        setfetchedSubCatagory(item.data.subCatagory);
        setChildCatagory(selectedOptionChild ? selectedOptionChild : null);
        setfetchedChildCatagory(item.data.childCatagory);
        setDescription(item.data.description);

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Invoke the fetchData function when the component mounts or when 'Id' changes
  }, [SellerId]);

  const handleFileSelection = (files) => {
    // Calculate the total number of selected files
    const totalSelectedFiles = selectedFiles.length + files.length;

    if (totalSelectedFiles <= 6) {
      setIsMaximum(false);

      setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
      if (totalSelectedFiles == 6) {
        setIsMaximum(true);
      }
    } else {
      // Display an error message or take appropriate action if the limit is exceeded
      console.error('You can only upload up to 6 files.');
    }
  };

  const handleRemoveFile = (index, event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsMaximum(false);
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1); // Remove the file at the specified index
    setSelectedFiles(updatedFiles);
  };

  const handleInputChange = (e) => {
    const files = e.target.files;
    handleFileSelection(files);
  };

  const dimentionOptions = [
    { label: '3 X 3', value: '3 X 3' },
    { label: '2 X 2', value: '2 X 2' },
    { label: '1 X 2', value: '1 X 2' },
    { label: '3 X 2', value: '3 X 2' },
    { label: '4 X 2', value: '4 X 2' },
    { label: '5 X 2', value: '5 X 2' },

    // Add more main category options as needed
  ];
  const colorOptions = [
    { label: 'Red', value: 'red' },
    { label: 'Blue', value: 'blue' },
    { label: 'Green', value: 'green' },
    { label: 'White', value: 'white' },
    { label: 'Black', value: 'black' },
    { label: 'Purple', value: 'purple' },
    { label: 'Yellow', value: 'yellow' },
    { label: 'Orange', value: 'orange' },
    { label: 'Pink', value: 'pink' },
    { label: 'Brown', value: 'brown' },
    { label: 'Gray', value: 'gray' },
    { label: 'Cyan', value: 'cyan' },
    { label: 'Magenta', value: 'magenta' },
    { label: 'Turquoise', value: 'turquoise' },
    { label: 'Lavender', value: 'lavender' },
    { label: 'Teal', value: 'teal' },
    { label: 'Olive', value: 'olive' },
    { label: 'Indigo', value: 'indigo' },
    { label: 'Violet', value: 'violet' },
    { label: 'Beige', value: 'beige' },
    { label: 'Maroon', value: 'maroon' },
    { label: 'Navy', value: 'navy' },
    { label: 'Silver', value: 'silver' },
    { label: 'Gold', value: 'gold' },
    // Add more colors as needed
    { label: 'Other', value: 'other' },
  ];

  const itemStatusOptions = [
    { value: 'new_no_defects', label: 'New with No defects' },
    { value: 'new_with_defects', label: 'New with defects' },
    { value: 'used', label: 'Used' },
  ];

  const [color, setColor] = useState(null);
  const [customColor, setCustomColor] = useState('');
  const [showCustomColorInput, setShowCustomColorInput] = useState(false);

  const [price, setPrice] = useState('');
  const [currency, setCurrency] = useState('USD'); // Set the initial currency value to USD
  const [quantity, setQuantity] = useState('');
  const [weight, setWeight] = useState('');
  const [itemStatus, setItemStatus] = useState(null);
  const [description, setDescription] = useState('');

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragOver(false);

    const droppedFiles = Array.from(e.dataTransfer.files);
    handleFileSelection(droppedFiles);
  };
  const [dragOver, setDragOver] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  const handleItemNameChange = (event) => {
    setItemName(event.target.value);
  };
  const [mainCatatagoryChanged, setMainCatagoryChanged] = useState(false);

  const handleMainCatagoryChange = (selectedOption) => {
    setMainCatagoryChanged(true);
    setMainCatagory(selectedOption);
    setSubCatagory(null); // Reset subcatagory when main category changes
  };

  const handleDimentionChange = (selectedOption) => {
    setDimention(selectedOption);
  };
  const handleColorChange = (selectedOption) => {
    if (selectedOption.value === 'other') {
      setColor(selectedOption);
      setSelectedColor(selectedOption);
      setShowCustomColorInput(true);
    } else {
      setColor(selectedOption);
      setSelectedColor(selectedOption);

      setShowCustomColorInput(false);
    }
  };

  const addColorChoice = (e) => {
    e.preventDefault();
    // Create a string representation of the color choice with quantity
    const colorChoiceString = `${selectedColor.value} (${quantity})`;

    // Push it into the colorChoices array
    setColorChoices([...colorChoices, colorChoiceString]);

    // Clear the selected color and quantity fields
    setSelectedColor('');
    setColor('');
  };

  const removeColorChoice = (index) => {
    // Filter the colorChoices array to remove the chosen color choice
    const updatedColorChoices = colorChoices.filter((_, i) => i !== index);
    setColorChoices(updatedColorChoices);

    const updatedColorImages = colorImages.filter((_, i) => i !== index);
    setColorImages(updatedColorImages);
  };

  const updateColorQuantity = (index, quantity) => {
    // Update the quantity for a specific color choice
    const updatedColorChoices = [...colorChoices];
    updatedColorChoices[index].quantity = quantity;
    setColorChoices(updatedColorChoices);
  };

  // const handleColorQuantityChange = (colorValue, quantity) => {
  //   setColorQuantities({ ...colorQuantities, [colorValue]: quantity });
  // };
  const handleCustomColorChange = (event) => {
    const customColorValue = event.target.value;
    setCustomColor(customColorValue);

    // When the user specifies a custom color, set it as the selected color
    // You can customize the label and value as needed
    setColor({
      label: customColorValue,
      value: customColorValue.toLowerCase().replace(/\s/g, '_'),
    });
    setSelectedColor({
      label: customColorValue,
      value: customColorValue.toLowerCase().replace(/\s/g, '_'),
    });
  };
  const handleItemStatuschange = (selectedOption) => {
    setItemStatus(selectedOption);
  };

  const handleSubCatagoryChange = (selectedOption) => {
    setSubCatagory(selectedOption);
    setChildCatagory(null);
  };
  const handleChildCatagoryChange = (selectedOption) => {
    setChildCatagory(selectedOption);
  };

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const handleWeightChange = (event) => {
    setWeight(event.target.value);
  };

  const getImageUrl = async (file) => {
    try {
      const formData = new FormData();
      formData.append('key', process.env.REACT_APP_API_KEY);
      formData.append('image', file);
      formData.append('name', file.name);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const response = await axios.post(
        'https://api.imgbb.com/1/upload',
        formData,
        config,
      );
      const avatarUrl = response.data.data.url;

      return avatarUrl;
    } catch (error) {
      // Handle errors if the request fails
      console.error(error);
      throw error;
    }
  };
  const handleAddAnother = async (event) => {
    event.preventDefault();
    setMessage(null);
    setSelectedFiles([]);
    setChildCatagory(null);
    setSubCatagory(null);
    setMainCatagory(null);
    setDescription('');
    setSelectedColor(null);
    setColor('');
    setColorChoices([]);
    setDimention(null);
    setItemStatus(null);
    setWeight('');
    setPrice('');
    setItemName('');
    setQuantity('');
  };

  const successMessageRef = useRef(null);

  useEffect(() => {
    if (message && message.type === 'success' && successMessageRef.current) {
      successMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [message]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    let totalQuantity = colorChoices.reduce((total, choice) => {
      const match = choice.match(/\((\d+)\)/);
      const quantity = match ? parseInt(match[1]) : 0;
      return total + quantity;
    }, 0);

    let colorChoiceString;

    if (colorChoices.length == 0) {
      
      totalQuantity = quantity;
      colorChoiceString = [`${selectedColor.value} (${quantity})`];

      // Push it into the colorChoices array
    }

    // Upload all selected images and wait for all promises to resolve
    const uploadPromises = selectedFiles.map((file) => getImageUrl(file));
    const uploadPromisesColorImgs = colorImages.map((file) =>
      getImageUrl(file),
    );

    try {
      const priceWithCurrency = price + currency;
      let images = await Promise.all(uploadPromises);
      const colorImgs = await Promise.all(uploadPromisesColorImgs);

      if (selectedFiles.length === 0) {
        images = previousAvatar;
        
      }
      let colorList;
      let colorImagelist;

      if (colorChoices.length === 0) {
        colorList = colorChoiceString;
      } else {
        colorList = colorChoices;
        colorImagelist = colorImgs;
      }

      if (subCatagory) {
        if (colorImagelist) {
          const userData = await updateItem({
            id: productId,
            itemName,
            companyName: companyName,
            companyId: SellerId,
            mainCatagory: mainCatagory.label,
            subCatagory: subCatagory.label,
            childCatagory: childCatagory.label,
            price: parseInt(price),
            currency: currency,
            avatarUrl: images,
            quantity: parseInt(totalQuantity),
            itemStatus: itemStatus.value,
            description,
            weight: parseInt(weight),
            dimentions: dimention.value,
            color: colorList,
            colorImgList: colorImagelist,
          }).unwrap();
        } else {
          const userData = await updateItem({
            id: productId,
            itemName,
            companyName: companyName,
            companyId: SellerId,
            mainCatagory: mainCatagory.label,
            subCatagory: subCatagory.label,
            childCatagory: childCatagory.label,
            price: parseInt(price),
            currency: currency,
            avatarUrl: images,
            quantity: parseInt(totalQuantity),
            itemStatus: itemStatus.value,
            description,
            weight: parseInt(weight),
            dimentions: dimention.value,
            color: colorList,
          }).unwrap();
        }
      } else {
        if (colorImagelist) {
          const userData = await updateItem({
            id: productId,
            itemName,
            companyName: companyName,
            companyId: SellerId,
            mainCatagory: mainCatagory.label,
            subCatagory: fetchedSubCatagory,
            childCatagory: fetchedChildCatagory,
            price: parseInt(price),
            currency: currency,
            avatarUrl: images,
            quantity: parseInt(totalQuantity),
            itemStatus: itemStatus.value,
            description,
            weight: parseInt(weight),
            dimentions: dimention.value,
            color: colorList,
            colorImgList: colorImagelist,
          }).unwrap();
        } else {
          const userData = await updateItem({
            id: productId,
            itemName,
            companyName: companyName,
            companyId: SellerId,
            mainCatagory: mainCatagory.label,
            subCatagory: fetchedSubCatagory,
            childCatagory: fetchedChildCatagory,
            price: parseInt(price),
            currency: currency,
            avatarUrl: images,
            quantity: parseInt(totalQuantity),
            itemStatus: itemStatus.value,
            description,
            weight: parseInt(weight),
            dimentions: dimention.value,
            color: colorList,
          }).unwrap();
        }
      }

      setMessage({ text: 'Product updated successfully!', type: 'success' });

      // After all image uploads are complete, you can perform other actions
    } catch (error) {
      setMessage({
        text: 'Failed to Update the product. Please try again.',
        type: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div
          className={`  px-6 py-4 bg-gray-100 w-full`}
          style={{ overflowY: 'auto', height: 'calc(100vh - 56px)' }}
        >
          <div className="w-full mb-4 bg-white h-16 flex items-center px-2 rounded-md  text-lg text-opacity-70">
            {t('editProduct')}
          </div>
          <form className="" onSubmit={handleSubmit}>
            <div className="flex-col">
              <label
                htmlFor="validationDefault01"
                className="block text-md font-medium leading-6 text-gray-900"
              >
                {t('itemName')}
                <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                value={itemName}
                className="w-full h-10  border-gray-400   
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded-md px-2 mb-4"
                id="validationDefault01"
                required
                onChange={handleItemNameChange}
                placeholder="Item Name"
                style={{ borderWidth: '1px' }}
              />
            </div>
            <div>
              <label
                htmlFor="price"
                className="block text-md font-medium leading-6 text-gray-900"
              >
                {t('price')}
                <span className="text-red-600">*</span>
              </label>
              <div className="relative  border-gray-400">
                <div className="pointer-events-none absolute inset-y-0 left-0  flex items-center pl-3">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                  type="text"
                  name="price"
                  id="price"
                  className="block w-full   rounded-md py-2 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:invalid:border-pink-500 focus:invalid:ring-pink-500 mb-4 sm:text-sm sm:leading-6"
                  placeholder="0.00"
                  value={price}
                  onChange={handlePriceChange}
                  style={{ borderWidth: '1px' }}
                  required
                />
                <div className="absolute inset-y-0 right-0 flex items-center">
                  <label htmlFor="currency" className="sr-only">
                    Currency
                  </label>
                  <select
                    id="currency"
                    name="currency"
                    className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                    value={currency}
                    onChange={handleCurrencyChange}
                  >
                    <option>USD</option>
                    <option>CAD</option>
                    <option>EUR</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="flex-col">
              <label
                htmlFor="validationDefault03"
                className="block text-md font-medium leading-6 text-gray-900"
              >
                {t('weight')}
                <span className="font-light">(In Kg)</span>
              </label>
              <input
                type="text"
                value={weight}
                className="w-full h-10  border-gray-400   
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded-md px-2 mb-4"
                id="validationDefault03"
                onChange={handleWeightChange}
                placeholder={`Weight`}
                style={{ borderWidth: '1px' }}
              />
            </div>

            <div className="w-full flex-col mb-4">
              <div className="block text-md font-medium leading-6 text-gray-900">
                {t('itemStatus')}
                <span className="text-red-600">*</span>
              </div>
              <Select
                options={itemStatusOptions}
                value={itemStatus}
                onChange={handleItemStatuschange}
                required
              />
            </div>
            <div className="w-full flex-col mb-4">
              <div className="block text-md font-medium leading-6 text-gray-900">
                {t('dimension')}
              </div>
              <Select
                options={dimentionOptions}
                value={dimention}
                isSearchable={true}
                onChange={handleDimentionChange}
                required
              />
            </div>
            <div className="w-full flex-col mb-4">
              <div className="block text-md font-medium leading-6 text-gray-900">
                {t('color')}
                <span
                  className="text-black text-sm font-light
              "
                >
                  (({t('chooseOtherColor')}))
                </span>
              </div>
              <Select
                options={colorOptions}
                value={color}
                isSearchable={true}
                onChange={handleColorChange}
              />
              {showCustomColorInput && (
                <div className="flex-col">
                  <label
                    htmlFor="validationDefault05"
                    className="block text-md font-medium leading-6 text-gray-900"
                  >
                    {t('customColor')}
                  </label>
                  <input
                    type="text"
                    className="w-full h-10  border-gray-400   
                focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded-md px-2 mb-4"
                    id="validationDefault05"
                    onChange={handleCustomColorChange}
                    placeholder={`Your Item Color`}
                    style={{ borderWidth: '1px' }}
                  />
                </div>
              )}
            </div>
            <div className="flex-col">
              <label
                htmlFor="validationDefault02"
                className="block text-md font-medium leading-6 text-gray-900"
              >
                {t('quantity')}
                <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                value={quantity}
                className="w-full h-10  border-gray-400   
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded-md px-2 mb-4"
                id="validationDefault02"
                onChange={handleQuantityChange}
                placeholder={`how many Items of ${itemName} do you have?`}
                style={{ borderWidth: '1px' }}
              />
            </div>
            <div>
              {/* Color Dropdown */}

              {/* Add Color Choice Button */}
              <button
                onClick={addColorChoice}
                className="bg-orange-600 text-white rounded-md hover:bg-orange-500 px-4 py-1 "
              >
                {t('additionalColorChoice')}
              </button>
              <ul className="bg-gray-100 p-4 rounded-lg space-y-2">
                {colorChoices.map((choice, index) => (
                  <li
                    key={index}
                    className="flex items-center justify-between bg-white p-2 rounded"
                  >
                    {choice}
                    <div className="flex items-center">
                      <label htmlFor={`colorImage${index}`} className="mr-2">
                        {`Image for ${choice}`}
                      </label>
                      <input
                        type="file"
                        id={`colorImage${index}`}
                        onChange={(e) => handleColorImageChange(index, e)}
                        accept="image/*"
                      />
                    </div>
                    <button
                      onClick={() => removeColorChoice(index)}
                      className="px-2 py-1 ml-2 text-red-600 bg-red-100 rounded hover:bg-red-200 focus:outline-none"
                    >
                      {t('delete')}
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            <div className="w-full flex-col mb-4">
              <div className="block text-md font-medium leading-6 text-gray-900">
                {t('AddProductmainCategory')}
                <span className="text-red-600">*</span>
              </div>
              <Select
                options={mainCatagoryOptions}
                value={mainCatagory}
                isSearchable={true}
                onChange={handleMainCatagoryChange}
                required
              />
            </div>

            {mainCatatagoryChanged && (
              <div className="w-full flex-col mb-4">
                <div className="block text-md font-medium leading-6 text-gray-900">
                  {t('AddProductsubCategory')}
                  {/* <span className="text-red-600">*</span> */}
                </div>
                <Select
                  options={subcatagoryOptions[mainCatagory.value]}
                  value={subCatagory}
                  isSearchable={true}
                  onChange={handleSubCatagoryChange}
                />
              </div>
            )}
            {subCatagory && (
              <div className="w-full flex-col mb-4">
                <div className="block text-md font-medium leading-6 text-gray-900">
                  {t('AddProductchildCategory')}
                  <span className="text-red-600">*</span>
                </div>
                <Select
                  options={childcatagoryoptions[subCatagory.value]}
                  value={childCatagory}
                  isSearchable={true}
                  onChange={handleChildCatagoryChange}
                  required
                />
              </div>
            )}

            <div className="w-full flex-col mb-4">
              <label
                htmlFor="description"
                className="block text-md font-medium leading-6 text-gray-900"
              >
                {t('description')}
              </label>
              <textarea
                id="description"
                name="description"
                className="w-full h-32 border-gray-400 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded-md px-2 py-1"
                value={description}
                onChange={handleDescriptionChange}
                placeholder="Enter a description..."
              />
            </div>
            <div className="w-full flex-col mb-4">
              <div className="block text-md font-medium leading-6 text-gray-900 mb-1">
                {t('uploadPhotos')}{' '}
                <span className="text-black text-sm font-light">
                  ({t('uploadPhotosLimit')})
                </span>
                <span className="text-red-600">*</span>
              </div>
              <div
                className="bg-white w-80 p-4 flex flex-col justify-center items-center rounded-md"
                style={{ width: '620px' }}
              >
                <div
                  style={{ width: '600px', height: '600px' }}
                  className={`border-2 border-dashed rounded-md flex flex-col justify-center items-center ${
                    dragOver ? 'bg-gray-100' : ''
                  }`}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <div className="w-24 h-24 border-2 border-gray-200 rounded-full flex items-center justify-center">
                    <ImUpload3
                      style={{
                        fontSize: '40px',
                        color: 'rgba(0, 0, 255, 0.7)',
                      }}
                    />
                  </div>
                  {!isMaximum && (
                    <p className="block mt-2 text-gray-500 my-5">
                      {t('dragImageHere')}
                    </p>
                  )}
                  <label className="block">
                    {isMaximum ? (
                      <div className="bg-red-500 my-2 text-white p-2 rounded-md text-center">
                        <p className="font-bold">{t('maxPhotosUploaded')}</p>
                      </div>
                    ) : (
                      <div>
                        <span className="sr-only">Choose profile photo</span>
                        <input
                          type="file"
                          className={`block w-full text-sm text-slate-500
        file:mr-4 file:py-2 file:px-4
        file:rounded-full file:border-0
        file:text-sm file:font-semibold
        file:bg-violet-50 file:text-violet-700
        hover:file:bg-violet-100 mb-4 `}
                          onChange={handleInputChange}
                          multiple
                          placeholder="heah"
                        />
                      </div>
                    )}

                    {selectedFiles.length === 0 ? (
                      <span className="text-gray-500">No files selected</span>
                    ) : (
                      <div>
                        <div className="text-blue-500 font-bold mb-2">
                          {selectedFiles.length} file(s) selected:
                        </div>
                        <ul className="list-disc pl-4">
                          {selectedFiles.map((file, index) => (
                            <li className="text-gray-700" key={index}>
                              <div className="flex items-center">
                                <span className="mr-2">
                                  {index + 1}. {file.name}
                                </span>
                                <button
                                  onClick={(event) =>
                                    handleRemoveFile(index, event)
                                  }
                                  className="text-red-500 hover:text-red-700"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    className="w-4 h-4"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M6 18L18 6M6 6l12 12"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </label>
                </div>
              </div>
            </div>

            <div className="w-full flex-col justify-center items-center mt-14">
              <button
                className={`bg-orange-600 w-64 block  hover:bg-orange-400 text-white rounded-md px-3 py-1 
              ${isSubmitting ? 'disabled:bg-orange-200' : ''}`}
                type="submit"
                onSubmit={handleSubmit}
              >
                {isSubmitting ? 'Submitting' : t('editProduct')}
              </button>
              {message && (
                <div>
                  <div
                    id="successMessage"
                    ref={successMessageRef}
                    className={` bg-white my-4 flex h-14 items-center rounded-md w-3/5 al p-2`}
                  >
                    <div
                      className={` bg-white w-80  rounded-md  ${
                        message.type === 'success'
                          ? 'text-green-500'
                          : 'text-red-500'
                      } `}
                    >
                      {message.text}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default EditProduct;
