import { useState } from "react";
import {
  useAddCartMutation,
  useRemoveCartMutation,
} from "../../features/auth/authApiSlice";
import {
  setCheckout,
  selectCurrentTotalCartItems,
  selectCurrentTotalprice,
  selectCurrentFetchedCartItems,
  setGlobalCartItems,
  setQuantityofCartItemsById,
  setUser,
  selectCurrentEmail,
  selectCurrentName,
  setColorofCartItemsById,
} from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/buyerProfile.css";
import { useTranslation } from "react-i18next";

function Cart({
  title,
  description,
  imageUrl,
  rating,
  price,
  stock,
  id,
  colorImgList,
  color,
  globalShippingRulescart,
  additionalPriceCart
}) {
  const [myquantity, setMyquantity] = useState(stock);
  const [quantity, setQuantity] = useState(1);
  const itemTotal = useSelector(selectCurrentTotalCartItems);
  const itemTotalPrice = useSelector(selectCurrentTotalprice);
  const cartGlobalItems = useSelector(selectCurrentFetchedCartItems);
  const cartGlobalItemsToassign = cartGlobalItems;
  const [removeCart, { isLoading }] = useRemoveCartMutation();
  // const userData = await removeCart({ fullName, email, cartItemId: order.itemId }).unwrap();

  const [globalShippingRules, setGlobalShippingRules] = useState(null);


  const email = useSelector(selectCurrentEmail);
  const fullName = useSelector(selectCurrentName);
  const [isRemovingCart, setIsRemovingCart] = useState(false);

  const [GlobalColorQuantity, setGlobalColorQuantity] = useState(stock);

  const dispatch = useDispatch();
  const extractQuantityFromText = (text) => {
    // Your logic to extract the quantity from the text
    const matches = text.match(/\((\d+)\)/); // Extracts the quantity within parentheses
    const quantity = matches ? parseInt(matches[1], 10) : null; // Parses the quantity as an integer
    return quantity;
  };
  const extractColorFromText = (text) => {
    const match = text.match(/\b(\w+)\b/); // Extracts the first word
    return match ? match[1] : "transparent";
  };

  const extractColor = (text) => {
    // Your logic to extract the color from the text
    const color = text.split(" ")[0]; // Assuming the color is the first word in the text
    return color;
  };

  const [colorvalue, setColor] = useState(extractColor(color[0]));

  const [quantityOptions, setQuantityOptions] = useState([]);

  const [colorToCompare, setColorToCompare] = useState(null);

  const handleColorChange = (colore) => {
    const selectedColor = colore;
    setColorToCompare(selectedColor)
    let quantity;
    color.map((color) => {
      const saj = extractColor(color);
      if (saj === selectedColor) {
        quantity = extractQuantityFromText(color);
      }
    });

    dispatch(setColorofCartItemsById({ color: selectedColor, id: id }));

    setColor(selectedColor);
    setMyquantity(quantity);
    setQuantityOptions([...Array(quantity).keys()].map((index) => index + 1));
    // setPrice(itemFetched?.price[0] * quantity);
  };
  const handleQuantityChange = (nnn) => {
    const newQuantity = parseInt(nnn);
    setQuantity(newQuantity);

    dispatch(setQuantityofCartItemsById({ quantity: newQuantity, id: id }));

    // Update total price and total quantity in Redux state
    dispatch(
      setCheckout({
        totalCartItems: itemTotal + newQuantity - quantity,
        totalPrice: itemTotalPrice + (newQuantity - quantity) * (price + additionalPriceCart),
      }),

    );
  };

  const handelRemoveFromCart = async (itemId) => {
    setIsRemovingCart(true);

    // Your logic for adding to cart goes here
    const userData = await removeCart({
      fullName,
      email,
      cartItemId: id,
    }).unwrap();

    dispatch(
      setUser({
        id: userData._id,
        email: userData.email,
        name: userData.fullName,
        role: userData.role,
        accountStatus: userData.accountStatus,
        cart: userData.cart,
      })
    );
    setIsRemovingCart(false);
  };

  const { t } = useTranslation();

  const renderRatingStars = (rating) => {
    if (!rating) {
      return null;
    }
    const filledStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    const stars = [];

    for (let i = 0; i < filledStars; i++) {
      stars.push(
        <span key={i} className="text-orange-600">
          &#9733;
        </span>
      );
    }

    if (hasHalfStar) {
      stars.push(
        <span key={filledStars} className="text-orange-600">
          &#9733;
        </span>
      );
    }

    const emptyStars = 5 - filledStars - (hasHalfStar ? 1 : 0);

    for (let i = 0; i < emptyStars; i++) {
      stars.push(
        <span key={`empty-${i}`} className="text-gray-300">
          &#9733;
        </span>
      );
    }

    return stars;
  };

  return (
    <>
      <div className="cartWidth flex bg-white  p-2 mb-4 rounded-md">
        <img src={imageUrl} className="h-28 w-28"></img>
        <div className="pl-2 md:ml-4">
          <p className=" text-gray-500">{title}</p>
          {/* <div className="">{description}</div> */}
          <div className="flex items-center">{renderRatingStars(rating)}</div>
          <div className="text-gray-700">{price}$</div>
          {colorImgList && (
            <div className="">
              <ul className="flex  flex-wrap">
                {color.map((color9, index) => (
                  <li key={index}>
                    <div
                      key={index}
                      className={`rounded-full my-2 w-[29px] h-[29px]   border-purple-500 ${
                        colorToCompare === extractColor(color9) ? "border-2" : ""
                      } `}
                      style={{
                        backgroundColor: extractColorFromText(color9),
                        // width: '20px',
                        // height: '20px',
                        marginRight: "8px",
                      }}
                      onClick={() => {
                        let quantity;
                        const colorse = extractColor(color9);

                        color.map((color2) => {
                          const saj = extractColor(color2);
                          if (saj === colorse) {
                            quantity = extractQuantityFromText(color2);
                          }
                        });
                        handleColorChange(colorse);
                        setGlobalColorQuantity(quantity);

                        //setColor(colorse);
                        //setGlobalColorQuantity(quantity);

                        //   setQuantityOptions(
                        //     [...Array(quantity).keys()].map(
                        //       (index) => index + 1,
                        //     ),
                        //   );
                        // }}
                        // onMouseEnter={() => {
                        //   if (itemFetched.colorImgList[index]) {
                        //     setHoveredImage(
                        //       itemFetched.colorImgList[index],
                        //     );
                        //   }
                      }}
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className="flex mt-2 items-center">
            {myquantity > 0 ? (
              <>
                <div className="flex">
                  <div className="bg-white rounded-[3px] border border-neutral-400 justify-start items-center gap-1.5 inline-flex">
                    <div
                      onClick={() => {
                        if (quantity > 0) {
                          let quantityModified = quantity - 1;
                          handleQuantityChange(quantityModified);
                        }
                      }}
                      className="hover:cursor-pointer pl-3 pr-[15px] py-1.5 flex-col justify-center items-center gap-2.5 inline-flex"
                    >
                      <div className="w-[5px] text-neutral-400 text-[33px] font-['Poppins'] leading-7 tracking-tight">
                        -
                      </div>
                    </div>
                    <div className="px-[19px] py-1.5 border-l border-r border-neutral-400 flex-col justify-center items-center gap-2.5 inline-flex">
                      <div className="text-neutral-400 text-[22px] font-normal font-['Poppins'] leading-7 tracking-tight">
                        {quantity}
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        if (quantity < Number(GlobalColorQuantity)) {
                          let quantityModified = quantity + 1;

                          handleQuantityChange(quantityModified);
                        }
                      }}
                      className={` ${
                        quantity === Number(GlobalColorQuantity)
                          ? "disabled:opacity-40"
                          : ""
                      } hover:cursor-pointer pl-3 pr-[15px] py-1.5 flex-col justify-center items-center gap-2.5 inline-flex `}
                    >
                      <div className="text-neutral-400 text-[28px] font-['Poppins'] leading-7 tracking-tight">
                        +
                      </div>
                    </div>
                  </div>
                  {/* <div className="ml-28 flex">
                    <span className="text-neutral-600 mb-2 text-[23px] font-normal font-['Poppins'] leading-7 tracking-tight">
                      Total: ${formatPrice(price)}
                    </span>
                  </div>{' '} */}
                </div>
              </>
            ) : (
              <p className="ml-2 text-red-500">Unavailable</p>
            )}
            <p className="text-gray-400 rowMarigin md:mx-4">|</p>
            <a
              onClick={handelRemoveFromCart}
              className="text-blue-500 rowMarigin hover:underline"
            >
              {isRemovingCart ? "processing..." : t("remove")}
            </a>
            <p className="text-gray-400 rowMarigin md:mx-4">|</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cart;
