import { useGetUsersQuery } from './UsersApiSlice';
import { Link } from 'react-router-dom';
import { selectCurrentUser } from '../auth/authSlice';
import { useSelector } from 'react-redux';

const UsersList = () => {
  const user = useSelector(selectCurrentUser);
  const { data, isLoading, error } = useGetUsersQuery({ email: user });

  let content;

  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (data) {
    content = (
      <section className="users">
        <h1>Users List</h1>
        <ul>
          <li>
            <p>{data._id}</p>
          </li>
        </ul>
        <Link to="/welcome">Back to Welcome</Link>
        <div>
        <Link to="/stripe">make a payment</Link>
        </div>
      </section>
    );
  } else if (error) {
    content = <p>{JSON.stringify(error)}</p>;
  }

  return content;
};
export default UsersList;
