import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials, logOut } from "../../features/auth/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND_API,
  prepareHeaders: (headers, { getState }) => {
    //console.log(process.env.REACT_APP_BACKEND_API);
    const token = getState().auth.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  //console.log(args);
  //console.log(api);
  let result = await baseQuery(args, api, extraOptions);
  //console.log('result');

  //console.log(result?.error?.status);

  if (result?.error?.status === 401) {
    //console.log('sending refresh token');
    const refreshToken = api.getState().auth.refreshtoken;
    const email = api.getState().auth.user;
    api.dispatch(
      setCredentials({
        user: email,
        accessToken: refreshToken,
        refreshtoken: refreshToken,
      })
    );
    // send refresh token to get new access token
    const refreshResult = await baseQuery("/auth/refresh", api, extraOptions);
    //console.log('test123');
    //console.log(refreshResult);
    if (refreshResult?.data) {
      //console.log('popopo');
      //console.log(refreshResult?.data.access_token);
      //console.log(refreshResult?.data.refresh_token);

      api.dispatch(
        setCredentials({
          user: email,
          accessToken: refreshResult?.data.access_token,
          refreshtoken: refreshResult?.data.refresh_token,
        })
      );
      // store the new token
      // let access_token = userData[0].access_token;
      // dispatch(setCredentials({ user: email, accessToken: access_token }));
      // retry the original query with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});
